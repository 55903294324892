import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const SuccessAddServiceProvider = ({ onClose }) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
        success
        bigText={`Service Provider has been Successfully
      Created`}
      >
        <Button bgColor={`#FFA72C`} text="Close" onClick={onClose} />
      </CommonModal>
    </>
  );
};

export default SuccessAddServiceProvider;
