import React, { useState } from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";
import fileImg from "../../../../assets/payment-request-file-icon.svg";
import "../../../../styles/dashboard/payment-request/PaymentRequest.css"

const SettleTransactionModal = ({ onClose, onSettle }) => {
  const [file, setFile] = useState("");
  return (
    <>
      <CommonModal
        onClose={() => {
            onClose()
            setFile("")
        }}
        documentPay
        //   bigText={`Profile has been successfully reactivated`}
      >
        {/* form upload box start */}
        <div className="payment-request-settle-upload-form-box">
          <div className="form-group">
            <input
              onChange={(e) => setFile(e.target.files[0])}
              type="file"
              name=""
              id="input-file"
              className="form-input"
            />
            <label htmlFor="input-file" className="form-label">
              <figure className="img-box">
                <img src={fileImg} alt="" className="img" />
              </figure>
              <p className={`text ${file && "text-bold"}`}>
                {!file ? "Please attach settlement evidence" : file?.name}
              </p>
            </label>
          </div>
        </div>
        {/* form upload box end */}
        <Button
          bgColor={file ? "#00C013" : "#BBBBBB"}
          text="Settle"
          onClick={onSettle}
        />
      </CommonModal>
    </>
  );
};

export default SettleTransactionModal;
