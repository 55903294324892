import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import setAuthToken from "../../utils/auth";
import { addLabelValueFunc } from "../../helpers/Helper";
toast.configure();

export const getPetTypes = createAsyncThunk(
  "admin/get-get-pet-type",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      const { data } = await axios.get("admin/pet/types", {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      //   console.log('pet-types', data);
      if (!data.success) {
        // console.log(data.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const petTypes = data?.pet_types?.data;
        await thunkAPI.dispatch(setPetTypes(petTypes));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const getPets = createAsyncThunk(
  "admin/get-pets",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      const { data } = await axios.get("admin/pets", {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      console.log("pets", data.pets);
      if (!data.success) {
        // console.log(data.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const petTypes = data?.pets;
        await thunkAPI.dispatch(setPets(petTypes));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);


export const getPetParents = createAsyncThunk(
    "admin/get-pet-parents",
    async (formData, thunkAPI) => {
      try {
        const token = localStorage.getItem("token");
  
        await setAuthToken(token);
        const { data } = await axios.get("/admin/users", {
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
        });
        console.log("pets", data?.users);
        if (!data.success) {
          // console.log(data.message);
          if (Array.isArray(data.errors)) {
            toast.error(data.errors[0], {
              theme: "colored",
            });
          } else {
            toast.error(data.errors, {
              theme: "colored",
            });
          }
          return thunkAPI.rejectWithValue(data);
        }
        if (data?.success) {
          await thunkAPI.dispatch(setPetParent( data?.users));
          return thunkAPI.rejectWithValue(data);
        }
      } catch (err) {
        console.log(err);
      }
    }
  );
  
  

export const getPetParentsPagination = createAsyncThunk(
    "admin/get-pet-parents",
    async (formData, thunkAPI) => {
      try {
        const token = localStorage.getItem("token");
  
        await setAuthToken(token);
        const { data } = await axios.get(formData, {
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
        });
        // console.log("pets", data?.users);
        if (!data.success) {
          // console.log(data.message);
          if (Array.isArray(data.errors)) {
            toast.error(data.errors[0], {
              theme: "colored",
            });
          } else {
            toast.error(data.errors, {
              theme: "colored",
            });
          }
          return thunkAPI.rejectWithValue(data);
        }
        if (data?.success) {
          await thunkAPI.dispatch(setPetParent( data?.users));
          return thunkAPI.rejectWithValue(data);
        }
      } catch (err) {
        console.log(err);
      }
    }
  );

export const getPet = createAsyncThunk(
    "admin/get-pets",
    async (formData, thunkAPI) => {
      try {
        const token = localStorage.getItem("token");
  
        await setAuthToken(token);
        const { data } = await axios.get(`admin/pets/${formData}`, {
         
        });
        // console.log("pets", data.pet);
        if (!data.success) {
          // console.log(data.message);
          if (Array.isArray(data.errors)) {
            toast.error(data.errors[0], {
              theme: "colored",
            });
          } else {
            toast.error(data.errors, {
              theme: "colored",
            });
          }
          return thunkAPI.rejectWithValue(data);
        }
        if (data?.success) {
        //   const petTypes = data?.pets;
        //   await thunkAPI.dispatch(setPets(petTypes));
          return thunkAPI.rejectWithValue(data);
        }
      } catch (err) {
        console.log(err);
      }
    }
  );

export const getPetsPagination = createAsyncThunk(
  "admin/get-pets-pagination",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      const { data } = await axios.get(formData, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
    //   console.log("pets", data.pets);
      if (!data.success) {
        // console.log(data.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const petTypes = data?.pets;
        await thunkAPI.dispatch(setPets(petTypes));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const getDewormDetails = createAsyncThunk(
  "admin/deworm-detail",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      //   return
      const { data } = await axios.get(
        `admin/pets/${formData}/deworming`,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (!data.success) {
        // console.log(dataRes.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateShopItem = createAsyncThunk(
  "admin/create-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      //   return
      const { data } = await axios.post(
        `admin/shop/items/${formData?.id}/update`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (!data.success) {
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const createPetAdmin = createAsyncThunk(
  "admin/create-pet-admin",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      //   return
      const { data } = await axios.post(`user/pets/create`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (!data.success) {
        // console.log(data.errors);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const searchShopItem = createAsyncThunk(
  "admin/create-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const { term } = formData;
      // console.log(formData);
      // return
      // var data = new FormData();
      await setAuthToken(token);
      //   return
      const { data } = await axios.post(`admin/shop/items/search`, {
        term,
      });
      console.log(data.shop_items);
      await thunkAPI.dispatch(setPetTypes(data.shop_items));
      if (!data.success) {
        // console.log(data.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      // console.log("search", data);
      //   await setAuthToken(data.token);
      //   await thunkAPI.dispatch(login(data));
      //   return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const deletePetParent = createAsyncThunk(
  "admin/delete-pet-parent",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { id } = formData;
      const { data } = await axios.delete(
        `admin/users/${id}/delete`,
        {}
      );
      if (!data.success) {
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const verifyPetParent = createAsyncThunk(
  "admin/publish-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { id } = formData;
      const { data } = await axios.post(`admin/users/${id}/verify`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      //   console.log('delete', data);
      if (!data.success) {
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const pet_type = createSlice({
  name: "service-provider",
  initialState: {
    pet_types: [],
    pet_type: [],
    petParents: [],
    pets: [],
    pet: {},
    isAuth: false,
    loading: false,
    loadModal: false,
    shop: [],
    meta: {},
    dewormObj: {},
  },
  reducers: {
    setPetTypes: (state, action) => {
      state.isAuth = true;
      state.pet_types = addLabelValueFunc(action.payload);
      state.loading = false;
      state.shop = action.payload;
    },
    setPets: (state, action) => {
      state.isAuth = true;
      state.pets = action.payload;
      state.loading = false;
      // state.shop = action.payload;
    },
    setDeworm: (state, action) => {
      state.isAuth = true;
      state.dewormObj = action.payload;
    },
    setPetParent: (state, action) => {
      state.isAuth = true;
      state.petParents = action.payload;
    },
    
    
  },
  extraReducers: {
    [getPets.pending]: (state) => {
      state.loading = true;
    },
    [getPets.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPets.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getPetsPagination.pending]: (state) => {
      state.loading = true;
    },
    [getPetsPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPetsPagination.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getPetParents.pending]: (state) => {
      state.loading = true;
    },
    [getPetParents.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPetParents.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getPetParentsPagination.pending]: (state) => {
      state.loading = true;
    },
    [getPetParentsPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPetParentsPagination.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getDewormDetails.pending]: (state) => {
      state.loadModal = true;
    },
    [getDewormDetails.fulfilled]: (state) => {
      state.loadModal = false;
    },
    [getDewormDetails.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadModal = false;
      state.isAuth = false;
      state = null;
    },
    [updateShopItem.pending]: (state) => {
      state.loading = true;
    },
    [updateShopItem.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateShopItem.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [createPetAdmin.pending]: (state) => {
      state.loading = true;
    },
    [createPetAdmin.fulfilled]: (state) => {
      state.loading = false;
    },
    [createPetAdmin.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deletePetParent.pending]: (state) => {
      state.loading = true;
    },
    [deletePetParent.fulfilled]: (state) => {
      state.loading = false;
    },
    [deletePetParent.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [verifyPetParent.pending]: (state) => {
      state.loading = true;
    },
    [verifyPetParent.fulfilled]: (state) => {
      state.loading = false;
    },
    [verifyPetParent.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPetTypes, setPets, setPetParent } = pet_type.actions;

export default pet_type.reducer;
