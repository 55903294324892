import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import setAuthToken from "../../utils/auth";
toast.configure();

export const getBlogCategories = createAsyncThunk(
  "admin/blog-get-categories",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      //     console.log(token);
      //   return
      const { data } = await axios.get("admin/blog/categories", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!data.success) {
        // console.log(data.message);
        toast.error(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
        // console.log("postys-------------"  ,data);
      await thunkAPI.dispatch(setBlogCategories(data.categories));
        return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

const loadOptionFunc = (data, type) => {
    if (type === "metrics") {
      const list = data?.map(({ id, name }) => {
        return { value: id, label: name };
      });
     return list;
    }
    if (type === "categories") {
      const list = data?.map(({ id, name }) => {
        return { value: id, label: name };
      });
    return list;
    }
  };


export const createBlogCategory = createAsyncThunk(
  "admin/create-shop-category",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const payload = {
        name: formData.name,
      };
      //   return
      await setAuthToken(token);
      const { data } = await axios.post("admin/blog/categories/create", {
        name: payload.name,
      });
    //   console.log('Login', data);
      if (!data.success) {
        // console.log(data.message);
        toast.error(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      toast.success(`Blog category ${data.category.name} creaated successfully`, {
        theme: "colored",
      });
      return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);



export const deleteBlogCategory = createAsyncThunk(
  "admin/delete-blog-category",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const { id } = formData;
      const { data } = await axios.delete(
        `admin/blog/categories/${id}/delete`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //   console.log('delete', data);
      if (!data.success) {
        toast.error("No Category found for given data", {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      //   console.log(data.metrics.data);
      return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const blog_settings = createSlice({
  name: "blog_settings",
  initialState: {
    blog_categories: [],
    loading: false,
    success: false,
    categoriesDropDown: [],
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setBlogCategories: (state, action) => {
      state.blog_categories = action.payload;
      state.loading = false;
      state.categoriesDropDown = loadOptionFunc(action.payload, "categories");
    },
  },
  extraReducers: {
    [createBlogCategory.pending]: (state) => {
      state.loading = true;
    },
    [createBlogCategory.fulfilled]: (state) => {
      state.loading = false;
      //   state.success = true;
    },
    [createBlogCategory.rejected]: (state) => {
      //   localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteBlogCategory.pending]: (state) => {
      state.loading = true;
    },
    [deleteBlogCategory.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
    },
    [deleteBlogCategory.rejected]: (state) => {
      //   localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getBlogCategories.pending]: (state) => {
      state.loading = true;
    },
    [getBlogCategories.fulfilled]: (state) => {
      state.loading = false;
      //   state.success = true;
    },
    [getBlogCategories.rejected]: (state) => {
      //   localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {  setBlogCategories } = blog_settings.actions;

export default blog_settings.reducer;
