import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import "../../../../../styles/dashboard/pet/modal/CommonParentDetailModal.css";
import AppointmentDetail from "./AppointmentDetail";
import PetDetailParent from "./PetDetailParent";
import ProfileDetail from "./ProfileDetail";
import TransactionHistory from "./TransactionHistory";

const CommonParentDetailModal = ({ onClose, detail }) => {
  const [showBox, setShowBox] = useState("profile");
  return (
    <div className="common-parent-detail-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* end of cancel */}
      {/* content wrap start */}
      <div className="content-wrap">
        {/* left box start */}
        <div className="left-box">
          <div className="list">
            <p
              onClick={() => setShowBox("profile")}
              className={`item ${showBox === "profile" && "item-active"}`}
            >
              <span>Profile Details</span>
            </p>
            <p
              onClick={() => setShowBox("pet")}
              className={`item ${showBox === "pet" && "item-active"}`}
            >
              <span>{`Pets - ${detail?.pets?.length}`}</span>
            </p>
            <p
              onClick={() => setShowBox("appointment")}
              className={`item ${showBox === "appointment" && "item-active"}`}
            >
              <span>{`Appointments - ${detail?.appointments?.length}`}</span>
            </p>
            <p
              onClick={() => setShowBox("transaction")}
              className={`item ${showBox === "transaction" && "item-active"}`}
            >
              <span>Transaction History</span>
            </p>
          </div>
        </div>
        {/* left box end */}
        {/* right box start */}
        <div className="right-box">
          {showBox === "profile" && <ProfileDetail detail={detail} />}
          {showBox === "pet" && <PetDetailParent detail={detail} />}
          {showBox === "appointment" && <AppointmentDetail detail={detail} />}
          {showBox === "transaction" && <TransactionHistory detail={detail} />}
        </div>
        {/* right box end */}
      </div>
      {/* content wrap end */}
    </div>
  );
};

export default CommonParentDetailModal;
