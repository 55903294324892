import moment from "moment";
import React from "react";
import defaultImg from "../../../../../assets/parent-pet-profile-img.png";

const ProfileDetail = ({ setShowbox, detail }) => {
  return (
    <div className="profile-box">
      {/*picture box start */}
      <div className="picture-box">
        <div
          style={{ backgroundImage: `url(${defaultImg})` }}
          className="img-show-wrap"
        ></div>
        {/* box start */}
        <div className="box">
          <p className="light">Created by </p>
          <p className="bold">{detail?.name}</p>
        </div>
        {/* box end */}
        <p className={`active ${detail?.status !== "verified" && 'disabled'}`}>{detail?.status === "verified" ? "Active" : "Not active"}</p>
      </div>
      {/*picture box end */}
      {/* bottom box start */}
      <div className="bottom-box">
        {/* box start */}
        <div className="box">
          <p className="light">Address </p>
          <p className="bold">
          {detail?.address}
          </p>
        </div>
        {/* box end */}
        {/* box start */}
        <div className="box">
          <p className="light">Phone Number</p>
          <p className="bold">{detail?.mobile}</p>
        </div>
        {/* box end */}
        {/* box start */}
        <div className="box">
          <p className="light">Email</p>
          <p className="bold">{detail?.email}</p>
        </div>
        {/* box end */}
        {/* box start */}
        <div className="box">
          <p className="light">Last Login</p>
          <p className="bold">{detail?.last_login}</p>
        </div>
        {/* box end */}
        {/* box start */}
        <div className="box">
          <p className="light">Onboarding Date</p>
          <p className="bold">{moment(detail?.created_at).format("YYYY-MM-DD")}</p>
        </div>
        {/* box end */}
        {/* box start */}
        <div className="box">
          <p className="light">Total Spend</p>
          <p className="bold bold-green">N 123,456</p>
        </div>
        {/* box end */}
      </div>
      {/* bottom box end */}
    </div>
  );
};

export default ProfileDetail;
