import React, { useState } from "react";
import CommonHeader from "./CommonHeader";
import Flatpicker from "react-flatpickr";
import "flatpickr/dist/themes/confetti.css";
import Modal from "react-awesome-modal";
import moment from "moment";
import calendarIcon from "../../../assets/calendar-icon.svg";
import "../../../styles/dashboard/appointment/AppointmentHistory.css";
import Button from "../../../component/dashboard/fragments/Button";
import ViewAppointmentModal from "./modals/ViewAppointmentModal";
import CreateAppointmentModal from "./modals/CreateAppoinmentModal";
import SuccessCreateAppointmentModal from "./modals/SuccessCreateAppoinment";
import ReassignServiceProvider from "./modals/ReassignServiceProvider";
import SuccessReassignModal from "./modals/SuccessReassignModal";
import CancelAppointmentModal from "./modals/CancelAppointmentModal";
import SuccessCancelAppointmentModal from "./modals/SuccessCancelAppointmentModal";
import RescheduleAppointModal from "./modals/RescheduleAppointmentModal";
import SuccessRescheduleModal from "./modals/SuccessRescheduleModal";
import ReuseableTable from "../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../component/dashboard/tables/TableRow";
import { tableBodyList } from "./NewAppointments";
// import Modal from "react-awesome-modal"

const AppointmentHistory = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [showHistoryTable, setShowHistoryTable] = useState(false);
  const [indexNo, setIndexNo] = useState("");
  const [showModal, setShowModal] = useState({
    view: false,
    create_appointment: false,
    success_create_appointment: false,
    reassign_service: false,
    success_reassign_service: false,
    cancel_appointment: false,
    success_ancel_appointment: false,
    reschedule: false,
    success_reschedule: false,
  });

  return (
    <>
      <CommonHeader
        onCreateAppointment={() => {
          setShowModal((prev) => {
            return { ...prev, create_appointment: true };
          });
        }}
      />
      <div className="appointment-history-wrap">
        {!showHistoryTable && (
          <p className="subtitle">
            Select a Date range to fetch transaction records for
          </p>
        )}

        {/* select history box start */}
        {/* calendar box start */}
        {!showHistoryTable && (
          <div className="calender-box">
            <p className="text">from</p>
            <div className="form-group">
              <label htmlFor="from" className="label">
                <figure className="img-box">
                  <img src={calendarIcon} alt="" className="img" />
                </figure>
              </label>
              <Flatpicker
                id="from"
                name="from"
                data-enable-time
                //   cal
                className="input-calender"
                autoComplete="off"
                value={fromDate}
                onChange={(date) =>
                  setFromDate(moment(date[0]).format("YYYY/MM/DD"))
                }
                placeholder="DD / MM / YYYY"
              />
            </div>
            <p style={{ marginLeft: "2rem" }} className="text">
              to
            </p>
            <div className="form-group">
              <label htmlFor="to" className="label">
                <figure className="img-box">
                  <img src={calendarIcon} alt="" className="img" />
                </figure>
              </label>
              <Flatpicker
                data-enable-time
                id="to"
                name="to"
                //   cal
                className="input-calender"
                autoComplete="off"
                value={toDate}
                onChange={(date) =>
                  settoDate(moment(date[0]).format("YYYY/MM/DD"))
                }
                placeholder="DD / MM / YYYY"
              />
            </div>
          </div>
        )}
        {/* btn start */}
        {!showHistoryTable && (
          <Button
            btnStyle={`btn-reuse`}
            text="Fetch Records"
            bgColor={`#84BFCD`}
            onClick={() => setShowHistoryTable(true)}
          />
        )}
        {/* btn end */}
        {showHistoryTable && (
          <>
            {/* reset text box start */}
            <div className="reset-text-box">
              <p className="text">
                Displaying <span> 4 </span> results from <span>23/11/2021</span>{" "}
                to <span>01/01/2022</span>
              </p>
              <p onClick={() => setShowHistoryTable(false)} className="reset">
                Reset
              </p>
            </div>
            {/* reset text box end */}
            <ReuseableTable
              //    loading={loading}
              sN
              one={`Pet`}
              two={`Parent`}
              three={`Appointment Type`}
              four={"Date"}
              threeDrop
              fourDrop
              five={`Service Provider`}
              fiveDrop
              action
              //   moreThanFive={parentList?.length >= 3}
            >
              {tableBodyList.map((chi, idx) => {
                const {
                  pet,
                  parent,
                  service_provider,
                  appointment_type,
                  date,
                } = chi;
                return (
                  <TableRow
                    action
                    setIndexNo={() => setIndexNo(idx)}
                    rowNo={idx}
                    indexNo={indexNo}
                    key={idx}
                    sN={idx + 1}
                    one={pet}
                    two={parent}
                    three={appointment_type}
                    four={date}
                    five={service_provider}
                    viewText={`View Appointment`}
                    createText={`Reassign Service Provider`}
                    onAdd={() => {
                      setShowModal((prev) => {
                        return { ...prev, reassign_service: true };
                      });
                    }}
                    editText={`Reschedule`}
                    deleteText={`Cancel Appointment`}
                    onDelete={() => {
                      setShowModal((prev) => {
                        return { ...prev, cancel_appointment: true };
                      });
                    }}
                    onEdit={() => {
                      setShowModal((prev) => {
                        return { ...prev, reschedule: true };
                      });
                    }}
                    longPopModal
                    onViewDetail={() => {
                      setShowModal((prev) => {
                        return { ...prev, view: true };
                      });
                    }}
                  />
                );
              })}
            </ReuseableTable>
          </>
        )}
      </div>
      {/* modal view start */}
      <Modal visible={showModal?.view}>
        <ViewAppointmentModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, view: false };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.create_appointment}>
        <CreateAppointmentModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, create_appointment: false };
            });
          }}
          onCreateAppointment={() => {
            setShowModal((prev) => {
              return {
                ...prev,
                create_appointment: false,
                success_create_appointment: true,
              };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.success_create_appointment}>
        <SuccessCreateAppointmentModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, success_create_appointment: false };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.reassign_service}>
        <ReassignServiceProvider
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, reassign_service: false };
            });
          }}
          onReassign={() => {
            setShowModal((prev) => {
              return {
                ...prev,
                reassign_service: false,
                success_reassign_service: true,
              };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.success_reassign_service}>
        <SuccessReassignModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, success_reassign_service: false };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.cancel_appointment}>
        <CancelAppointmentModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, cancel_appointment: false };
            });
          }}
          onReject={() => {
            setShowModal((prev) => {
              return {
                ...prev,
                cancel_appointment: false,
                success_ancel_appointment: true,
              };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.success_ancel_appointment}>
        <SuccessCancelAppointmentModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, success_ancel_appointment: false };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.reschedule}>
        <RescheduleAppointModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, reschedule: false };
            });
          }}
          onReshedule={() => {
            setShowModal((prev) => {
              return { ...prev, reschedule: false, success_reschedule: true };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.success_reschedule}>
        <SuccessRescheduleModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, success_reschedule: false };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
    </>
  );
};

export default AppointmentHistory;
