import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import "../../../../../styles/dashboard/pet/modal/CommonParentDetailModal.css";
import AppointmentDetail from "./AppointmentDetail";
import Documents from "./Documents";
import PetClient from "./PetClient";
// import PetDetailParent from "./PetDetailParent";
import ProfileDetail from "./ProfileDetail";
import TransactionHistory from "./TransactionHistory";

const CommonProfileModal = ({ onClose , detail }) => {
  const [showBox, setShowBox] = useState("profile");
  return (
    <div style={{width: "90rem"}} className="common-parent-detail-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* end of cancel */}
      {/* content wrap start */}
      <div className="content-wrap">
        {/* left box start */}
        <div className="left-box">
          <div className="list">
            <p
              onClick={() => setShowBox("profile")}
              className={`item ${showBox === "profile" && "item-active"}`}
            >
              <span>Profile Details</span>
            </p>
            <p
              onClick={() => setShowBox("pet")}
              className={`item ${showBox === "pet" && "item-active"}`}
            >
              <span>Pet Clients - 6</span>
            </p>
            <p
              onClick={() => setShowBox("appointment")}
              className={`item ${showBox === "appointment" && "item-active"}`}
            >
              <span>Appointments - 3</span>
            </p>
            <p
              onClick={() => setShowBox("transaction")}
              className={`item ${showBox === "transaction" && "item-active"}`}
            >
              <span>Transaction History</span>
            </p>
            <p
              onClick={() => setShowBox("document")}
              className={`item ${showBox === "document" && "item-active"}`}
            >
              <span>Document(s)</span>
            </p>
          </div>
        </div>
        {/* left box end */}
        {/* right box start */}
        <div className="right-box">
          {showBox === "profile" && <ProfileDetail  />}
          {showBox === "pet" && <PetClient  />}
          {showBox === "appointment" && (
            <AppointmentDetail  />
          )}
          {showBox === "transaction" && (
            <TransactionHistory  />
          )}
          {showBox === "document" && (
            <Documents  />
          )}
        </div>
        {/* right box end */}
      </div>
      {/* content wrap end */}
    </div>
  );
};

export default CommonProfileModal;
