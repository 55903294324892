import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReuseableTable from "../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../component/dashboard/tables/TableRow";
import {
  // blogBodyArticleList,
  blogHeadArticleList,
} from "../../../utils/BlogArticleData";
import CommonHeader from "./CommonHeader";
import Modal from "react-awesome-modal";
import DeleteBlogModal from "./modals/DeleteBlogModal";
import SuccessDeleteModal from "./modals/SuccessDeleteBlogModal";
import { useDispatch, useSelector } from "react-redux";
import {  getBlogPosts } from "../../../redux/blog/BlogPosts";
// import CommonHeader from '../shop/CommonHeader';

const BlogArticle = () => {
  const dispatch = useDispatch();
  // const { loading } = useSelector((state) => state.blog_settings);

  const { blog_posts, blog_data } = useSelector((state) => state.blog_posts);
  const history = useHistory();
  const [indexNo, setIndexNo] = useState("");
  const [singleChikd, setSingleChild] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const [showModal, setShowModal] = useState({
    view: false,
    success_delete: false,
    delete: false,
  });

  useEffect(() => {
    dispatch(getBlogPosts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  return (
    <>
      <div className="blog_article_wrap">
        {/* common headr start */}
        <CommonHeader
          onSearch={(e) => console.log(e)}
          // onClickBtn={() => history.push("/dashboard-blog-create")}
        />
        {/* common header end */}
        {/* table start */}
        <ReuseableTable
          //    loading={loading}
          one={blogHeadArticleList[0]}
          two={blogHeadArticleList[1]}
          three={blogHeadArticleList[2]}
          four={blogHeadArticleList[3]}
          twoDrop
          threeDrop
          fourDrop
          action
          // moreThanFive={blogBodyArticleList?.length >= 3}
          moreThanFive={blog_posts?.length >= 3}
          toNo={blog_data?.to}
          totalNo={blog_data?.total}
          fromNo={blog_data?.from}
          nextPage={blog_data?.next_page_url}
          prevPage={blog_data?.prev_page_url}
        >
          {blog_posts.map((chi, idx) => {
            const {
              heading,
              title,
              likes,
              views,
              author,
              image,
              image_path,
              category,
              id,
            } = chi;
            const img = image ? `${image_path}` : "";
            const obj = {
              img: img,
              header: category?.name,
              title: title,
              header_type:
                heading === "Headline 1"
                  ? "heading-one"
                  : heading === "Regular"
                  ? "regular"
                  : "heading-two",
            };
            return (
              <TableRow
                setIndexNo={() => setIndexNo(idx)}
                rowNo={idx}
                indexNo={indexNo}
                key={idx}
                blogtdOne={obj}
                two={views || <>&nbsp;</>}
                three={likes || <>&nbsp;</>}
                four={author}
                action
                viewText={`View Blog Item`}
                editText={`Edit Blog Item`}
                deleteText={`Delete Blog Item`}
                onEdit={() => {
                  localStorage.setItem("blog-post-id", id);
                  history.push("/dashboard-blog-update");

                }}
                onDelete={() => {
                  setShowModal((prev) => {
                    return { ...prev, delete: true };
                  });
                  setSingleChild(chi);
                }}
                onViewDetail={() => {
                  localStorage.setItem("blog-post-id", id);
                  history.push("/dashboard-blog-view");
                }}
              />
            );
          })}
        </ReuseableTable>
        {/* table end */}
      </div>
      {/* delete modal start */}
      <Modal visible={showModal.delete}>
        <DeleteBlogModal
          detail={singleChikd}
          onDeleteSuccess={() => {
            setShowModal((prev) => {
              return { ...prev, delete: false, success_delete: true };
            });
          }}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
            setSingleChild({});
          }}
          onFinish={() => {
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
            setSingleChild({});
            setRefreshTable(!refreshTable);
          }}
        />
      </Modal>
      {/* delete modal end */}
      {/* delete modal start */}
      <Modal visible={showModal.success_delete}>
        <SuccessDeleteModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, success_delete: false };
            });
          }}
        />
      </Modal>
      {/* delete modal end */}
    </>
  );
};

export default BlogArticle;
