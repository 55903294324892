import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthLayout from "../../component/auth/layout";
import "../../styles/auth/AuthForm.css";
import Modal from "react-awesome-modal";
import SuccessModal from "./SuccessModal";
import { FaTimes } from "react-icons/fa";

const ResetPassword = () => {
  const history = useHistory();
  const [details, setDetails] = useState({
    email: "",
  });
  const [showModal, setShowModal] = useState(false);
  // const [showPassword, setShwoPassord] = useState(false);
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  return (
    <>
      <AuthLayout
        title={`Reset Password`}
        bigText={`Oops, sorry about that`}
        smallText={`Enter your email address so we can send you a reset link`}
      >
        <div
          // onClick={() => history.push("/sign-up")}
          className="auth-form-wrap"
        >
          {/* form start */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowModal(true)
              //   history.push("/sign-up");
            }}
            action=""
            className="form"
          >
            {/* form group start */}
            <div className="form-group">
              {/* <label htmlFor="email" className="form-label">
                <figure className="img-box">
                  <img src={profileIcon} className="img" alt="" />
                </figure>
              </label> */}
              <input
                type="email"
                name="email"
                id="email"
                className="form-input white-black-color"
                placeholder="Enter your email address"
                onChange={handleChange}
                value={details.email}
              />
              <p
                className={`placeholder  white-black-bg white-black-color ${
                  details.email && "placeholder-active"
                }`}
              >
                Email Address
              </p>
            </div>
            {/* form group end */}

            {/* link to forget page start */}
            <div className="rember-login-box">
              <p className="text">
                Remebered your password?{" "}
                <span onClick={() => history.push("/login")}>Login here</span>
              </p>
            </div>
            {/* link to forget page end */}
            {/* submit form start */}
            <input
              type="submit"
              value="Send Reset Link"
              className="form-group btn-reuse form-submit"
            />
            {/* submit form  end */}
          </form>
          {/* form end */}
        </div>
      </AuthLayout>
        {/* modal start */}
        <Modal visible={showModal} effect="fadeInUp">
        <SuccessModal
          textOne={`Reset Link Sent Successfully`}
          textTwo={`Kindly access the password  reset link sent to your email`}
          textThree={`Please note the link expires after 24hours`}
          btnText={`Proceed to Login`}
        >
          <button onClick={() => history.push("/set-password")} className="btn">
          Close
          </button>
          <div onClick={() => setShowModal(false)} className="close-icon">
            <FaTimes className="icon" />
          </div>
        </SuccessModal>
      </Modal>
      {/* modal end */}
    </>
  );
};

export default ResetPassword;
