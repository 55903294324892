import React from "react";
import "../../../styles/dashboard/SidebarMenu.css";
import petmeLogo from "../../../assets/petme-white-new-logo.svg";
import { SidebarDataList } from "../../../utils/SideBarDataList";
import { Link, useLocation } from "react-router-dom";
import {  useSelector } from "react-redux";

const SidebarMenu = () => {
  const location = useLocation();
  const { user } = useSelector(
    state => state.user
    )
  return (
    <div className="sidebar-menu-wrap">
      {/* logo box start */}
      <div className="logo-box">
        <figure className="img-box">
          <img src={petmeLogo} alt="" className="img" />
        </figure>
        <p className="text">{user?.role}</p>
      </div>
      {/* logo box end */}
      {/* list box start */}
      <div className="list-box">
        <div className="content-box">
          {SidebarDataList.map((chi, idx) => {
            const { title, dash, list } = chi;
            return (
              <div key={idx} className="box">
                {title && (
                  <div className="title-box">
                    <p className="title">{title}</p>
                    <span></span>
                  </div>
                )}
                {dash && (
                  <div className="dash">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                )}
                {list.map((child, index) => {
                  const { name, link, img } = child;
                  return (
                    <Link
                      to={link}
                      key={index}

                      className={`link-box ${
                        location.pathname.includes(link) && "link-box-active"
                      }`}
                    >
                      <figure className="img-box">
                        <img src={img} alt="" className="img" />
                      </figure>
                      <p className="name">{name}</p>
                    </Link>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {/* list box end */}
    </div>
  );
};

export default SidebarMenu;
