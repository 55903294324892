


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from '../../utils/axios'; 
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import setAuthToken from '../../utils/auth';
toast.configure();



export const getShopOrders = createAsyncThunk(
  'admin/lghop-orders',
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(`/admin/shop/orders?paginate=${formData?.per_page || 10}&sort=${formData?.sort || ""}`);
      // console.log('Login', data);
      if (!data.success) {
        // console.log(data.message);
        toast.error(data.message, {
          theme: 'colored'
        });
        return thunkAPI.rejectWithValue(data);
      }
      if(data?.success){
        // console.log(data);
        await thunkAPI.dispatch(setShopOrders(data.orders));
      }
      // console.log(data);
    //   await setAuthToken(data.token);
    //   await thunkAPI.dispatch(login(data));
    //   return thunkAPI.rejectWithValue(data);
    } catch (err) {
        console.log(err);
      // localStorage.removeItem('token');
    //   if (error) {
    //     toast.error(error, {
    //       theme: 'colored'
    //     });
    //     console.log(error);
    //     return;
    //   }
    //   toast.error(err.message, {
    //     theme: 'colored'
    //   });
    }
  }
);

export const getShopOrdersPagination = createAsyncThunk(
  'admin/lghop-orders',
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(formData, {

      });
      // console.log('Login', data);
      if (!data.success) {
        // console.log(data.message);
        toast.error(data.message, {
          theme: 'colored'
        });
        return thunkAPI.rejectWithValue(data);
      }
      if(data?.success){
        // console.log(data);
        await thunkAPI.dispatch(setShopOrders(data.orders));
      }
      // console.log(data);
    //   await setAuthToken(data.token);
    //   await thunkAPI.dispatch(login(data));
    //   return thunkAPI.rejectWithValue(data);
    } catch (err) {
        console.log(err);
      // localStorage.removeItem('token');
    //   if (error) {
    //     toast.error(error, {
    //       theme: 'colored'
    //     });
    //     console.log(error);
    //     return;
    //   }
    //   toast.error(err.message, {
    //     theme: 'colored'
    //   });
    }
  }
);

export const shop_order = createSlice({
  name: 'shop_orders',
  initialState: {
    shop_orders: {},
    isAuth: false,
    loading: false,
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setShopOrders: (state, action) => {
      state.isAuth = true;
      state.shop_orders = action.payload;
      state.loading = false;
    },
    login: (state, action) => {
      state.shop_orders = action.payload.user;
      state.isAuth = true;
      state.loading = false;
    },
    // logout: state => {
    //   delete axios.defaults.headers.common['Authorization'];
    //   localStorage.removeItem('token');
    //   state.user = null;
    //   state.isAuth = false;
    //   state.loading = false;
    //   state.balances = [];
    //   state = null;
    // }
  },
  extraReducers: {
    [getShopOrders.pending]: state => {
      state.loading = true;
    },
    [getShopOrders.fulfilled]: state => {
      state.loading = false;
    },
    [getShopOrders.rejected]: state => {
      localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getShopOrdersPagination.pending]: state => {
      state.loading = true;
    },
    [getShopOrdersPagination.fulfilled]: state => {
      state.loading = false;
    },
    [getShopOrdersPagination.rejected]: state => {
      localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  }
});

// Action creators are generated for each case reducer function
export const { login, setUser, setShopOrders} =
  shop_order.actions;

export default shop_order.reducer;
