import React, { useContext, useState } from "react";
import ActionContext from "../../../context/ActionContext";
import "../../../styles/dashboard/DashboardLayout.css";
import HeaderNav from "./HeaderNav";
import SidebarMenu from "./SidebarMenu";
// import { useHistory } from 'react-router-dom';
import Modal from "react-awesome-modal";
import ChangePasswordModal from "./ChangePassword";
import SuccessPasswordChangeModal from "./SuccessPasswordChangeModal";
import SystemSettingsModal from "./SystemSettingsModal";

const DashboardLayout = ({ children, pageTitle, pageSmallText }) => {
  const actionCtx = useContext(ActionContext);
  const [showModal, setShowModal] = useState({
    change_password: false,
  });
  return (
    <>
      <div className="dashboard-layout-wrap">
        {/* header start */}
        {/* <div
          className="header-wrap"
          style={{ zIndex: actionCtx.showProfileDropBox ? 4 : 3 }}
        >
          <HeaderNav title={pageTitle} text={pageSmallText} />
        </div> */}
        {/* header end */}
        {/* side bar start */}
        <div className="sidebar-wrap">
          <div className="blur-blue"></div>
          <div className="blur-yellow"></div>
          <SidebarMenu />
        </div>
        {/* side bar end */}
        {/* main start */}
        <div
          // onClick={() => actionCtx.setShowProfileDropBox(false)}
          className="main-wrap"
        >
          {/* header start */}
          <div
            className="header-wrap"
            // style={{ zIndex: actionCtx.showProfileDropBox ? 4 : 3 }}
          >
            <HeaderNav title={pageTitle} text={pageSmallText} />
          </div>
          {/* header end */}
          <div
            onClick={() => actionCtx.setShowProfileDropBox(false)}
            className=""
          >
            {children}
          </div>
        </div>
        {/* main end */}
      </div>
      {/* password modal start */}
      <div className="">
        <Modal visible={actionCtx.changePassword}>
          <ChangePasswordModal />
        </Modal>
      </div>

      {/* password modal end */}
      {/* password modal start */}
      <div className="">
        <Modal visible={actionCtx.changePasswordSuccess}>
          <SuccessPasswordChangeModal />
        </Modal>
      </div>

      {/* password modal end */}
      {/* system setting modal start */}
      <div className="">
        <Modal visible={actionCtx.systemSetting}>
          <SystemSettingsModal />
        </Modal>
      </div>

      {/* system setting modal end */}
    </>
  );
};

export default DashboardLayout;
