import React from "react";
import ReuseableTable from "../../../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../../../component/dashboard/tables/TableRow";

const PetDetailParent = ({ detail }) => {
  const list = [
    { pet: "Enzo", type: "Cat", sex: "Female", service_provider: "N/A" },
    { pet: "Enzo", type: "Cat", sex: "Female", service_provider: "N/A" },
    {
      pet: "Enzo",
      type: "Cat",
      sex: "Female",
      service_provider: "Kuramo Vets & Doctors",
    },
  ];
  return (
    <div className="pet-wrap-box">
      <ReuseableTable
        //    loading={loading}
        sN
        one={`Pet `}
        two={`Type`}
        three={`Sex`}
        four={"Service Provider"}
        //   oneDrop
        twoDrop
        threeDrop
        fourDrop
        //   moreThanFive={parentList?.length >= 3}
      >
        {detail?.pets?.map((chi, idx) => {
          const { name, type, gender, service_provider } = chi;
          return (
            <TableRow
              key={idx}
              sN={idx + 1}
              one={name}
              two={type || <>&nbsp;</>}
              three={gender}
              four={service_provider || <>&nbsp;</>}
            />
          );
        })}
      </ReuseableTable>
    </div>
  );
};

export default PetDetailParent;
