import BlogArticle from "../../../pages/dashboard/blog/BlogArticle";
import BlogSettings from "../../../pages/dashboard/blog/BlogSettings";
import CreateBlogPost from "../../../pages/dashboard/blog/CreateBlogPost";
import UpdateBlogPost from "../../../pages/dashboard/blog/UpdateBlogPost";
import ViewBlogArticle from "../../../pages/dashboard/blog/ViewBlogArticle";

export const blog_route_group = [
  {
    path: "/dashboard-blog",
    component: BlogArticle,
    title: "blog",
    smallTitle: "Review and Manage all Blog Items on Petme",
  },
  {
    path: "/dashboard-blog-settings",
    component: BlogSettings,
    title: "blog",
    smallTitle: "Review and Manage all Blog Items on Petme",
  },
  {
    path: "/dashboard-blog-create",
    component: CreateBlogPost,
    title: "blog",
    smallTitle: "Review and Manage all Blog Items on Petme",
  },
  {
    path: "/dashboard-blog-update",
    component: UpdateBlogPost,
    title: "blog",
    smallTitle: "Review and Manage all Blog Items on Petme",
  },
  {
    path: "/dashboard-blog-view",
    component: ViewBlogArticle,
    title: "blog",
    smallTitle: "Review and Manage all Blog Items on Petme",
  },
];
