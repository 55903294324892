import React from "react";
import CommonModal from "./CommonModal";

const SuccessRejectModal = ({ children }) => {
  return (
    <>
      <CommonModal
        success
        bigText={`Order Successfully Rejected`}
        smallText={`The Client will be notified of the reason
      via mail`}
      >
        {children}
      </CommonModal>
    </>
  );
};

export default SuccessRejectModal;
