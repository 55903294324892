import React, { useState } from "react";
import ReuseableTable from "../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../component/dashboard/tables/TableRow";
import CommonHeader from "./CommonHeader";
import Modal from "react-awesome-modal";
import SettleTransactionModal from "./modal/SettleTransactionModal";
import SuccessSettleTransactionModal from "./modal/SuccessSettleTransactionModal";
import ViewPaymentRequestModal from "./modal/ViewPaymentRequestModal";

const list = [
  {
    name: "Kuramo Vets & Doctors",
    req_amount: "N243,091",
    time: "Jul 28 2022 07:09pm",
    status: "Yet to Download",
  },
  {
    name: "Kuramo Vets & Doctors",
    req_amount: "N243,091",
    time: "Jul 28 2022 07:09pm",
    status: "Yet to Download",
  },
  {
    name: "Kuramo Vets & Doctors",
    req_amount: "N243,091",
    time: "Jul 28 2022 07:09pm",
    status: "Download",
  },
  {
    name: "Kuramo Vets & Doctors",
    req_amount: "N243,091",
    time: "Jul 28 2022 07:09pm",
    status: "Download",
  },
  {
    name: "Kuramo Vets & Doctors",
    req_amount: "N243,091",
    time: "Jul 28 2022 07:09pm",
    status: "Yet to Download",
  },
];

const PaymentRequestPending = () => {
  const [indexNo, setIndexNo] = useState("");
  const [showModal, setShowModal] = useState({
    settle: false,
    settle_success: false,
    view: false,
  });
  return (
    <>
      <div>
        <CommonHeader />
        {/* table start */}
        <ReuseableTable
          //   loading={loading}
          sN
          one={"Name"}
          two={"Req Amount"}
          three={`Time`}
          five={"Status"}
          oneDrop
          twoDrop
          threeDrop
          fiveDrop
          action
          moreThanFive={list?.length >= 3}
          //   tableTwo
        >
          {list.map((chi, idx) => {
            const { name, req_amount, time, status } = chi;
            return (
              <TableRow
                sN={idx + 1}
                setIndexNo={() => setIndexNo(idx)}
                rowNo={idx}
                indexNo={indexNo}
                key={idx}
                action
                one={name}
                two={req_amount}
                three={time}
                status={status}
                statusColor={status === "Yet to Download" ? "black" : "yellow"}
                viewText={`View Profile`}
                onViewDetail={() =>
                    setShowModal((prev) => {
                      return { ...prev, view: true };
                    })
                  }
                onList={() =>
                  setShowModal((prev) => {
                    return { ...prev, settle: true };
                  })
                }
                addText={`Download`}
                listText="Settle Transaction"
              />
            );
          })}
        </ReuseableTable>
        {/* table end */}
      </div>
      {/* settle modal start */}
      <Modal effect="fadeInUp" visible={showModal.settle}>
        <SettleTransactionModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, settle: false };
            })
          }
          onSettle={() =>
            setShowModal((prev) => {
              return { ...prev, settle: false, settle_success: true };
            })
          }
        />
      </Modal>
      {/* settle modal end */}
      {/* settle success modal start */}
      <Modal effect="fadeInUp" visible={showModal.settle_success}>
        <SuccessSettleTransactionModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, settle_success: false };
            })
          }
        />
      </Modal>
      {/* settle success modal end */}
        {/* view request modal start */}
        <Modal effect="fadeInUp" visible={showModal.view}>
        <ViewPaymentRequestModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, view: false };
            })
          }
        />
      </Modal>
      {/* view request modal end */}
    </>
  );
};

export default PaymentRequestPending;
