import React from 'react'
import Button from '../../../../component/dashboard/fragments/Button'
import CommonModal from './CommonModal'

const SuccesActivateModal = ({onClose}) => {
  return (
    <>
    <CommonModal
      onClose={onClose}
      success
      bigText={`User has been successfully reactivated`}
    >
      <Button bgColor={`#FFA72C`} text="Close" onClick={onClose} />
    </CommonModal>
  </>
  )
}

export default SuccesActivateModal