import React, { useEffect, useState } from "react";
import ReuseableTable from "../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../component/dashboard/tables/TableRow";
import CommonHeader from "./CommonHeader";
import Modal from "react-awesome-modal";
import CommonParentDetailModal from "./modals/parent-modal/CommonParentDetailModal";
import EditParentDetailModal from "./modals/parent-modal/EditParentDetailModal";
import EditParentDetailConfirmModal from "./modals/parent-modal/EditParentDetailConfirmModal";
import EditParentDetailSuccess from "./modals/parent-modal/EditParentDetailSuccess";
import DisablePetParentModal from "./modals/parent-modal/DisablePetParentModal";
import DisableSuccessModal from "./modals/parent-modal/DisableSuccessModal";
import EnablePetParentDetailModal from "./modals/parent-modal/EnablePetParentDetailModal";
import EnableSuccessModal from "./modals/parent-modal/EnableSuccessModal";
import SendAppLinkModal from "./modals/parent-modal/SendAppLinkModal";
import SendAppInviteLinkSuccessModal from "./modals/parent-modal/SendAppInviteLinkSuccessModal";
import CreatePetParentModal from "./modals/parent-modal/CreatePetModal";
import CreatePetDetailSuccessModal from "./modals/CreatePetDetailSuccess";
import { useDispatch, useSelector } from "react-redux";
import {
  getPetParents,
  getPetParentsPagination,
} from "../../../redux/pets/petTypes";
import DeletePetParentModal from "./modals/parent-modal/DeletePetParentModal";


const PetParent = () => {
  const [indexNo, setIndexNo] = useState("");
  const dispatch = useDispatch();
  const { petParents, loading } = useSelector((state) => state.pet_type);
  const [singleChild, setSingleChild] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const [showModal, setShowModal] = useState({
    view: false,
    edit: false,
    edit_confirm: false,
    edit_success: false,
    disable: false,
    disable_success: false,
    enable: false,
    enable_success: false,
    send_app_link: false,
    send_app_link_success: false,
    create_parent: false,
    create_parent_success: false,
    delete: false,
  });

  const handlePagination = async (param) => {
    const linkSplit = param.split("api/");
    const link = linkSplit[1];
    dispatch(getPetParentsPagination(link));
  };

  const handleFinish = () => {
    setShowModal({
      view: false,
      edit: false,
      edit_confirm: false,
      edit_success: false,
      disable: false,
      disable_success: false,
      enable: false,
      enable_success: false,
      send_app_link: false,
      send_app_link_success: false,
      create_parent: false,
      create_parent_success: false,
      delete: false,
    });
    setRefreshTable(!refreshTable);
  };

  useEffect(() => {
    dispatch(getPetParents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  return (
    <div>
      <div className="dashboard-pet-wrap">
        {/* common headr start */}
        <CommonHeader
          onSearch={(e) => console.log(e)}
          onCreateParent={() =>
            setShowModal((prev) => {
              return { ...prev, create_parent: true };
            })
          }
        />
        {/* common header end */}
        {/* table start */}
        <ReuseableTable
          //    loading={loading}
          sN
          one={`Name`}
          two={`Pet Count`}
          three={`Phone No`}
          four={"Email"}
          oneDrop
          twoDrop
          five={`App Status`}
          fiveDrop
          action
          moreThanFive={petParents?.data?.length >= 3}
          toNo={petParents?.to}
          totalNo={petParents?.total}
          fromNo={petParents?.from}
          nextPage={petParents?.next_page_url}
          prevPage={petParents?.prev_page_url}
          onNext={() => handlePagination(petParents?.next_page_url)}
          onPrev={() => handlePagination(petParents?.prev_page_url)}
        >
          {petParents?.data?.map((chi, idx) => {
            const { name, pets, mobile, email, status } = chi;
            return (
              <TableRow
                loading={loading}
                action
                setIndexNo={() => setIndexNo(idx)}
                rowNo={idx}
                indexNo={indexNo}
                key={idx}
                sN={idx + 1}
                one={name || <>&nbsp;</>}
                two={pets?.length > 0 ? pets?.length : "0"}
                three={mobile || <>&nbsp;</>}
                four={email || <>&nbsp;</>}
                status={status || <>&nbsp;</>}
                listText={status !== "verified" && "Enable Pet Parent"}
                sendAppInviteText={
                  status !== "verified" && "Send App Invite link"
                }
                onAppInvite={() =>
                  setShowModal((prev) => {
                    return { ...prev, send_app_link: true };
                  })
                }
                deleteText={status === "verified" && "Disable Pet Parent"}
                statusColor={
                  status?.toLowerCase() === "verified" ? "deliver" : "red"
                }
                viewText={`View Details`}
                onViewDetail={() => {
                  setShowModal((prev) => {
                    return { ...prev, view: true };
                  });
                  console.log(chi);
                  setSingleChild(chi)
                }}
                onDelete={() => {
                  setShowModal((prev) => {
                    return { ...prev, disable: true };
                  });
                  setSingleChild(chi);
                }}
                onList={() => {
                  setShowModal((prev) => {
                    return { ...prev, enable: true };
                  });
                  setSingleChild(chi);
                }}
                editText={`Edit Parent Details`}
                onEdit={() =>
                  setShowModal((prev) => {
                    return { ...prev, edit: true };
                  })
                }
                deleteTextTwo="Delete Parent"
                onDeleteTwo={() => {
                  setShowModal((prev) => {
                    return { ...prev, delete: true };
                  });
                  setSingleChild(chi);
                }}
              />
            );
          })}
        </ReuseableTable>
      </div>
      {/* view modal start */}
      <Modal effect="fadeInUp" visible={showModal.view}>
        <CommonParentDetailModal
        detail={singleChild}
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, view: false };
            })
          }
        />
      </Modal>
      {/* view modal end */}
      {/* edit modal start */}
      <Modal effect="fadeInUp" visible={showModal.edit}>
        <EditParentDetailModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, edit: false };
            })
          }
          onUpdate={() =>
            setShowModal((prev) => {
              return { ...prev, edit_confirm: true, edit: false };
            })
          }
        />
      </Modal>
      {/* edit modal end */}
      {/* edit modal start */}
      <Modal effect="fadeInUp" visible={showModal.edit_confirm}>
        <EditParentDetailConfirmModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, edit_confirm: false };
            })
          }
          onUpdate={() =>
            setShowModal((prev) => {
              return { ...prev, edit_success: true, edit_confirm: false };
            })
          }
        />
      </Modal>
      {/* edit modal end */}
      {/* edit success modal start */}
      <Modal effect="fadeInUp" visible={showModal.edit_success}>
        <EditParentDetailSuccess
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, edit_success: false };
            })
          }
        />
      </Modal>
      {/* edit  success modal end */}
      {/* disable  modal start */}
      <Modal effect="fadeInUp" visible={showModal.disable}>
        <DisablePetParentModal
          detail={singleChild}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, disable: false };
            });
            setSingleChild({});
          }}
          onDisable={() =>
            setShowModal((prev) => {
              return { ...prev, disable: false, disable_success: true };
            })
          }
        />
      </Modal>
      {/* disable modal end */}
      {/* disable success  modal start */}
      <Modal effect="fadeInUp" visible={showModal.disable_success}>
        <DisableSuccessModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, disable_success: false };
            });
            handleFinish();
            setSingleChild({});
          }}
        />
      </Modal>
      {/* disable success modal end */}
      {/* enable  modal start */}
      <Modal effect="fadeInUp" visible={showModal.enable}>
        <EnablePetParentDetailModal
          detail={singleChild}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, enable: false };
            });
            setSingleChild({});
          }}
          onEnable={() => {
            setShowModal((prev) => {
              return { ...prev, enable: false, enable_success: true };
            });
          }}
        />
      </Modal>
      {/* enable modal end */}
      {/* enable success  modal start */}
      <Modal effect="fadeInUp" visible={showModal.enable_success}>
        <EnableSuccessModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, enable_success: false };
            });
            handleFinish();
            setSingleChild({});
          }}
        />
      </Modal>
      {/* enable success modal end */}
      {/* delete success  modal start */}
      <Modal effect="fadeInUp" visible={showModal.delete}>
        <DeletePetParentModal
          detail={singleChild}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
            handleFinish();
            setSingleChild({});
          }}
        />
      </Modal>
      {/* delete success modal end */}
      {/* send app invite link  modal start */}
      <Modal effect="fadeInUp" visible={showModal.send_app_link}>
        <SendAppLinkModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, send_app_link: false };
            })
          }
          onSendAppLink={() =>
            setShowModal((prev) => {
              return {
                ...prev,
                send_app_link: false,
                send_app_link_success: true,
              };
            })
          }
        />
      </Modal>
      {/* send app invite link modal end */}
      {/* send app link success  modal start */}
      <Modal effect="fadeInUp" visible={showModal.send_app_link_success}>
        <SendAppInviteLinkSuccessModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, send_app_link_success: false };
            })
          }
        />
      </Modal>
      {/* send app link success modal end */}
      {/* create parent  modal start */}
      <Modal effect="fadeInUp" visible={showModal.create_parent}>
        <CreatePetParentModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, create_parent: false };
            })
          }
          onCreate={() =>
            setShowModal((prev) => {
              return {
                ...prev,
                create_parent_success: true,
                create_parent: false,
              };
            })
          }
        />
      </Modal>
      {/* create parent modal end */}
      {/* create parent success  modal start */}
      <Modal effect="fadeInUp" visible={showModal.create_parent_success}>
        <CreatePetDetailSuccessModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, create_parent_success: false };
            })
          }
        />
      </Modal>
      {/* create parent success modal end */}
    </div>
  );
};

export default PetParent;
