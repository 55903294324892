import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const SuccessCreateAppointmentModal = ({ onClose }) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
        success
        smallText={`The Pet Parent has been notified via mail`}
        bigText={`Appointment Successfully Created`}
      >
        <Button bgColor={`#FFA72C`} text="Close" onClick={onClose} />
      </CommonModal>
    </>
  );
};

export default SuccessCreateAppointmentModal;
