import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../../styles/dashboard/shop/CommonHeader.css";
import searchIcon from "../../../assets/search-icon.svg";
import bukIcon from "../../../assets/blog-btn-create-icon.svg";
import Button from "../../../component/dashboard/fragments/Button";

const linkList = [
  { name: "Articles", link: "/dashboard-blog" },
  { name: "Blog Settings", link: "/dashboard-blog-settings" },
];

const CommonHeader = ({  onSearch }) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <div className="shop-common-header-wrap">
      {/* link box start */}
      <div className="link-box">
        {linkList.map((chi, idx) => {
          const { name, link } = chi;
          return (
            <p
              onClick={() => history.push(link)}
              key={idx}
              className={`child ${
                location.pathname === link && "child-active"
              }`}
            >
              <span>{name}</span>
            </p>
          );
        })}
      </div>
      {/* link box end */}
      {/* button search box start */}
      <div className="btn-search-box">
        <div className="form-group">
          <label htmlFor="search" className="label">
            <figure className="img-box">
              <img src={searchIcon} alt="" className="img" />
            </figure>
          </label>
          <input
            type="text"
            id="search"
            className="input"
            placeholder="Search"
            onKeyUp={(e) => onSearch(e.target.value)}
          />
        </div>
          <Button
            onClick={() => history.push("/dashboard-blog-create")}
            text={'Create Blog Post'}
          >
            {" "}
            <figure className="img-box">
              <img src={bukIcon} alt="" className="img" />
            </figure>
          </Button>
      </div>
      {/* button search box end */}
    </div>
  );
};

export default CommonHeader;
