import React, { useState } from "react";
import "../../../styles/dashboard/HeaderNav.css";
import userImg from "../../../assets/user-img.png";
import dropIcon from "../../../assets/down-icon.svg";
import profileIcon from "../../../assets/change-picture-icon.svg";
import settingIcon from "../../../assets/system-settings-icon.svg";
import logoutIcon from "../../../assets/logout-icon.svg";
import passwordIcon from "../../../assets/change-password-icon.svg";
import { useContext } from "react";
import ActionContext from "../../../context/ActionContext";
import Modal from "react-awesome-modal";
import { useDispatch, useSelector } from "react-redux";
import { Dna } from "react-loader-spinner";
import { logoutUser } from "../../../redux/user";
import { useHistory } from "react-router-dom";

const HeaderNav = ({ title, text }) => {
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, user } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState({
    change_password: false,
  });

  const handleLogout = async () => {
    const data = await dispatch(logoutUser());
    if (data?.payload?.success) {
      actionCtx.setShowProfileDropBox(false);
      localStorage.clear();
      history.push("/login");
    }
  };

  return (
    <>
      <div className="header-nav-wrap">
        <div
          className="text-box"
          onClick={() => actionCtx.setShowProfileDropBox(false)}
        >
          <p className="title">{title || `Hello ${user?.name}`}</p>
          <p className="text">{text || "small text"}</p>
        </div>
        {/* profile box start */}
        <div className="profile-box">
          <div
            className="wrap"
            onClick={() =>
              actionCtx.setShowProfileDropBox(!actionCtx.showProfileDropBox)
            }
          >
            <figure className="user-img-box">
              <img src={userImg} alt="" className="img" />
            </figure>
            <figure className="drop-img-box">
              <img src={dropIcon} alt="" className="img" />
            </figure>
          </div>
          {/* drop-box-wrap start */}
          <div
            className={`drop-box-wrap ${
              actionCtx.showProfileDropBox && "drop-box-wrap-show"
            }`}
          >
            {/* text icon start */}
            <div
              onClick={() => actionCtx.setShowProfileDropBox(false)}
              className="text-icon-box"
            >
              <figure className="img-box">
                <img src={profileIcon} alt="" className="img" />
              </figure>
              <p className="text">Change Profile Picture</p>
            </div>
            {/* text icon end */}
            {/* text icon start */}
            <div
              onClick={() => {
                actionCtx.setShowProfileDropBox(false);
                actionCtx.setChangePassword(true);
              }}
              className="text-icon-box"
            >
              <figure className="img-box">
                <img src={passwordIcon} alt="" className="img" />
              </figure>
              <p className="text">Change Password</p>
            </div>
            {/* text icon end */}
            {/* text icon start */}
            <div
              onClick={() => {
                actionCtx.setShowProfileDropBox(false);
                actionCtx.setSystemSetting(true);
              }}
              className="text-icon-box"
            >
              <figure className="img-box">
                <img src={settingIcon} alt="" className="img" />
              </figure>
              <p className="text">System Settings</p>
            </div>
            {/* text icon end */}
            {/* text icon start */}
            {!loading ? (
              <div onClick={handleLogout} className="text-icon-box">
                <figure className="img-box">
                  <img src={logoutIcon} alt="" className="img" />
                </figure>
                <p className="text red">Logout</p>
              </div>
            ) : (
              <div
                className="dna-wrapper-box"
                // style={{height: "100%" }}
              >
                <Dna
                  visible={true}
                  height="50"
                  width="100"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            {/* text icon end */}
          </div>
          {/* drop-box-wrap end */}
        </div>
        {/* profile box end */}
      </div>
    </>
  );
};

export default HeaderNav;
