import React, { useEffect } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import "../../../styles/dashboard/blog/DashboardBlog.css";
import likeIcon from "../../../assets/blog-like-icon.svg";
import viewIcon from "../../../assets/blog-view-icon.svg";
import Button from "../../../component/dashboard/fragments/Button";
import { useDispatch, useSelector } from "react-redux";
import { getBlogPost } from "../../../redux/blog/BlogPosts";
import moment from "moment";
import Moment from "react-moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ViewBlogArticle = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { blog_post } = useSelector((state) => state.blog_posts);

  useEffect(() => {
    getBlogDetail();
  }, []);

  const getBlogDetail = async () => {
    const id = localStorage.getItem("blog-post-id");
    const data = await dispatch(getBlogPost(id));
  };

  return (
    <>
      <div className="view-blog-article">
        {/* ctitle back box start */}
        <div className="title-back-box">
          <FaAngleLeft className="icon" />
          <p onClick={() => history.goBack()} className="text">
            Blog /{" "}
          </p>{" "}
          <p className="text text-active">Create Blog Post</p>
        </div>
        {/* ctitle back box end */}
        <div className="content-box">
          <div className="left-box">
            <div className="img-wrap-box">
              {/* <div className="heading">{blog_post?.category?.name}</div> */}
            </div>
            <p className="title">{blog_post?.title}</p>
            {/* like view ... box start */}
            <div className="like-view-box">
              {/* box start */}
              <div className="box">
                <figure className="img-box">
                  <img src={viewIcon} alt="" className="img" />
                </figure>
                <p className="text">{blog_post?.views || "1,283"}</p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <figure className="img-box">
                  <img src={likeIcon} alt="" className="img" />
                </figure>
                <p className="text">{blog_post?.likes || "102"}</p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="text">{blog_post?.author}</p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="text">
                  <Moment format="MMMM DD YYYY">{blog_post?.created_at}</Moment>
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="text">{blog_post?.category?.name}</p>
              </div>
              {/* box end */}
            </div>
            {/* like view ... box  end */}
            {/* post text start */}
            <ReactQuill
              value={blog_post?.description}
              readOnly={true}
              theme={"bubble"}
              className="article-text"
            />
            {/* post text end */}
          </div>
          {/* left box end */}
          <div className="right-box">
            <p className="title">Comments (23)</p>
            {/* comment start */}
            <div className="comment-wrap">
              {/* comment box start */}
              <div className="comment-box">
                <div className="name-date-box">
                  <p className="name">Yusuf Obi Dan-Baba</p>
                  <p className="date">3days ago</p>
                </div>
                <p className="comment">
                  Amet, viverra at elit id nunc non tincidunt et. Ac
                  pellentesque orci, bibendum dolor duis habitant. Cursus id ac
                  et viverra ultrices ac. Morbi massa facilisis congue euismod
                  aliquet. Maecenas id in tempus ac nulla habitant. Id{" "}
                </p>
              </div>
              {/* comment box end */}
              {/* comment box start */}
              <div className="comment-box">
                <div className="name-date-box">
                  <p className="name">Yusuf Obi Dan-Baba</p>
                  <p className="date">3days ago</p>
                </div>
                <p className="comment">
                  Amet, viverra at elit id nunc non tincidunt et. Ac
                  pellentesque orci, bibendum dolor duis habitant. Cursus id ac
                  et viverra ultrices ac. Morbi massa facilisis congue euismod
                  aliquet. Maecenas id in tempus ac nulla habitant. Id{" "}
                </p>
              </div>
              {/* comment box end */}
              {/* comment box start */}
              <div className="comment-box">
                <div className="name-date-box">
                  <p className="name">Yusuf Obi Dan-Baba</p>
                  <p className="date">3days ago</p>
                </div>
                <p className="comment">
                  Amet, viverra at elit id nunc non tincidunt et. Ac
                  pellentesque orci, bibendum dolor duis habitant. Cursus id ac
                  et viverra ultrices ac. Morbi massa facilisis congue euismod
                  aliquet. Maecenas id in tempus ac nulla habitant. Id{" "}
                </p>
              </div>
              {/* comment box end */}
              {/* comment box start */}
              <div className="comment-box">
                <div className="name-date-box">
                  <p className="name">Yusuf Obi Dan-Baba</p>
                  <p className="date">3days ago</p>
                </div>
                <p className="comment">
                  Amet, viverra at elit id nunc non tincidunt et. Ac
                  pellentesque orci, bibendum dolor duis habitant. Cursus id ac
                  et viverra ultrices ac. Morbi massa facilisis congue euismod
                  aliquet. Maecenas id in tempus ac nulla habitant. Id{" "}
                </p>
              </div>
              {/* comment box end */}
              {/* comment box start */}
              <div className="comment-box">
                <div className="name-date-box">
                  <p className="name">Yusuf Obi Dan-Baba</p>
                  <p className="date">3days ago</p>
                </div>
                <p className="comment">
                  Amet, viverra at elit id nunc non tincidunt et. Ac
                  pellentesque orci, bibendum dolor duis habitant. Cursus id ac
                  et viverra ultrices ac. Morbi massa facilisis congue euismod
                  aliquet. Maecenas id in tempus ac nulla habitant. Id{" "}
                </p>
              </div>
              {/* comment box end */}
            </div>
            {/* comment end */}

            {/* btn start */}
            <Button
              bgColor="rgba(66, 66, 66, 0.15)"
              btnStyle="btn-view"
              text="View More"
            />
            {/* btn end */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewBlogArticle;
