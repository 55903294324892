import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deactivateActivateProvider,
  getServiceProviders,
} from "../../../../redux/service-provider";
import { lowerCaseWrap } from "../../../../helpers/Helper";
import { Dna } from "react-loader-spinner";

const DeactivateModal = ({ onClose, onDeactivate, detail }) => {
  const { loadingActive } = useSelector((state) => state.service_provider);
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    // console.log(detail);
    // return;
    const obj = {
      id: detail?.id,
    };
    const data = await dispatch(deactivateActivateProvider(obj));
    if (data?.payload?.success) {
      onDeactivate();
      dispatch(getServiceProviders());
    }
    // console.log(data);
    // dispatch(getServiceProviders());
  };
  return (
    <>
      <CommonModal
        onClose={onClose}
        deactivate
        //   smallText={`The Pet Parent has been notified via mail`}
        bigText={`Are you sure you want to deactivate this profile? ( ${lowerCaseWrap(
          detail?.name
        )} )`}
      >
        {loadingActive ? (
          <>
            <div className="dna-wrapper-box" style={{ alignSelf: "center" }}>
              <Dna
                visible={true}
                height="80"
                width="100"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          </>
        ) : (
          <Button
            bgColor={`#DE0B0B`}
            text="Deactivate"
            onClick={handleSubmit}
          />
        )}
      </CommonModal>
    </>
  );
};

export default DeactivateModal;
