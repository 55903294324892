import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import "../../../../../styles/dashboard/service-provider/ServiceProviderProfileModal.css";
import defaultImg from "../../../../../assets/service-provider-profile-img.png";

const ProfileDetail = () => {
  const [rating, setRating] = useState(100); //

  const handleRating = (rate) => {
    setRating(rate);
    // other logic
  };
  return (
    <div className="service-provider-profile-detail-box">
      {/* top box start */}
      <div className="top-box">
        <div
          style={{ backgroundImage: `url(${defaultImg})` }}
          className="img-wrap"
        ></div>
        <div className="text-wrap">
          {/* box start */}
          <div className="box">
            <p className="light">Clinic Name</p>
            <p className="bold">Kuramo Vets & Doctors</p>
          </div>
          {/* box end */}
          {/* rate text box start */}
          <div className="rate-clinic-box">
            <div className="clinic">Clinic</div>
            <div className="rate-box">
              <Rating
                size={15}
                fillColor="#FFA72C"
                onClick={handleRating}
                ratingValue={"100"}
                // initialValue={"100"}
                allowHover={false}
                allowHalfIcon
                iconsCount={1}
                emptyColor="#FFA72C"
              />
              <p className="text">4.5</p>
            </div>
          </div>
          {/* rate text box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Phone Number</p>
            <p className="bold">+2348156666666</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Email</p>
            <p className="bold">login@kukukase.com</p>
          </div>
          {/* box end */}
        </div>
      </div>
      {/* top box end */}
      {/* middle box start */}
      <div className="middle-box">
        <div className="left-box">
          {/* box start */}
          <div className="box">
            <p className="light">Address</p>
            <p className="bold">
              28, Adamasingba Rd, Idimu, Lagos State, Nigeria
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Wallet Balance</p>
            <p className="bold green">+N 123,456</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Last Login</p>
            <p className="bold">3 Days ago</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Onboarding Date</p>
            <p className="bold">July 28 2021</p>
          </div>
          {/* box end */}
        </div>
        {/* end left box */}
        <div className="right-box">
            {/* box start */}
          <div className="box">
            <p className="light">Offered Services</p>
            <p className="bold">Pet Walk - N1,000/hr</p>
            <p className="bold">Pet Hotel - N1,000/hr</p>
            <p className="bold">Pet Training - N1,000/hr</p>
            <p className="bold">Pet Mating - N1,000/hr</p>
          </div>
          {/* box end */}
        </div>
      </div>
      {/* middle box end */}
      {/* bottom box start */}
      <div className="bottom-box">
           {/* box start */}
           <div className="box">
            <p className="light">Operating Hours</p>
            <p className="bold ">7:00am - 9:00pm</p>
          </div>
          {/* box end */}
             {/* box start */}
             <div className="box">
            <p className="light">Staff Count</p>
            <p className="bold">12</p>
          </div>
          {/* box end */}
             {/* box start */}
             <div className="box">
            <p className="light">Email Count</p>
            <p className="bold">243</p>
          </div>
          {/* box end */}
             {/* box start */}
             <div className="box">
            <p className="light">Email Sent</p>
            <p className="bold">1,232</p>
          </div>
          {/* box end */}
      </div>
      {/* bottom box end */}
    </div>
  );
};

export default ProfileDetail;
