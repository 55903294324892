import React from "react";
import { FaTimes } from "react-icons/fa";
import "../../../../styles/dashboard/pet/modal/DewormVaccinationDietModal.css";
import noContentImg from "../../../../assets/deworm-content-img.png";
import Button from "../../../../component/dashboard/fragments/Button";

const DewormDetailModal = ({ onClose, detail }) => {
  const list = ["", ""];
  return (
    <div className="deworm-vaccination-diet-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* end oc cancel box */}
      {/* modal title start */}
      <p className="modal-title">Manage Deworming</p>
      {/* modal title end */}
      {/* no content box start */}
      {detail?.deworm_details?.length  < 1 && (
        <div className="no-content-box">
          <div className="wrap">
            <figure className="img-box">
              <img src={noContentImg} alt="" className="img" />
            </figure>
            <p className="text">This pet currently has no deworm schdule.</p>
          </div>
        </div>
      )}
      {/* no content box end */}

      {/* content box start */}
      {detail?.deworm_details?.length > 0 && <div className="content-box">
        <div className="top-box">
          {/* box start */}
          <div className="box">
            <p className="light">Deworm Drug</p>
            <p className="bold">
              {detail?.deworm_details?.length > 0
                ? detail?.deworm_details[0]?.drug
                : ""}
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Administer rate</p>
            <p className="bold">
              {detail?.deworm_details?.length > 0
                ? detail?.deworm_details[0]?.administer_rate
                : ""}
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Deworm Frequency</p>
            <p className="bold">
              {detail?.deworm_details?.length > 0
                ? detail?.deworm_details[0]?.frequency
                : ""}
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Est Administration Duration</p>
            <p className="bold">
              {detail?.deworm_details?.length > 0
                ? detail?.deworm_details[0]?.administration_duration
                : ""}
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Last Deworm</p>
            <p className="bold">
              {detail?.deworm_details?.length > 0
                ? detail?.deworm_details[0]?.last_session
                : ""}
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Next Deworm</p>
            <p className="bold">
              {detail?.deworm_details?.length > 0
                ? detail?.deworm_details[0]?.next_session
                : ""}
            </p>
          </div>
          {/* box end */}
        </div>
        {/* end of top box */}
        {/* bottom box start */}
        <div className="bottom-box">
          {/* box start */}
          <div className="box">
            <p className="light">Created by </p>
            <p className="bold">
              {detail?.deworm_details?.length > 0
                ? detail?.deworm_details[0]?.created_by
                : ""}
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Last Edited on</p>
            <p className="bold">  {detail?.deworm_details?.length > 0
                ? detail?.deworm_details[0]?.updated_at
                : ""}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Location</p>
            <p className="bold">  {detail?.deworm_details?.length > 0
                ? detail?.deworm_details[0]?.location
                : ""}</p>
          </div>
          {/* box end */}
        </div>
        {/* bottom box end */}
      </div>}
      {/* content box end */}

      {/* close button start */}
      <Button
        onClick={onClose}
        text={`Close`}
        bgColor="#84BFCD"
        btnStyle={`btn-reuse`}
      />
      {/* close btn end */}
    </div>
  );
};

export default DewormDetailModal;
