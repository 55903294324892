import { useState, createContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

const ActionContext = createContext({
  authSlideShow: true,
  authSlideShowFunc: (param) => {},
  sidebarDropdown: {},
  sidebarDropdownFunc: (param) => {},
  isSidebarReduce: false,
  sidebarReduceFunc: () => {},
  isProfileBoxOpen: false,
  profileBoxOpenFunc: (param) => {},
  showProfileDropBox: false,
  setShowProfileDropBox: (param) => {},
  changePassword: false,
  changePasswordSuccess: false,
  setChangePassword: (param) => {},
  setChangePasswordSuccess: (param) => {},
  systemSetting: false,
  setSystemSetting: (param) => {},
});

export function ActionContextProvider(props) {
  const location = useLocation();
  const [isauthSlideShowVal, setIsAuthSlideShowVal] = useState(true);
  const [sidebarDropdownVal, setsidebarDropdownVal] = useState({
    one: location.pathname.includes("/dashboard-home") ? true : false,
    two: location.pathname.includes("/dashboard-projection") ? true : false,
    three: location.pathname.includes("/dashboard-actual") ? true : false,
    four: location.pathname.includes("/dashboard-general") ? true : false,
    five: location.pathname.includes("/dashboard-administration")
      ? true
      : false,
  });
  const [sidebarReduceVal, setSidebarReduceVal] = useState(false);
  const [profileboxShowVal, setprofileboxShowVal] = useState(false);
  const [showProfileDropBoxVal, setshowProfileDropBoxVal] = useState(false);
  const [changePasswordVal, setChangePasswordVal] = useState(false)
  const [changePasswordValSuccess, setChangePasswordValSuccess] = useState(false)
  const [systemSettingVal, setSystemSettingVal] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsAuthSlideShowVal(false);
    }, 1200);
  }, []);

  function authSlideShowFunction(param) {
    setIsAuthSlideShowVal(false);
  }

  function sidebarDropdownFunction(param) {
    if (param === "one") {
      setsidebarDropdownVal((prev) => {
        return { ...prev, one: !sidebarDropdownVal.one };
      });
    }
    if (param === "two") {
      setsidebarDropdownVal((prev) => {
        return { ...prev, two: !sidebarDropdownVal.two };
      });
    }
    if (param === "three") {
      setsidebarDropdownVal((prev) => {
        return { ...prev, three: !sidebarDropdownVal.three };
      });
    }
    if (param === "four") {
      setsidebarDropdownVal((prev) => {
        return { ...prev, four: !sidebarDropdownVal.four };
      });
    }
    if (param === "five") {
      setsidebarDropdownVal((prev) => {
        return { ...prev, five: !sidebarDropdownVal.five };
      });
    }
  }

  function sidebarReduceFunction() {
    setSidebarReduceVal(!sidebarReduceVal);
  }

  function profileBoxOpenFunction(param) {
    if (param === "any") {
      setprofileboxShowVal(!profileboxShowVal);
    }
    if (param === "close") {
      setprofileboxShowVal(false);
    }
  }

  function  setShowProfileDropBoxFunc (param){
    setshowProfileDropBoxVal(param)
  }

  function changePasswordFunc (param){
    setChangePasswordVal(param)
  }

  function changePasswordSuccessFunc (param){
    setChangePasswordValSuccess(param)
  }

  function setSystemSettingFunc (param){
    setSystemSettingVal(param)
  }

  const context = {
    authSlideShow: isauthSlideShowVal,
    authSlideShowFunc: authSlideShowFunction,
    sidebarDropdown: sidebarDropdownVal,
    sidebarDropdownFunc: sidebarDropdownFunction,
    isSidebarReduce: sidebarReduceVal,
    sidebarReduceFunc: sidebarReduceFunction,
    isProfileBoxOpen: profileboxShowVal,
    profileBoxOpenFunc: profileBoxOpenFunction,
    showProfileDropBox: showProfileDropBoxVal,
    setShowProfileDropBox: setShowProfileDropBoxFunc,
    changePassword: changePasswordVal,
    changePasswordSuccess: changePasswordValSuccess,
    setChangePassword: changePasswordFunc,
    setChangePasswordSuccess: changePasswordSuccessFunc,
    systemSetting: systemSettingVal,
    setSystemSetting: setSystemSettingFunc,
  };

  return (
    <ActionContext.Provider value={context}>
      {props.children}
    </ActionContext.Provider>
  );
}

export default ActionContext;