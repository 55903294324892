import dashboardImg from "../assets/dashboard-icon.svg";
import appointmentsImg from "../assets/appoinment-icon.svg";
import petsImg from "../assets/pets-icon.svg";
import shopImg from "../assets/pets-icon.svg";
import blogImg from "../assets/blog-icon.svg";
import userAdminImg from "../assets/user-icon.svg";
import serviceImg from "../assets/service-icon.svg";
import supportImg from "../assets/support-icon.svg";
import paymentImg from "../assets/payment-icon.svg";

export const SidebarDataList = [
  {
    title: "",
    list: [
      { name: "Dashboard", link: "/dashboard-home", img: dashboardImg },
      {
        name: "Appointments",
        link: "/dashboard-appointment",
        img: appointmentsImg,
      },
    ],
  },
  {
    title: "Manage",
    list: [
      { name: "Pets", link: "/dashboard-pets", img: petsImg },
      { name: "Shop", link: "/dashboard-shop", img: shopImg },
      { name: "Blog", link: "/dashboard-blog", img: blogImg },
      { name: "User Admin", link: "/dashboard-user-admin", img: userAdminImg },
      {
        name: "Service Providers",
        link: "/dashboard-service-provider",
        img: serviceImg,
      },
    ],
  },
  {
    title: "Finance",
    list: [
      {
        name: "Payment Requests",
        link: "/dashboard-payment-request",
        img: paymentImg,
      },
    ],
  },
  {
    title: "",
    dash: true,
    list: [{ name: "Support", link: "/dashboard-support", img: supportImg }],
  },
];
