import React from 'react'
import Button from '../../../../../component/dashboard/fragments/Button'
import CommonModal from '../CommonModal'

const SendAppLinkModal = ({onClose, onSendAppLink}) => {
  return (
    <>
    <CommonModal
      onClose={onClose}
     updateFoot
      // smallText={`The Pet Parent has been notified via mail`}
      bigText={`Are you sure you want to send App Invite Link?`}
    >
      <Button bgColor={`#FFA72C`} text="Send Link" onClick={onSendAppLink} />
    </CommonModal>
  </>
  )
}

export default SendAppLinkModal