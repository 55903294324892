import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const SuccessReassignModal = ({ onClose }) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
        success
        bigText={`Appointment Successfully Rescheduled`}
        smallText={`The Client will be notified of the new time
        and date`}
      >
        <Button bgColor={`#FFA72C`} text="Close" onClick={onClose} />
      </CommonModal>
    </>
  );
};

export default SuccessReassignModal;
