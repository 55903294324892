

import React from "react";
import "../../../../styles/dashboard/shop/Modal.css";
import sucImg from "../../../../assets/delete-info.svg";
import { FaTimes } from "react-icons/fa";
import Button from "../../../../component/dashboard/fragments/Button";
import { useSelector } from "react-redux";

const DeleteBlogCategoryModal = ({ children, onClose , name,  onDelete }) => {
    const { loading } = useSelector((state) => state.blog_settings);
  return (
    <div className="accept-reject-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      <figure className="img-box">
        <img src={sucImg} alt="" className="img" />
      </figure>

      <p className="big-text">
        {`Are you sure you want to delete this blog category ${name} ?`}
      </p>

      {/* {smallText && <p className="small-text">{smallText}</p>} */}
      <Button onClick={onDelete} text={loading ? "Deleting..." : `Delete blog category`} bgColor={`#DE0B0B`} />
      {children}
    </div>
  );
};

export default DeleteBlogCategoryModal;
