import React, { useEffect, useState } from "react";
import ReuseableTable from "../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../component/dashboard/tables/TableRow";
import CommonHeader from "./CommonHeader";
import Modal from "react-awesome-modal";
import ViewAppointmentModal from "./modals/ViewAppointmentModal";
import CreateAppointmentModal from "../pets/modals/CreateAppointmentModal";
import SuccessCreateAppointmentModal from "../pets/modals/SuccessCreateAppointmentModal";
import ReassignServiceProvider from "./modals/ReassignServiceProvider";
import SuccessReassignModal from "./modals/SuccessReassignModal";
import CancelAppointmentModal from "./modals/CancelAppointmentModal";
import SuccessCancelAppointmentModal from "./modals/SuccessCancelAppointmentModal";
import RescheduleAppointModal from "./modals/RescheduleAppointmentModal";
import SuccessRescheduleModal from "./modals/SuccessRescheduleModal";
import { useDispatch, useSelector } from "react-redux";
import { GetAllAppointments, GetAllAppointmentsPagination } from "../../../redux/appointment/Appointments";
import moment from "moment";

export const tableBodyList = [
  {
    pet: "Enzo - Cat ",
    parent: "Kokumo Blaqbonez",
    appointment_type: "Medical",
    date: "Jul 28 - 10:00am",
    service_provider: "Dr. Jolomi Ashafa R.",
  },
  {
    pet: "Enzo - Cat ",
    parent: "Kokumo Blaqbonez",
    appointment_type: "Medical",
    date: "Jul 28 - 10:00am",
    service_provider: "Dr. Jolomi Ashafa R.",
  },
  {
    pet: "Enzo - Cat ",
    parent: "Kokumo Blaqbonez",
    appointment_type: "Medical",
    date: "Jul 28 - 10:00am",
    service_provider: "Dr. Jolomi Ashafa R.",
  },
];

const NewAppointments = () => {
  const [singleChild, setSingleChild] = useState({});
  const [indexNo, setIndexNo] = useState("");
  const dispatch = useDispatch();
  const { appointments, loading } = useSelector((state) => state.appointments);
  const [showModal, setShowModal] = useState({
    view: false,
    create_appointment: false,
    success_create_appointment: false,
    reassign_service: false,
    success_reassign_service: false,
    cancel_appointment: false,
    success_ancel_appointment: false,
    reschedule: false,
    success_reschedule: false,
  });

  useEffect(() => {
    dispatch(GetAllAppointments());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log(appointments);
  //   //
  // }, [appointments]);
  // const [numPage, setNumPa
  const handlePagination = async (param) => {
    const linkSplit = param.split("api/");
    const link = linkSplit[1];
    // console.log(param);
    // console.log(link);
    // return
    dispatch(GetAllAppointmentsPagination(link));
  };

  const getOptionDrop = (per, total) => {
    // let endingValue = 10;
    const rangeValue = Math.ceil(Number(total) / Number(per));
    let array = [];

    for (let i = 1; i <= rangeValue; i++) {
      array.push(i);
    }

    const labelOption = array.map((chi) => {
      return { label: chi, value: chi };
    });
    // console.log(labelOption);
    return labelOption;
  };
  // eslint-disable-next-line no-unused-vars
  const [selectValue, setSelectValue] = useState("");
  return (
    <>
      {/* common header start */}
      <CommonHeader
        onCreateAppointment={() => {
          setShowModal((prev) => {
            return { ...prev, create_appointment: true };
          });
        }}
      />
      {/* common header end */}
      {/* table start */}
      <ReuseableTable
        //    loading={loading}
        sN
        one={`Pet`}
        two={`Parent`}
        three={`Appointment Type`}
        four={"Date"}
        threeDrop
        fourDrop
        five={`Service Provider`}
        fiveDrop
        action
        moreThanFive={appointments?.data?.length >= 3}
        toNo={appointments?.meta?.to}
        totalNo={appointments?.meta?.total}
        fromNo={appointments?.meta?.from}
        nextPage={appointments?.links?.next}
        prevPage={appointments?.links?.prev}
        onNext={() => {
          handlePagination(appointments?.links?.next);
        }}
        onPrev={() => {
          handlePagination(appointments?.links?.prev);
        }}
        selectValue={selectValue}
        onPageSort={(e) => {
          // setSelectValue(e);
          // handlePagination(e);
        }}
        sortOption={getOptionDrop(
          appointments?.meta?.per_page,
          appointments?.meta?.total
        )}
      >
        {appointments?.data?.map((chi, idx) => {
          const {
            // pet,
            pet_owner,
            service_provider_name,
            appointment_type,
            appointment_time,
            pets
          } = chi;
          return (
            <TableRow
              loading={loading}
              action
              setIndexNo={() => setIndexNo(idx)}
              rowNo={idx}
              indexNo={indexNo}
              key={idx}
              sN={(idx + 1) }
              // sN={(idx + 1) + ((10 * appointments?.meta?.current_page) - 10) + (appointments?.meta?.current_page !== 1 ? appointments?.meta?.current_page : 0)}
              one={pets[0]?.pet_name || "---"}
              two={pet_owner || "---"}
              three={appointment_type || "---"}
              four={moment(appointment_time).format(
                `${`MMMM`?.slice(0, 3)} DD, hh:mmA`
              )}
              five={service_provider_name || "---"}
              viewText={`View Appointment`}
              createText={`Reassign Service Provider`}
              onAdd={() => {
                setShowModal((prev) => {
                  return { ...prev, reassign_service: true };
                });
              }}
              editText={`Reschedule`}
              deleteText={`Cancel Appointment`}
              onDelete={() => {
                setShowModal((prev) => {
                  return { ...prev, cancel_appointment: true };
                });
              }}
              onEdit={() => {
                setShowModal((prev) => {
                  return { ...prev, reschedule: true };
                });
              }}
              longPopModal
              onViewDetail={() => {
                setSingleChild(chi);
                console.log(chi);
                setShowModal((prev) => {
                  return { ...prev, view: true };
                });
              }}
            />
          );
        })}
      </ReuseableTable>
      {/* table end */}
      {/* modal view start */}
      <Modal visible={showModal?.view}>
        <ViewAppointmentModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, view: false };
            });
          }}
          detail={singleChild}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.create_appointment}>
        <CreateAppointmentModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, create_appointment: false };
            });
          }}
          onCreateAppointment={() => {
            setShowModal((prev) => {
              return {
                ...prev,
                create_appointment: false,
                success_create_appointment: true,
              };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.success_create_appointment}>
        <SuccessCreateAppointmentModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, success_create_appointment: false };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.reassign_service}>
        <ReassignServiceProvider
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, reassign_service: false };
            });
          }}
          onReassign={() => {
            setShowModal((prev) => {
              return {
                ...prev,
                reassign_service: false,
                success_reassign_service: true,
              };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.success_reassign_service}>
        <SuccessReassignModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, success_reassign_service: false };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.cancel_appointment}>
        <CancelAppointmentModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, cancel_appointment: false };
            });
          }}
          onReject={() => {
            setShowModal((prev) => {
              return {
                ...prev,
                cancel_appointment: false,
                success_ancel_appointment: true,
              };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.success_ancel_appointment}>
        <SuccessCancelAppointmentModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, success_ancel_appointment: false };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.reschedule}>
        <RescheduleAppointModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, reschedule: false };
            });
          }}
          onReshedule={() => {
            setShowModal((prev) => {
              return { ...prev, reschedule: false, success_reschedule: true };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
      {/* modal view start */}
      <Modal visible={showModal?.success_reschedule}>
        <SuccessRescheduleModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, success_reschedule: false };
            });
          }}
        />
      </Modal>
      {/* modal view end */}
    </>
  );
};

export default NewAppointments;
