import React, { useEffect, useContext } from "react";
import "../../../styles/auth/AuthLayout.css";
import petMeLogo from "../../../assets/petme-new-logo.svg";
import { useLocation } from "react-router-dom";
import imgOne from "../../../assets/auth-one-img.png";
import imgTwo from "../../../assets/auth-two-img.png";
import ActionContext from "../../../context/ActionContext";
// import { useLocation } from "react-router-dom";

const AuthLayout = ({ children, title, smallText, bigText }) => {
  // const location = useLocation();
  const location = useLocation();
  const actionCtx = useContext(ActionContext);

  return (
    <div className="auth-layout-wrap">
      <div className="left-box">
        <div className="blue-circle"></div>
        <div className="yellow-circle"></div>
        <div
          className={`content-box ${
            actionCtx.authSlideShow && "content-box-slide"
          }`}
        >
          {/* logo text box start */}
          <div className="logo-text-box" data-aos="">
            <figure className="img-box">
              <img src={petMeLogo} alt="" className="img" />
            </figure>
            <p className="text">Admin</p>
          </div>
          {/* logo text box end */}
          {/* img bg box start */}
          <div
            style={{
              backgroundImage:
                location.pathname === "/login"
                  ? `url(${imgOne})`
                  : location.pathname === "/sign-up"
                  ? `url(${imgOne})`
                  : `url(${imgTwo})`,
            }}
            className="img-bg-box"
          ></div>
          {/* img bg box end */}
        </div>
      </div>
      <div className="right-box">
        {/* content-box start */}
        <div className="content-box">
          <p className="title">{title || "title"}</p>
          {bigText && <p className="big-text">{bigText}</p>}
          {smallText && <p className="small-text">{smallText}</p>}
          {location.pathname === "/sign-up" && (
            <>
              <p className="big-text">
                Welcome <span>Kendrick Lamar</span>, you have been profiled on
                this
              </p>
              <p style={{ marginTop: "-.7rem" }} className="big-text">
                admin tool as an <span>Admin Officer</span>.
              </p>
              <p className="small-text">
                Please complete your signup by securing your profile with a
                password
              </p>
            </>
          )}
          {/* children start */}
          {children}
          {/* children end */}
        </div>
        {/* content-box end */}
      </div>
    </div>
  );
};

export default AuthLayout;
