import React, { useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import dogImg from "../../../assets/dog-bag-img.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ImageSpinner from "../fragments/ImageSpinner";
import blogtdImg from "../../../assets/blog-article-img.png";

const TableRow = ({
  marginLeft,
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,
  action,
  status,
  statusColor,
  rowNo,
  indexNo,
  setIndexNo,
  pending_order,
  onViewDetail,
  onAccept,
  onReject,
  onConfirm,
  onClickTableRow,
  img_text,
  viewText,
  addText,
  onAdd,
  editText,
  onEdit,
  deleteText,
  onDelete,
  listText,
  onList,
  unListText,
  onUnList,
  loading,
  blogtdOne,
  sN,
  createText,
  dewormtext,
  onDeworm,
  vaccinateText,
  onvaccinate,
  dietText,
  onDiet,
  sendAppInviteText,
  onAppInvite,
  greenRed,
  greenRedColor,
  statusBgColor,
  longPopModal,
  deleteTextTwo,
  onDeleteTwo
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const location = useLocation();
  //   const [indexNo, setIndexNo] = useState("");
  return (
    <>
      <tr
        style={{
          position: "relative",
          zIndex: showDropDown && indexNo === rowNo ? "5" : "1",
        }}
        onClick={onClickTableRow}
      >
        {sN && (
          <td>
            {" "}
            {loading ? (
              <Skeleton width={30} height={20} />
            ) : (
              <span>{sN < 10 ? `0${sN}` : sN || "Not set"}</span>
            )}
          </td>
        )}
        {img_text && (
          <td className="img-text-td">
            {loading ? (
              <Skeleton width={120} height={20} />
            ) : (
              <div className="img-text-box">
                <figure className="img-box">
                  <img src={img_text?.img || dogImg} alt="" className="img" />
                </figure>
                <p className="text">{img_text?.des || "Not set"}</p>
              </div>
            )}
          </td>
        )}
        {blogtdOne && (
          <td className="blog-td">
            {loading ? (
              <Skeleton width={100} height={20} />
            ) : (
              <div className="img-title-box">
                <figure className="img-box">
                  <img
                    src={blogtdOne?.img || blogtdImg}
                    alt=""
                    className="img"
                  />
                </figure>
                <div className="text-box">
                  <p className={`header ${blogtdOne.header_type}`}>
                    {blogtdOne?.header}
                  </p>
                  <p className="title">{blogtdOne?.title}</p>
                </div>
              </div>
            )}
          </td>
        )}
        {one && (
          <td>
            {loading ? (
              <Skeleton width={100} height={20} />
            ) : (
              <span>{one || "Not set"}</span>
            )}
          </td>
        )}
        {two && (
          <td>
            {" "}
            {loading ? (
              <Skeleton width={100} height={20} />
            ) : (
              <span>{two || "Not set"}</span>
            )}
          </td>
        )}
        {greenRed && (
          <td style={{ color: greenRedColor }}>{greenRed || "Not set"}</td>
        )}
        {three && (
          <td>
            {loading ? (
              <Skeleton width={100} height={20} />
            ) : (
              <span>{three || "Not set"}</span>
            )}
          </td>
        )}
        {four && (
          <td>
            {loading ? (
              <Skeleton width={100} height={20} />
            ) : (
              <span>{four || "Not set"}</span>
            )}
          </td>
        )}
        {five && (
          <td>
            {" "}
            {loading ? (
              <Skeleton width={100} height={20} />
            ) : (
              <span>{five || "Not set"}</span>
            )}
          </td>
        )}
        {status && (
          <td className="status">
            {loading ? (
              <Skeleton width={100} height={20} />
            ) : (
              <div className={`status-bg status-${statusColor}`}>
                <span>
                  {status}
                </span>
              </div>
            )}
          </td>
        )}
        {six && (
          <td>
            {loading ? (
              <Skeleton width={100} height={20} />
            ) : (
              <span>{six || "Not set"}</span>
            )}
          </td>
        )}
        {seven && (
          <td>
            {loading ? (
              <Skeleton width={100} height={20} />
            ) : (
              <span>{seven}</span>
            )}
          </td>
        )}
        {eight && (
          <td>
            {loading ? (
              <Skeleton width={100} height={20} />
            ) : (
              <span>{eight}</span>
            )}
          </td>
        )}
        {nine && (
          <td>
            {loading ? (
              <Skeleton width={100} height={20} />
            ) : (
              <span>{nine}</span>
            )}
          </td>
        )}
        {ten && (
          <td>
            {loading ? (
              <Skeleton width={100} height={20} />
            ) : (
              <span>{ten}</span>
            )}
          </td>
        )}
        {eleven && (
          <td>
            {loading ? (
              <Skeleton width={100} height={20} />
            ) : (
              <span>{eleven}</span>
            )}
          </td>
        )}
        {action && (
          <td className="action">
            {loading ? (
              <Skeleton width={20} height={30} circle />
            ) : <div
              style={{ marginLeft: marginLeft && "2rem" }}
              onClick={() => {
                setIndexNo(rowNo);
                indexNo === rowNo
                  ? setShowDropDown(!showDropDown)
                  : setShowDropDown(true);
              }}
              className="drop-wrap"
            >
              <FaEllipsisV className="icon" />
              <div
                className={`drop-box ${
                  showDropDown && indexNo === rowNo && "drop-box-show"
                } ${longPopModal && "drop-box-big"}`}
              >
                {/* shop order drops start */}
                {location.pathname === "/dashboard-shop" && (
                  <>
                    <p onClick={onViewDetail} className="text">
                      View Order Details
                    </p>
                    {pending_order && (
                      <p
                        onClick={onAccept}
                        style={{ color: "#00C013" }}
                        className="text"
                      >
                        Accept Order
                      </p>
                    )}
                    {!pending_order && (
                      <p
                        onClick={onConfirm}
                        style={{ color: "#00C013" }}
                        className="text"
                      >
                        Confirm Delivery
                      </p>
                    )}
                    <p
                      onClick={onReject}
                      style={{ color: "#DE0B0B" }}
                      className="text"
                    >
                      Reject Order
                    </p>
                  </>
                )}
                {/* shop order drops end */}
                {viewText && (
                  <p
                    onClick={onViewDetail}
                    //  style={{ color: "#DE0B0B" }}
                    className="text"
                  >
                    {viewText}
                  </p>
                )}
                {createText && (
                  <p
                    onClick={onAdd}
                    //  style={{ color: "#DE0B0B" }}
                    className="text"
                  >
                    {createText}
                  </p>
                )}

                {dewormtext && (
                  <p
                    onClick={onDeworm}
                    //  style={{ color: "#DE0B0B" }}
                    className="text"
                  >
                    {dewormtext}
                  </p>
                )}
                {vaccinateText && (
                  <p
                    onClick={onvaccinate}
                    //  style={{ color: "#DE0B0B" }}
                    className="text"
                  >
                    {vaccinateText}
                  </p>
                )}
                {dietText && (
                  <p
                    onClick={onDiet}
                    //  style={{ color: "#DE0B0B" }}
                    className="text"
                  >
                    {dietText}
                  </p>
                )}
                {editText && (
                  <p
                    onClick={onEdit}
                    //  style={{ color: "#DE0B0B" }}
                    className="text"
                  >
                    {editText}
                  </p>
                )}
                {sendAppInviteText && (
                  <p
                    onClick={onAppInvite}
                    style={{ color: "#FFA72C" }}
                    className="text"
                  >
                    {sendAppInviteText}
                  </p>
                )}
                {addText && (
                  <p
                    onClick={onAdd}
                    style={{
                      color: status?.toLowerCase() === "listed" ? "#BBBBBB" : "",
                    }}
                    className="text"
                  >
                    {addText}
                  </p>
                )}
                {unListText && (
                  <p
                    onClick={onUnList}
                    style={{ color: "#FFA72C" }}
                    className="text"
                  >
                    {unListText}
                  </p>
                )}
                {listText && (
                  <p
                    onClick={onList}
                    style={{ color: "#00C013" }}
                    className="text"
                  >
                    {listText}
                  </p>
                )}
                {deleteText && (
                  <p
                    onClick={onDelete}
                    style={{ color: "#DE0B0B" }}
                    className="text"
                  >
                    {deleteText}
                  </p>
                )}
                {deleteTextTwo && (
                  <p
                    onClick={onDeleteTwo}
                    style={{ color: "#DE0B0B" }}
                    className="text"
                  >
                    {deleteTextTwo}
                  </p>
                )}
              </div>
            </div>}
          </td>
        )}
      </tr>
    </>
  );
};

export default TableRow;
