import React from "react";
import "../../../../styles/dashboard/shop/Modal.css";
import sucImg from "../../../../assets/success-order-icon.svg";
import rejImg from "../../../../assets/reject-order-icon.svg";
import acpImg from "../../../../assets/accept-order-icon.svg";

const CommonModal = ({
  bigText,
  smallText,
  children,
  accept,
  reject,
  success,
}) => {
  return (
    <div className="accept-reject-modal-wrap">
      <figure className="img-box">
        {accept && <img src={acpImg} alt="" className="img" />}
        {reject && <img src={rejImg} alt="" className="img" />}
        {success && <img src={sucImg} alt="" className="img" />}
      </figure>

      {bigText && <p className="big-text">{bigText}</p>}
      {reject && (
        <textarea
          style={{ resize: "none" }}
          className="input"
          placeholder="Give a reason for rejecting the order"
          name=""
          id=""
          cols="30"
          rows="10"
        ></textarea>
      )}
      {smallText && <p className="small-text">{smallText}</p>}

      {children}
    </div>
  );
};

export default CommonModal;
