import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const DeletePetdetailModal = ({ onClose, onDeleteSuccess }) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
        deletee
        bigText={`Are you sure you want to delete this
Pet Details?`}
      >
        <Button bgColor={`#DE0B0B`} text="Delete" onClick={onDeleteSuccess} />
      </CommonModal>
    </>
  );
};

export default DeletePetdetailModal;
