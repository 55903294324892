import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const CancelAppointmentModal = ({ onReject, onClose }) => {
  return (
    <>
      <CommonModal
        deletee
        bigText={`Kindly enter a Cancellation note to the Client`}
        onClose={onClose}
        textarea
      >
        <Button
          onClick={onReject}
          text={`Cancel Appointment`}
          bgColor={`#DE0B0B`}
        />
      </CommonModal>
    </>
  );
};

export default CancelAppointmentModal;
