import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import Button from "../../../../component/dashboard/fragments/Button";
import "../../../../styles/dashboard/appointment/ViewAppointmentModal.css";
import Select from "react-select";
import Flatpicker from "react-flatpickr";
import "flatpickr/dist/themes/confetti.css";
import moment from "moment";
import calendarIcon from "../../../../assets/calendar-icon.svg";

const RescheduleAppointModal = ({ onClose, onReshedule }) => {
  const [selectedOption, setselectedOption] = useState(null);
  const [fromDate, setFromDate] = useState("");

  const metricsDropDown = [
    { value: 1, label: "Kosoko Badmus" },
    { value: 2, label: "Diamond Kosonwo" },
    { value: 2, label: "Mikano Tigaer" },
    { value: 2, label: "Diamond Silifia" },
  ];
  return (
    <div className="reassign-service-provider-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* content box start */}
      <div className="content-box">
        <p className="content-title">Reschedule Appointment</p>
        {/* top box start */}
        <div className="top-box">
          <div className="two-group">
            {/* box start */}
            <div className="box">
              <p className="light">Appointment Time</p>
              <p className="bold">Jul 28 - 10:00am</p>
            </div>
            {/* box end */}
            <Button text={`Free`} btnStyle="btn-reuse" />
          </div>
        </div>
        {/* top box end */}
        {/* bottombox start */}
        <div className="bottom-box">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Select Appointment Time
            </label>
            <div className="group-two">
              <label htmlFor="from" className="label">
                <figure className="img-box">
                  <img src={calendarIcon} alt="" className="img" />
                </figure>
              </label>
              <Flatpicker
                id="from"
                name="from"
                data-enable-time
                //   cal
                className="input-calender"
                autoComplete="off"
                value={fromDate}
                onChange={(date) =>
                  setFromDate(moment(date[0]).format("YYYY/MM/DD"))
                }
                placeholder="DD / MM / YYYY"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* bottombox end */}
        <Button
          onClick={onReshedule}
          text={`Reschedule`}
          btnStyle={`btn-reuse`}
        />
      </div>
      {/* content box end */}
    </div>
  );
};

export default RescheduleAppointModal;
