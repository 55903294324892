import Login from "../../pages/auth/Login";
import ResetPassword from "../../pages/auth/ResetPassword";
import SetPassword from "../../pages/auth/SetPassword";
import SignUp from "../../pages/auth/SignUp";



export const Onboarding_route_group = [
    {path: "/login", component: Login},
    {path: "/sign-up", component: SignUp},
    {path: "/reset-password", component: ResetPassword},
    {path: "/set-password", component: SetPassword},
    // {path: "/login", component: Login},
]