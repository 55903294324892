
import imgTd from "../assets/blog-article-img.png"

export const blogBodyArticleList = [
  {
    heading: "Headline 1",
    title:
      "Working from Home? See this 5 engaging activities to keep your Dog Busy",
    view_count: "1,234",
    like_count: "651",
    uploaded_by: "Folusho tayo",
    img: imgTd,
  },
  {
    heading: "Headline 3",
    title:
      "Working from Home? See this 5 engaging activities to keep your Dog Busy",
    view_count: "1,234",
    like_count: "651",
    uploaded_by: "Folusho tayo",
    img: imgTd,
  },
  {
    heading: "Headline 2",
    title:
      "Working from Home? See this 5 engaging activities to keep your Dog Busy",
    view_count: "1,234",
    like_count: "651",
    uploaded_by: "Folusho tayo",
    img: imgTd,
  },
  {
    heading: "Regular",
    title:
      "Working from Home? See this 5 engaging activities to keep your Dog Busy",
    view_count: "1,234",
    like_count: "651",
    uploaded_by: "Folusho tayo",
    img: imgTd,
  },
];

export const blogHeadArticleList = ["Blogpost", "View Count", "Like Count", "Uploaded by"]
