import React from "react";
import { Dna } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../component/dashboard/fragments/Button";
import { verifyPetParent } from "../../../../../redux/pets/petTypes";
import CommonModal from "../CommonModal";

const EnablePetParentDetailModal = ({ onClose, onEnable, detail }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.pet_type);

  const HandleVerifyPetParent = async () => {
    const obj = {
      id: detail?.id,
    };
   const data = await dispatch(verifyPetParent(obj));
   if(data?.payload?.success){
    onEnable()
   }
  };

  return (
    <>
      <CommonModal
        onClose={onClose}
        enable
        // smallText={`The Pet Parent has been notified via mail`}
        bigText={`Are you sure you want to enable this
      Pet Parent?`}
      >
        {!loading ? (
          <Button
            bgColor={`#00C013`}
            text="Enable"
            onClick={HandleVerifyPetParent}
          />
        ) : (
          <div className="dna-wrapper-box" style={{ alignSelf: "center" }}>
            <Dna
              visible={true}
              height="80"
              width="100"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
      </CommonModal>
    </>
  );
};

export default EnablePetParentDetailModal;
