import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import Button from "../../../../component/dashboard/fragments/Button";
import "../../../../styles/dashboard/appointment/ViewAppointmentModal.css";
import Select from "react-select";

const ReassignServiceProvider = ({ onClose, onReassign }) => {
  const [selectedOption, setselectedOption] = useState(null);

  const metricsDropDown = [
    { value: 1, label: "Kosoko Badmus" },
    { value: 2, label: "Diamond Kosonwo" },
    { value: 2, label: "Mikano Tigaer" },
    { value: 2, label: "Diamond Silifia" },
  ];
  return (
    <div className="reassign-service-provider-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* content box start */}
      <div className="content-box">
        <p className="content-title">Reassign Service Provider</p>
        {/* top box start */}
        <div className="top-box">
          <div className="two-group">
            {/* box start */}
            <div className="box">
              <p className="light">Currently Assigned to</p>
              <p className="bold">Dr. Jolomi Ashafa R.</p>
            </div>
            {/* box end */}
            <Button text={`Free`} btnStyle="btn-reuse" />
          </div>
        </div>
        {/* top box end */}
        {/* bottombox start */}
        <div className="bottom-box">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Select Client
            </label>
            <Select
              value={selectedOption}
              onChange={(e) => setselectedOption(e)}
              options={metricsDropDown}
              // className="input-border"
            />
          </div>
          {/* form group end */}
        </div>
        {/* bottombox end */}
        <Button onClick={onReassign} text={`Reassign`} btnStyle={`btn-reuse`} />
      </div>
      {/* content box end */}
    </div>
  );
};

export default ReassignServiceProvider;
