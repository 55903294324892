import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const SuccessResetPassword = ({ onClose }) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
        success
        bigText={`Password Reset Successful`}
        smallText={
          "A reset email has been set to the profile`s registered email address"
        }
      >
        <Button bgColor={`#FFA72C`} text="Close" onClick={onClose} />
      </CommonModal>
    </>
  );
};

export default SuccessResetPassword;
