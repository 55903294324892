import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import "../../../../styles/dashboard/shop/Modal.css";
import dogImg from "../../../../assets/dog-bag-img.png";
import { Rating } from "react-simple-star-rating";
import Button from "../../../../component/dashboard/fragments/Button";
import Moment from "react-moment";
import { formatNumWithCommaNaira } from "../../../../helpers/Helper";

const ViewShopItemModal = ({ onClose, detail }) => {
  const [rating, setRating] = useState(0); //

  const handleRating = (rate) => {
    setRating(rate);
    // other logic
  };

  return (
    <div style={{ width: "65rem" }} className="view-shop-item-modal">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>

      {/* left box start */}
      <div className="left-box">
        <div style={{gridTemplateColumns: `repeat(${detail?.images?.length}, 1fr)`}} className="img-scroll-wrap">
          {
            detail?.images?.length > 0 && detail?.images?.map((chi, idx) => {
              return (
                <figure key={idx} className="img-box">
                <img
                  src={
                    detail?.images?.length > 0
                      ? chi?.image
                      : ""
                  }
                  alt=""
                  className="img"
                />
              </figure>
              )
            })
          }
        </div>
      
        {/* detail box start */}
        <div className="detail-box">
          <div className="box">
            <p className="key">Created by </p>
            <p className="value">{detail?.owner || "Not set"}</p>
          </div>
          <div className="box">
            <p className="key">Last Edited on </p>
            <p className="value">
              <Moment format="MMMM , h:mm a">{detail?.updated_at}</Moment>
            </p>
          </div>
        </div>
        {/* detail box end */}
      </div>
      {/* left box end */}
      {/* right box start */}
      <div className="right-box">
        <p
          className={`btn ${detail?.status !== "published" && "status-pending"}`}
        >
          {detail?.status !== "published" ? "Unlisted" : detail?.status}
        </p>
        <p className="title">{`${detail?.name}`}</p>
        <div className="rate-box">
          <Rating
            size={17}
            allowHalfIcon
            fillColor="#FFA72C"
            onClick={handleRating}
            ratingValue={rating}
            allowHover={false}
          />
          <span className="number">(32)</span>
        </div>
        {/* detail box start */}
        <div className="detail-box">
          <div className="box">
            <p className="key">Price</p>
            <p className="value">
              {detail ? formatNumWithCommaNaira(String(detail?.cost)) : ""}
            </p>
          </div>
          <div className="box">
            <p className="key">Available Stock</p>
            <p className="value">{`${detail?.quantity}`}</p>
          </div>
        </div>
        {/* detail box end */}
        <p className="description">Description</p>
        <p className="text">{detail?.description}</p>
        <Button onClick={onClose} text="Close" bgColor={`#FFA72C`} />
      </div>
      {/* right box end */}
    </div>
  );
};

export default ViewShopItemModal;
