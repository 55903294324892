import React from "react";
import { FaTimes } from "react-icons/fa";
import "../../../../styles/dashboard/pet/modal/DewormVaccinationDietModal.css";
import noContentImg from "../../../../assets/deworm-content-img.png";
import Button from "../../../../component/dashboard/fragments/Button";

const ViewPaymentRequestModal = ({ onClose }) => {
  const list = ["", ""];
  return (
    <div className="deworm-vaccination-diet-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* end oc cancel box */}
      {/* modal title start */}
      <p className="modal-title">Payment Request Details</p>
      {/* modal title end */}
      {/* content box start */}
      <div className="content-box">
        <div className="top-box">
          {/* box start */}
          <div className="box">
            <p className="light">Name</p>
            <p className="bold">Kuramo Vets & Doctors</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Requested Amount</p>
            <p className="bold">N213,829</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Phone Number</p>
            <p className="bold">+2348156666666</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Email</p>
            <p className="bold">Chuks@kukuhealth.com</p>
          </div>
          {/* box end */}
           {/* box start */}
           <div className="box">
            <p className="light">PBank Account Name</p>
            <p className="bold">Kuramo Vets & Doctors</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            {/* <p className="light">Email</p>
            <p className="bold">Chuks@kukuhealth.com</p> */}
          </div>
          {/* box end */}
           {/* box start */}
           <div className="box">
            <p className="light">Account No</p>
            <p className="bold">0123456789</p>
          </div>
          {/* box end */}
           {/* box start */}
           <div className="box">
            <p className="light">Bank Name</p>
            <p className="bold">Wema Bank</p>
          </div>
          {/* box end */}
        </div>
        {/* end of top box */}
        {/* bottom box start */}
        <div style={{gridTemplateColumns: '1fr 1fr'}} className="bottom-box">
          {/* box start */}
          <div className="box">
            <p className="light">Date Requested</p>
            <p className="bold">July 21 2022 - 10:00am</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Transaction ID</p>
            <p className="bold">#123456789</p>
          </div>
          {/* box end */}
        </div>
        {/* bottom box end */}
      </div>
      {/* content box end */}

      {/* close button start */}
      <Button
        onClick={onClose}
        text={`Close`}
        bgColor="#84BFCD"
        btnStyle={`btn-reuse`}
      />
      {/* close btn end */}
    </div>
  );
};

export default ViewPaymentRequestModal;
