import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const DeactivateModal = ({ onClose, onDeactivate }) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
        deactivate
        //   smallText={`The Pet Parent has been notified via mail`}
        bigText={"Are you sure you want to deactivate this user?"}
      >
        <Button bgColor={`#DE0B0B`} text="Deactivate" onClick={onDeactivate} />
      </CommonModal>
    </>
  );
};

export default DeactivateModal;
