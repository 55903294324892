import React, { useState } from "react";
import CommonHeader from "./CommonHeader";
import "../../../styles/dashboard/payment-request/PaymentRequest.css";
import Flatpicker from "react-flatpickr";
import "flatpickr/dist/themes/confetti.css";
import Modal from "react-awesome-modal";
import moment from "moment";
import calendarIcon from "../../../assets/calendar-icon.svg";
import Button from "../../../component/dashboard/fragments/Button";
import TableRow from "../../../component/dashboard/tables/TableRow";
import ReuseableTable from "../../../component/dashboard/tables/ReuseableTable";
import SuccessSettleTransactionModal from "./modal/SuccessSettleTransactionModal";
import SettleTransactionModal from "./modal/SettleTransactionModal";
import ViewPaymentRequestModal from "./modal/ViewPaymentRequestModal";

const list = [
  {
    name: "Kuramo Vets & Doctors",
    req_amount: "N243,091",
    time: "Jul 28 2022 07:09pm",
    status: "Yet to Download",
  },
  {
    name: "Kuramo Vets & Doctors",
    req_amount: "N243,091",
    time: "Jul 28 2022 07:09pm",
    status: "Yet to Download",
  },
  {
    name: "Kuramo Vets & Doctors",
    req_amount: "N243,091",
    time: "Jul 28 2022 07:09pm",
    status: "Download",
  },
  {
    name: "Kuramo Vets & Doctors",
    req_amount: "N243,091",
    time: "Jul 28 2022 07:09pm",
    status: "Download",
  },
  {
    name: "Kuramo Vets & Doctors",
    req_amount: "N243,091",
    time: "Jul 28 2022 07:09pm",
    status: "Yet to Download",
  },
];

const PaymentRequestHistory = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [showHistoryTable, setShowHistoryTable] = useState(false);
  const [indexNo, setIndexNo] = useState("");
  const [showModal, setShowModal] = useState({
    settle: false,
    settle_success: false,
    view: false,
  });

  return (
    <>
      <div className="payment-request-history-wrap">
        <CommonHeader />
        {!showHistoryTable && (
          <p className="subtitle">
            Select a Date range to fetch transaction records for
          </p>
        )}

        {/* select history box start */}
        {/* calendar box start */}
        {!showHistoryTable && (
          <div className="calender-box">
            <p className="text">from</p>
            <div className="form-group">
              <label htmlFor="from" className="label">
                <figure className="img-box">
                  <img src={calendarIcon} alt="" className="img" />
                </figure>
              </label>
              <Flatpicker
                id="from"
                name="from"
                data-enable-time
                //   cal
                className="input-calender"
                autoComplete="off"
                value={fromDate}
                onChange={(date) =>
                  setFromDate(moment(date[0]).format("YYYY/MM/DD"))
                }
                placeholder="DD / MM / YYYY"
              />
            </div>
            <p style={{ marginLeft: "2rem" }} className="text">
              to
            </p>
            <div className="form-group">
              <label htmlFor="to" className="label">
                <figure className="img-box">
                  <img src={calendarIcon} alt="" className="img" />
                </figure>
              </label>
              <Flatpicker
                data-enable-time
                id="to"
                name="to"
                //   cal
                className="input-calender"
                autoComplete="off"
                value={toDate}
                onChange={(date) =>
                  settoDate(moment(date[0]).format("YYYY/MM/DD"))
                }
                placeholder="DD / MM / YYYY"
              />
            </div>
          </div>
        )}
        {/* btn start */}
        {!showHistoryTable && (
          <Button
            btnStyle={`btn-reuse`}
            text="Fetch Records"
            bgColor={`#84BFCD`}
            onClick={() => setShowHistoryTable(true)}
          />
        )}
        {/* btn end */}
        {/* table start */}
        {showHistoryTable && (
          <>
            {/* reset text box start */}
            <div className="reset-text-box">
              <p className="text">
                Displaying <span> 4 </span> results from <span>23/11/2021</span>{" "}
                to <span>01/01/2022</span>
              </p>
              <p onClick={() => setShowHistoryTable(false)} className="reset">
                Reset
              </p>
            </div>
            {/* reset text box end */}

            <ReuseableTable
              //   loading={loading}
              sN
              one={"Name"}
              two={"Req Amount"}
              three={`Time`}
              five={"Status"}
              oneDrop
              twoDrop
              threeDrop
              fiveDrop
              action
              moreThanFive={list?.length >= 3}
              //   tableTwo
            >
              {list.map((chi, idx) => {
                const { name, req_amount, time, status } = chi;
                return (
                  <TableRow
                    sN={idx + 1}
                    setIndexNo={() => setIndexNo(idx)}
                    rowNo={idx}
                    indexNo={indexNo}
                    key={idx}
                    action
                    one={name}
                    two={req_amount}
                    three={time}
                    status={status}
                    statusColor={
                      status === "Yet to Download" ? "black" : "yellow"
                    }
                    viewText={`View Profile`}
                    onViewDetail={() =>
                      setShowModal((prev) => {
                        return { ...prev, view: true };
                      })
                    }
                    onList={() =>
                      setShowModal((prev) => {
                        return { ...prev, settle: true };
                      })
                    }
                    addText={`Download`}
                    listText="Settle Transaction"
                  />
                );
              })}
            </ReuseableTable>
          </>
        )}
        {/*  */}
      </div>
      {/* settle modal start */}
      <Modal effect="fadeInUp" visible={showModal.settle}>
        <SettleTransactionModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, settle: false };
            })
          }
          onSettle={() =>
            setShowModal((prev) => {
              return { ...prev, settle: false, settle_success: true };
            })
          }
        />
      </Modal>
      {/* settle modal end */}
      {/* settle success modal start */}
      <Modal effect="fadeInUp" visible={showModal.settle_success}>
        <SuccessSettleTransactionModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, settle_success: false };
            })
          }
        />
      </Modal>
      {/* settle success modal end */}
      {/* view request modal start */}
      <Modal effect="fadeInUp" visible={showModal.view}>
        <ViewPaymentRequestModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, view: false };
            })
          }
        />
      </Modal>
      {/* view request modal end */}
    </>
  );
};

export default PaymentRequestHistory;
