import React from "react";
import "../../../../styles/dashboard/shop/Modal.css";
import sucImg from "../../../../assets/success-order-icon.svg";
import sucFootImg from "../../../../assets/pet-foot-icon.svg";
import rejImg from "../../../../assets/delete-pet-img.svg";
import acpImg from "../../../../assets/success-blog-img.svg";
import updImg from "../../../../assets/update-foot.svg";
import enableImg from "../../../../assets/enable-foot.svg";
import disableImg from "../../../../assets/disable-foot.svg";
import resetImg from "../../../../assets/provider-reset-pass-icon.svg";
import deactivateImg from "../../../../assets/provider-deactivate-icon.svg";
import activateImg from "../../../../assets/provider-reactivate-icon.svg";
import successGreenImg from "../../../../assets/success-green-icon.svg";
import successRedImg from "../../../../assets/success-red-icon.svg";
import openTicketImg from "../../../../assets/support-ticket-reopen-icon.svg";
import closeTicketImg from "../../../../assets/support-close-ticket-icon.svg";
import { FaTimes } from "react-icons/fa";

const CommonModal = ({
  bigText,
  smallText,
  children,
  accept,
  deletee,
  success,
  textarea,
  onClose,
  success_foot,
  updateFoot,
  disable,
  enable,
  activate,
  deactivate,
  resetPas,
  successRed,
  successGreen,
  openTicket,
  closeTicket
}) => {
  return (
    <div className="accept-reject-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      <figure className="img-box">
        {accept && <img src={acpImg} alt="" className="img" />}
        {deletee && <img src={rejImg} alt="" className="img" />}
        {success && <img src={sucImg} alt="" className="img" />}
        {successGreen && <img src={successGreenImg} alt="" className="img" />}
        {successRed && <img src={successRedImg} alt="" className="img" />}
        {activate && <img src={activateImg} alt="" className="img" />}
        {deactivate && <img src={deactivateImg} alt="" className="img" />}
        {resetPas && <img src={resetImg} alt="" className="img" />}
        {success_foot && <img src={sucFootImg} alt="" className="img" />}
        {updateFoot && <img src={updImg} alt="" className="img" />}
        {disable && <img src={disableImg} alt="" className="img" />}
        {enable && <img src={enableImg} alt="" className="img" />}
        {openTicket && <img src={openTicketImg} alt="" className="img" />}
        {closeTicket && <img src={closeTicketImg} alt="" className="img" />}
      </figure>

      {bigText && <p className="big-text">{bigText}</p>}
      {textarea && (
        <textarea
          style={{ resize: "none" }}
          className="input"
          placeholder="Give a reason for rejecting the order"
          name=""
          id=""
          cols="30"
          rows="10"
        ></textarea>
      )}
      {smallText && <p className="small-text">{smallText}</p>}

      {children}
    </div>
  );
};

export default CommonModal;
