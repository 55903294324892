import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const RejectOrderModal = ({ children, onReject }) => {
  return (
    <>
      <CommonModal
        reject
        bigText={`Kindly enter a Rejection note to the Client`}
      >
        {children}
        <Button onClick={onReject} text={`Reject Order`} bgColor={`#DE0B0B`} />
      </CommonModal>
    </>
  );
};

export default RejectOrderModal;
