import React, { useState } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import "../../../../styles/dashboard/service-provider/AddNewServiceProvider.css";
import Select from "react-select";
import Button from "../../../../component/dashboard/fragments/Button";
import { toast } from "react-toastify";
import ItemInList from "../../blog/ItemInList";
// import Button from "../../../../../component/dashboard/fragments/Button";

const AddNewServiceProviderModal = ({ onClose, onCreate }) => {
    const [metricsList, setMetricsList] = useState([]);
  const [selectedOption, setselectedOption] = useState(null);
  const [reloadData, setReloadData] = useState(false);
  const [showMetricsAdd, setShowMetricsAdd] = useState(false);
  const [metricsActiveInput, setMetricsActiveInput] = useState(false);
  const [productCategoryActiveInput, setproductCategoryActiveInput] =
    useState(false);
  const [showModal, setShowModal] = useState({
    metric: false,
    category: false,
    detail: {},
  });


  const metricsDropDown = [
    { value: 1, label: "test one" },
    { value: 2, label: "test two" },
  ];

  const handleAddToMetricsList = async (e) => {
    const obj = {
      name: e.target.value,
    };
    // if (e.key === "Enter" && e.target.value) {
    //   if (
    //     !shop_metrics.some(
    //       (vendor) =>
    //         vendor["name"].toLowerCase() === e.target.value.toLowerCase()
    //     )
    //   ) {
    //     const data = await dispatch(createShopMetric(obj));
    //     if (data?.payload?.success) {
    //       setReloadData(!reloadData);
    //       setMetricsActiveInput(false);
    //     }
    //   } else {
    //     toast.info(" Shop metrics already exist");
    //   }
    // }
  };

  const handleRemoveMetrics = async () => {
    // console.log(showModal.detail.id);
    const payload = {
      id: showModal.detail.id,
    };
    // const data = await dispatch(deleteShopMetric(payload));
    // if (data?.payload?.success) {
    //   toast.success(
    //     `Shop metric ${showModal.detail.name}  deleted successfully`,
    //     {
    //       theme: "colored",
    //     }
    //   );
    //   setShowModal((prev) => {
    //     return { ...prev, metric: false, detail: {} };
    //   });
    //   setReloadData(!reloadData);
    // }
  };

  return (
    <div className="add-new-service-provider-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* end of cancel */}
      <p className="title">Add a New Service Provider</p>
      {/* form start */}
      <form action="" className="form">
        {/* two boxes start */}
        <div className="two-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Enter Service Provider Name
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="text"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* two boxes end */}
        {/* three boxes start */}
        <div className="three-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Email address
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="email"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Phone Number
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="email"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* three boxes end */}
        {/* three boxes start */}
        <div className="three-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Select Role
            </label>
            <Select
              value={selectedOption}
              onChange={(e) => setselectedOption(e)}
              options={metricsDropDown}
              className="input-border-select"
            />
          </div>
          {/* form group end */}
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Upload Applicable KYC Doc
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="email"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* three boxes end */}

        {/* metrics box start */}
        <div className="metrics-box">
          <p className="title">Select Applicable Services</p>
          <div
            className="detail-box"
            onClick={() => {
              metricsActiveInput && setMetricsActiveInput(false);
              setShowMetricsAdd(true);
            }}
          >
            {metricsList?.length < 1 &&
              !metricsActiveInput &&
              !showMetricsAdd && (
                <p className="info">
                  Enter metrics that can be applied to shop items
                </p>
              )}
            {metricsList?.map((chi, idx) => {
              const { name, id } = chi;
              return (
                <ItemInList
                //   loading={loading}
                  onRemove={() => {
                    setShowModal((prev) => {
                      return { ...prev, metric: true, detail: chi };
                    });
                  }}
                  key={id || idx}
                  text={name}
                />
              );
            })}
            {/* add box button start */}
            <div
              className={`input-btn-box ${
                metricsActiveInput && "input-btn-box-active"
              }`}
            >
              {metricsActiveInput && (
                <input
                  onKeyPress={handleAddToMetricsList}
                  autoFocus={metricsActiveInput}
                  type="text"
                  className="metrics-input"
                />
              )}
              {!metricsActiveInput && showMetricsAdd && (
                <FaPlus
                  onClick={() => setMetricsActiveInput(true)}
                  className="icon"
                />
              )}
            </div>
            {/* add box button end */}
          </div>
          {/* detail box end */}
        </div>
        {/* metrics box end */}

        {/* button start */}
        <Button
          text={`Add Service Provider`}  
          btnStyle={`btn-reuse`}
          bgColor="#84BFCD"
          onClick={onCreate}
        />
        {/* button end */}
      </form>
      {/* form end */}
    </div>
  );
};

export default AddNewServiceProviderModal;
