import React from "react";
import { FaTimes } from "react-icons/fa";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const SuccessDiscounModal = ({ children, onClose }) => {
  return (
    <>
      <CommonModal
        success
        bigText={`Discount Rule has been Successfully applied
        to the Shop Item`}
      >
        <div onClick={onClose} className="close-icon">
          <FaTimes className="icon" />
        </div>
        <Button onClick={onClose} text={`Close`} bgColor={`#FFA72C`} />
      </CommonModal>
    </>
  );
};

export default SuccessDiscounModal;
