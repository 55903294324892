import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import "../../../styles/dashboard/blog/DashboardBlog.css";
import downImg from "../../../assets/angle-down.svg";
import Select from "react-select";
import cameraIcon from "../../../assets/camera-upload-label.svg";
import { Audio, Dna, ProgressBar, ThreeDots } from "react-loader-spinner";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import Button from "../../../component/dashboard/fragments/Button";
import Modal from "react-awesome-modal";
import CreateSuccessModal from "./modals/CreateSuccessModal";
import { useDispatch, useSelector } from "react-redux";
import { getBlogCategories } from "../../../redux/blog/BlogCategories";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  createBlogPost,
  getBlogPost,
  updateBlogPost,
} from "../../../redux/blog/BlogPosts";
import { reactSelectStyle } from "../../../helpers/Helper";

const UpdateBlogPost = () => {
  const { categoriesDropDown } = useSelector((state) => state.blog_settings);
  const { loading } = useSelector((state) => state.blog_posts);
  const [showModal, setShowModal] = useState({
    delete: false,
    create: false,
    success_delete: false,
  });
  const [singleChild, setSingleChild] = useState({});
  const [details, setDetais] = useState({
    title: "",
    author: "",
    description: "",
    blog_category_id: "",
    photo: "",
    status: "",
  });
  const [convertedText, setConvertedText] = useState("");
  const [selectedOption, setselectedOption] = useState(null);
  const [selectedCategoryOption, setselectedCategoryOption] = useState(null);
  // const [editorState, setEditorState] = useState(() =>
  //   EditorState.createEmpty()
  // );
  const dispatch = useDispatch();
  const [refreshTable, setRefreshTable] = useState(false);
  const history = useHistory();
  const headlineDropdown = [
    { value: "1", label: "Headline 1" },
    { value: "2", label: "Headline 2" },
    { value: "3", label: "Headline 3" },
    { value: "regular", label: "Regular" },
  ];

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetais(data);
    const dataTwo = { ...singleChild, [name]: value };
    setSingleChild(dataTwo);
  };

  // const handleFinish = () => {
  //   setDetais({
  //     title: "",
  //     author: "",
  //     description: "",
  //     blog_category_id: "",
  //     photo: "",
  //     status: "",
  //   });
  //   setConvertedText("");
  //   setselectedCategoryOption(null);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      ...singleChild,
      category: singleChild?.catgory?.value
    };
    // console.log(obj);
    // return;
    const formData = new FormData();
    formData.append("status", obj.status);
    formData.append("photo", obj.photo);
    formData.append("title", obj.title);
    formData.append("author", obj.author);
    formData.append("description", obj.description);
    formData.append("id", singleChild?.id);
    formData.append("blog_category_id", obj.blog_category_id);
    console.log( singleChild?.id);

    const data = await dispatch(updateBlogPost(formData));
    // console.log(data);
    if(data?.payload?.success){
      setRefreshTable(!refreshTable)
      setDetais({
        title: "",
        author: "",
        description: "",
        blog_category_id: "",
        photo: "",
        status: "",
      })
    }
  };

  useEffect(() => {
    dispatch(getBlogCategories());
    getBlogDetail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  const getBlogDetail = async () => {
    const id = localStorage.getItem("blog-post-id");
    const data = await dispatch(getBlogPost(id));
    if (data?.payload?.success) {
      // console.log(data?.payload.post);
      const post = data?.payload.post;
      const obj = {
        ...post,
        category: {
          ...post?.category,
          label: post?.category?.name,
          value: post?.category?.id,
        },
      };
      setSingleChild(obj);
    }
  };

  return (
    <>
      <div className="create-blog-post-wrap">
        {/* ctitle back box start */}
        <div className="title-back-box">
          <FaAngleLeft className="icon" />
          <p onClick={() => history.goBack()} className="text">
            Blog /{" "}
          </p>{" "}
          <p className="text text-active"> Create Blog Post</p>
        </div>
        {/* ctitle back box end */}
        {/* detail box start */}
        <div className="blog-detail-box">
          <p className="text">Blog Details</p>
          <span></span>
        </div>
        {/* detail box end */}
        {/* form start */}
        <form onSubmit={handleSubmit} action="" className="form">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="" className="form-label">
              Article Title
            </label>
            <div className="input-group">
              <input
                name="title"
                onChange={handleChange}
                type="text"
                className="form-input"
                value={singleChild?.title}
              />
            </div>
          </div>
          {/* form group end */}
          {/* two group start */}
          <div className="two-groups">
            {/* form group start */}
            <div className="form-group">
              <label htmlFor="" className="form-label">
                Attach Article Image
              </label>
              <div className="input-group">
                <label className="img-article" htmlFor="img-article">
                  <figure className="img-box">
                    <img src={cameraIcon} alt="" className="img" />
                  </figure>
                </label>
                <input
                  onChange={(e) =>
                    setDetais((prev) => {
                      return { ...prev, photo: e.target.files[0] };
                    })
                  }
                  id="img-article"
                  type="file"
                  className="form-input"
                />
              </div>
            </div>
            {/* form group end */}
            {/* form group start */}
            <div className="form-group">
              <label htmlFor="" className="form-label">
                Headline Position
              </label>
              <div className="input-group">
                <Select
                  styles={reactSelectStyle}
                  id="headline"
                  name="headline"
                  value={selectedOption}
                  onChange={(e) => setselectedOption(e)}
                  options={headlineDropdown}
                  className="input-border"
                />
                <label htmlFor="headline" className="img-wrap">
                  <figure className="img-box">
                    <img src={downImg} alt="" className="img" />
                  </figure>
                </label>
              </div>
            </div>
            {/* form group end */}
            {/* form group start */}
            <div className="form-group">
              <label htmlFor="" className="form-label">
                Article Category
              </label>
              <div className="input-group">
                <Select
                  styles={reactSelectStyle}
                  id="headline"
                  name="headline"
                  value={singleChild?.category}
                  onChange={(e) => {
                    setselectedCategoryOption(e);
                    setSingleChild((prev) => {
                      return {...prev, category: e}
                    })
                  }}
                  options={categoriesDropDown}
                  className="input-border"
                />
                <label htmlFor="headline" className="img-wrap">
                  <figure className="img-box">
                    <img src={downImg} alt="" className="img" />
                  </figure>
                </label>
              </div>
            </div>
            {/* form group end */}
            {/* form group start */}
            <div className="form-group">
              <label htmlFor="" className="form-label">
                Author name
              </label>
              <div className="input-group">
                <input
                  name="author"
                  onChange={handleChange}
                  type="text"
                  className="form-input"
                  value={singleChild?.author}
                />
              </div>
            </div>
            {/* form group end */}
          </div>
          {/* two group end */}

          {/* form group start */}
          <div style={{ marginTop: "2rem" }} className="form-group">
            <label htmlFor="" className="form-label">
              Distinguishing Marks (Optional)
            </label>
            {/* <div style={{ minHeight: "35rem" }} className="editor-wrap"> */}
            {/* <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  // onEditorStateChange={this.onEditorStateChange}
                /> */}
            <ReactQuill
              formats={formats}
              theme="snow"
              value={singleChild?.description}
              onChange={(e) => {
                setSingleChild((prev) => {
                  return { ...prev, description: e };
                });
                setConvertedText(e);
              }}
              className="quill-editor"
              // style={{height: "25rem", width: "60rem", borderRadius: "1rem" }}
            />
            {/* </div> */}
          </div>
          {/* form group end */}
          {!loading ? (
            <input
              disabled={
                !details?.author &&
                !details?.photo &&
                !details?.title &&
                !selectedCategoryOption &&
                !convertedText
              }
              onClick={handleSubmit}
              className={`form-submit btn ${
                details.title ||
                details.photo ||
                details?.author ||
                selectedCategoryOption ||
                convertedText
                  ? "form-submit-active"
                  : ""
              }`}
              type="submit"
              value="Update Article"
            />
          ) : (
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          )}
        </form>
        {/* form end */}
      </div>
      {/* modal for success create start */}
      <Modal visible={showModal.create} effect="fadeInUp">
        <CreateSuccessModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, create: false };
            })
          }
        />
      </Modal>
      {/* modal for success create end */}
    </>
  );
};

export default UpdateBlogPost;
