import React from "react";
import "../../../../../styles/dashboard/service-provider/ServiceProviderProfileModal.css";
import docOne from "../../../../../assets/doc-img-one.png";
import docTwo from "../../../../../assets/doc-img-two.png";
import Button from "../../../../../component/dashboard/fragments/Button";
import { useState } from "react";
import Modal from "react-awesome-modal";
// import AcceptOrderModal from "../../../shop/modals/AcceptOrderModal";
import SuccessRejectModal from "./SuccessRejectModal";
import SuccessAcceptModal from "./SuccessAcceptModal";

const docList = [
  {
    name: "CAC Registeration Doc",
    status: "Approved",
    date: "Jul 28 2020",
    app_rej_name: "Kashamu Wizomo",
    img: docOne,
  },
  {
    name: "CAC Registeration Doc",
    status: "Pending",
    date: "Jul 28 2020",
    app_rej_name: "Kashamu Wizomo",
    img: docOne,
  },
  {
    name: "CAC Registeration Doc",
    status: "Rejected",
    date: "Jul 28 2020",
    app_rej_name: "Kashamu Wizomo",
    img: docTwo,
  },
  {
    name: "CAC Registeration Doc",
    status: "Pending",
    date: "Jul 28 2020",
    app_rej_name: "Kashamu Wizomo",
    img: docOne,
  },
];

const Documents = () => {
  const [showModal, setShowModal] = useState({
    accept: false,
    reject: false,
  });
  return (
    <>
      <div className="service-provider-document-wrap-box">
        {/* documents box start */}
        <div className="documents-box">
          {docList.map((chi, idx) => {
            const { name, status, date, app_rej_name, img } = chi;
            return (
              <div key={idx} className="child-wrap">
                <p className="name">{name}</p>
                <div className="bottom-box">
                  <div
                    style={{ backgroundImage: `url(${img})` }}
                    className="img-wrap"
                  ></div>
                  <div className="right-box">
                    <div className="box">
                      <p className="light">Status</p>
                      <p
                        className={`bold ${
                          status === "Approved"
                            ? "green"
                            : status === "Rejected"
                            ? "red"
                            : "yellow"
                        }`}
                      >
                        {status === "Pending" ? "Pending Approval" : status}
                      </p>
                    </div>
                    <div className="box">
                      <p className="light">Uploaded on</p>
                      <p className="bold">{date}</p>
                    </div>
                    {status !== "Pending" && (
                      <div className="box">
                        <p className="light">
                          {status === "Approved"
                            ? "Approved by"
                            : "Rejected by"}
                        </p>
                        <p className="bold">{app_rej_name}</p>
                      </div>
                    )}
                    {status !== "Approved" && (
                      <div className="btn-box">
                        {status === "Rejected" && (
                          <Button
                            btnStyle={`btn-one`}
                            bgColor={`#00C013`}
                            text={`Approve`}
                            onClick={() =>
                              setShowModal((prev) => {
                                return { ...prev, accept: true };
                              })
                            }
                          />
                        )}
                        {status === "Pending" && (
                          <Button
                            onClick={() =>
                              setShowModal((prev) => {
                                return { ...prev, accept: true };
                              })
                            }
                            btnStyle={`btn-one`}
                            bgColor={`#00C013`}
                            text={`Approve`}
                          />
                        )}
                        {status === "Pending" && (
                          <Button
                            btnStyle={`btn-two`}
                            bgColor={`#DE0B0B`}
                            text={`Reject`}
                            onClick={() =>
                                setShowModal((prev) => {
                                  return { ...prev, reject: true };
                                })
                              }
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* documents box end */}
      </div>
      {/* accep modal start */}
      <Modal effect="fadeInUp" visible={showModal.accept}>
        <SuccessAcceptModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, accept: false };
            })
          }
        />
      </Modal>
      {/* accept modal end */}
        {/* reject modal start */}
        <Modal effect="fadeInUp" visible={showModal.reject}>
        <SuccessRejectModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, reject: false };
            })
          }
        />
      </Modal>
      {/* reject  modal end */}
    </>
  );
};

export default Documents;
