import React from 'react'
import Button from '../../../../component/dashboard/fragments/Button'
import CommonModal from './CommonModal'

const CreatePetDetailModal = ({onClose, onCreate}) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
        success_foot
        // smallText={`The Pet Parent has been notified via mail`}
        bigText={`Are you sure you want to create this
        Pet Details?`}
      >
        <Button bgColor={`#FFA72C`} text="Create Pet" onClick={onCreate} />
      </CommonModal>
    </>
  )
}

export default CreatePetDetailModal