import ShopItems from "../../../pages/dashboard/shop/ShopItems";
import ShopOrder from "../../../pages/dashboard/shop/ShopOrder";
import ShopSettings from "../../../pages/dashboard/shop/ShopSettings";

export const Shop_group_routes = [
  {
    path: "/dashboard-shop-items",
    component: ShopItems,
    title: "shop",
    smallTitle: "Review and Manage all sales and shop listing on Petme",
  },
  {
    path: "/dashboard-shop",
    component: ShopOrder,
    title: "shop",
    smallTitle: "Review and Manage all sales and shop listing on Petme",
  },
  {
    path: "/dashboard-shop-settings",
    component: ShopSettings,
    title: "shop",
    smallTitle: "Review and Manage all sales and shop listing on Petme",
  },
];
