import React, { useState } from "react";
import AuthLayout from "../../component/auth/layout";
import { Link, useHistory } from "react-router-dom";
import "../../styles/auth/AuthForm.css";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import ProgressBar from "../../component/utils/ProgressBar";
import Modal from "react-awesome-modal";
import SuccessModal from "./SuccessModal";

const SignUp = () => {
  //   const history = useHistory();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [details, setDetails] = useState({
    new_password: "",
    confirm_new_password: "",
  });
  const [validate, setValidate] = useState({
    hasLow: 0,
    hasCap: 0,
    hasNumber: 0,
    has8digit: 0,
  });
  const [showPassword, setShwoPassord] = useState(false);
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const validatePassword = (password) => {
    if (password.match(/\d+/g)) {
      setValidate((o) => ({ ...o, hasNumber: 25 }));
    } else {
      setValidate((o) => ({ ...o, hasNumber: 0 }));
    }

    if (password.match(/[A-Z]+/g)) {
      setValidate((o) => ({ ...o, hasCap: 25 }));
    } else {
      setValidate((o) => ({ ...o, hasCap: 0 }));
    }

    if (password.match(/[a-z]+/g)) {
      setValidate((o) => ({ ...o, hasLow: 25 }));
    } else {
      setValidate((o) => ({ ...o, hasLow: 0 }));
    }

    if (password.length > 7) {
      setValidate((o) => ({ ...o, has8digit: 25 }));
    } else {
      setValidate((o) => ({ ...o, has8digit: 0 }));
    }
  };

  return (
    <>
      <AuthLayout
        title="Sign Up"
        // bigText={`Welcome Kendrick Lamar, you have been profiled on this
        // admin tool as an Admin Officer`}
        // smallText={`Please complete your signup by securing  your profile with a password`}
      >
        <div
          // onClick={() => history.push("/sign-up")}
          className="auth-form-wrap"
        >
          {/* form start */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowModal(true)
            }}
            action=""
            className="form"
          >
            {/* form group start */}
            <div className="form-group">
              <input
                type={showPassword ? "text" : "Password"}
                name="new_password"
                id="Password"
                className="form-input white-black-color"
                placeholder="Create your password"
                onChange={(e) => {
                  validatePassword(e.target.value);
                  handleChange(e);
                }}
                value={details.new_password}
              />
              <p
                className={`placeholder  white-black-bg white-black-color ${
                  details.new_password && "placeholder-active"
                }`}
              >
                Password
              </p>
              <p
                onClick={() => setShwoPassord(!showPassword)}
                className="show-hide"
              >
                {!showPassword ? (
                  <FaEyeSlash className="icon" />
                ) : (
                  <FaEye className="icon" />
                )}
              </p>
            </div>
            {/* form group end */}
            {/* form group start */}
            <div className="form-group">
              <input
                type={showPassword ? "text" : "Password"}
                name="confirm_new_password"
                id="Password"
                className="form-input white-black-color"
                placeholder="Reconfirm your password"
                onChange={handleChange}
                value={details.confirm_new_password}
              />
              <p
                className={`placeholder  white-black-bg white-black-color ${
                  details.confirm_new_password && "placeholder-active"
                }`}
              >
                Confirm Password
              </p>
              <p
                onClick={() => setShwoPassord(!showPassword)}
                className="show-hide"
              >
                {!showPassword ? (
                  <FaEyeSlash className="icon" />
                ) : (
                  <FaEye className="icon" />
                )}
              </p>
            </div>
            {/* form group end */}
            {/* progress box for password start */}
            <div className="progress-box">
              <p className="text">Password Strength </p>
              <ProgressBar
                bgcolor={
                  validate.has8digit +
                    validate.hasCap +
                    validate.hasLow +
                    validate.hasNumber ===
                  50
                    ? "#FFA72C"
                    : validate.has8digit +
                        validate.hasCap +
                        validate.hasLow +
                        validate.hasNumber ===
                      75
                    ? "#84BFCD"
                    : validate.has8digit +
                        validate.hasCap +
                        validate.hasLow +
                        validate.hasNumber >=
                      100
                    ? "#8DFB07"
                    : "#DE0B0B"
                }
                completed={
                  validate.has8digit +
                  validate.hasCap +
                  validate.hasLow +
                  validate.hasNumber
                }
              />
            </div>
            {/* progress box for password end */}
            {/* submit form start */}
            <input
              type="submit"
              value="Login"
              className="form-group btn-reuse form-submit"
            />
            {/* submit form  end */}
          </form>
          {/* form end */}
        </div>
      </AuthLayout>
      {/* modal start */}
      <Modal visible={showModal} effect="fadeInUp">
        <SuccessModal
          textOne={`Welcome Onboard Kendrick!`}
          textTwo={`Kindly proceed to login to review your profile and see assigned tasks`}
          btnText={`Proceed to Login`}
        >
          <button onClick={() => history.push("/login")} className="btn">
            Proceed to Login
          </button>
        </SuccessModal>
      </Modal>
      {/* modal end */}
    </>
  );
};

export default SignUp;
