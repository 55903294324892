import { Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Onboarding_route_group } from "./routes/auth";
import { Dashboard_route_group } from "./routes/dashboard";
import PrivateRoute from "./utils/PrivateRoute";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// toast.configure();

function App() {
  return (
    <div > 
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        {/* onboarding routes start */}
        {Onboarding_route_group.map((route, idx) => {
          return <Route key={idx} exact {...route} />;
        })}
        {Dashboard_route_group.map((route, idx) => {
          return <PrivateRoute  key={idx} {...route} />;
        })}
        {/* onboarding routes end */}
        <Route path="*" component={() => <h1>404 Not Found</h1>} />
      </Switch>
    </div>
  );
}

export default App;
