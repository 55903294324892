import React, { useEffect, useState } from "react";
import "../../../styles/dashboard/pet/CreatePetDetails.css";
import petDefault from "../../../assets/create-pet-img-default.png";
import Flatpicker from "react-flatpickr";
import "flatpickr/dist/themes/confetti.css";
import calendarIcon from "../../../assets/calendar-icon.svg";
import moment from "moment";
import Select from "react-select";
import Button from "../../../component/dashboard/fragments/Button";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPet } from "../../../redux/pets/petTypes";

const ViewPetDetail = () => {
  const [petImg, setPetImage] = useState("");
  const dispatch = useDispatch();
  const [singleChild, setSingleChild] = useState({});
  const [fromDate, setFromDate] = useState("");
  const [selectedOption, setselectedOption] = useState(null);
  const history = useHistory();
  const metricsDropDown = [
    { value: 1, label: "test one" },
    { value: 2, label: "test two" },
  ];

  useEffect(() => {
    getPetDetail();
  }, []);

  const getPetDetail = async () => {
    const id = localStorage.getItem("pet-id");
    const data = await dispatch(getPet(id));
    if (data?.payload?.success) {
      const pet = data?.payload?.pet;
      const obj = {
        ...pet,
        gender: { label: pet?.gender, value: pet?.gender },
        type: { label: pet?.type?.name, value: pet?.type?.id },
        user: { ...pet.user, label: pet?.user?.name },
      };
      console.log(obj);
      setSingleChild(obj);
    }
  };

  return (
    <div className="create-pet-detail-wrap">
      {/* ttitle box start */}
      <div className="title-box">
        <p className="text">Pet Details</p>
        <span></span>
      </div>
      {/* ttitle box end */}

      {/* form box start  */}
      <form action="" className="form">
        {/* picture detail box start */}
        <div className="picture-detail-box">
          {/* picture box start */}
          <div className="picture-box">
            <div className="form-group">
              <p className="text">Pet Picture</p>
              <input
                onChange={(e) => setPetImage(e.target.files[0])}
                type="file"
                name=""
                id="file-input"
                className="file-input"
              />
              <label
                style={{
                  backgroundImage: `url(${
                    petImg ? URL.createObjectURL(petImg) : petDefault
                  })`,
                }}
                htmlFor="file-input"
                className="file-label"
              ></label>
            </div>
          </div>
          {/* picture box end */}
          {/* distinguish box start */}
          <div className="distinguish-box">
            <p className="text">Distinguishing Marks (Optional)</p>
            <div className="form-group form-group-text-area">
              <textarea
                style={{ resize: "none" }}
                className="input"
                // placeholder="Give a detail note"
                name=""
                id=""
                cols="40"
                disabled
                value={singleChild?.distinguishing_marks}
                rows="7"
              ></textarea>
            </div>
          </div>
          {/* distinguish box end */}
        </div>
        {/* picture detail box end */}
        {/* three boxes start */}
        <div className="three-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="" className="label">
              Pet Name
            </label>
            <div className="input-wrap">
              <input
                name="name"
                value={singleChild?.name}
                disabled
                placeholder=""
                type="text"
                className="input"
              />
            </div>
          </div>
          {/* fom group end */}
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Select Date of Birth
            </label>
            <div className="group-two">
              <label htmlFor="from" className="label label-calendar">
                <figure className="img-box">
                  <img src={calendarIcon} alt="" className="img" />
                </figure>
              </label>
              <Flatpicker
                id="from"
                name="from"
                // data-enable-time
                disabled
                className="input-calender"
                autoComplete="off"
                value={moment(singleChild?.dob).format("YYYY-MM-DD")}
                // onChange={(date) =>
                //   setFromDate(moment(date[0]).format("YYYY/MM/DD"))
                // }
                // placeholder="DD / MM / YYYY"
              />
            </div>
          </div>
          {/* form group end */}

          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Select Sex
            </label>
            <Select
            isDisabled
              value={singleChild?.gender}
              onChange={(e) => setselectedOption(e)}
              options={metricsDropDown}
              className="input-border-select"
            />
          </div>
          {/* form group end */}
        </div>
        {/* three boxes end */}
        {/* three boxes start */}
        <div className="three-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Select Pet Type
            </label>
            <Select
              value={singleChild?.type}
              onChange={(e) => setselectedOption(e)}
              options={metricsDropDown}
              className="input-border-select"
            />
          </div>
          {/* form group end */}
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Resgistration Number
            </label>
            <div className="input-wrap">
              <input
                value={singleChild?.registration_number}
                name="registration_number"
                // onChange={handleChange}
                placeholder=""
                type="text"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* three boxes end */}

        {/* three boxes start */}
        <div className="three-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Assign Service Provider
            </label>
            <Select
              value={selectedOption}
              onChange={(e) => setselectedOption(e)}
              options={metricsDropDown}
              className="input-border-select"
            />
          </div>
          {/* form group end */}
        </div>
        {/* three boxes end */}

        {/* ttitle box start */}
        <div className="title-box">
          <p className="text">Pet Parent Details</p>
          <span></span>
        </div>
        {/* ttitle box end */}
        {/* two boxes start */}
        <div className="two-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Pet Parent Name
            </label>
            <Select
              isDisabled
              value={singleChild?.user}
              onChange={(e) => setselectedOption(e)}
              options={metricsDropDown}
              className="input-border-select"
            />
          </div>
          {/* form group end */}
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Phone Number
            </label>
            <div className="input-wrap">
              <input
                name="name"
                disabled
                // onChange={handleChange}
                placeholder=""
                value={singleChild?.user?.mobile}
                type="text"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* two boxes end */}
        {/* three boxes start */}
        <div className="three-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Email address
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="email"
                disabled
                value={singleChild?.user?.email}
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Select Branch
            </label>
            <Select
              value={selectedOption}
              onChange={(e) => setselectedOption(e)}
              options={metricsDropDown}
              className="input-border-select"
            />
          </div>
          {/* form group end */}
        </div>
        {/* three boxes end */}
        {/* two boxes start */}
        <div className="two-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Pet Parent Address
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                disabled
                value={singleChild?.user?.address}
                placeholder=""
                type="text"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Phone Number
            </label>
            <div className="input-wrap">
              <input
                value={singleChild?.user?.mobile}
                disabled
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="text"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* two boxes end */}
        {/* ttitle box start */}
        <div className="title-box">
          <p className="text">Ownership History (Optional)</p>
          <span></span>
        </div>
        {/* ttitle box end */}
        {/* two boxes start */}
        <div className="two-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Previous Pet Parent Name
            </label>
            <Select
              value={selectedOption}
              onChange={(e) => setselectedOption(e)}
              options={metricsDropDown}
              className="input-border-select"
            />
          </div>
          {/* form group end */}
        </div>
        {/* two boxes end */}
        {/* three boxes start */}
        <div className="three-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Email address
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="email"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* three boxes end */}
        {/* two boxes start */}
        <div className="two-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Previous Pet Parent Address
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="text"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* two boxes end */}
        {/* ttitle box start */}
        <div className="title-box">
          <p className="text">Breeder Details (Optional)</p>
          <span></span>
        </div>
        {/* ttitle box end */}
        {/* two boxes start */}
        <div className="two-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Breeder`s Name
            </label>
            <Select
              value={selectedOption}
              onChange={(e) => setselectedOption(e)}
              options={metricsDropDown}
              className="input-border-select"
            />
          </div>
          {/* form group end */}
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Phone Number
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="text"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* two boxes end */}
        {/* two boxes start */}
        <div className="two-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Breeder`s Address
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="text"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* two boxes end */}
        {/* btn box wrap start */}
        <div className="btn-box-wrap">
          <Button
            onClick={() => history.goBack()}
            text={`Back`}
            bgColor="#84BFCD"
            btnStyle={`btn-reuse`}
          />
          {/* <p className="cancel-text">Cancel</p> */}
        </div>
        {/* btn box wrap end */}
      </form>
      {/* form box end */}
    </div>
  );
};

export default ViewPetDetail;
