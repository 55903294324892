import PaymentRequestHistory from "../../../pages/dashboard/payment-request/PaymentRequestHistory";
import PaymentRequestPending from "../../../pages/dashboard/payment-request/PaymentRequestPending";

export const payment_request_route_group = [
  {
    path: "/dashboard-payment-request",
    component: PaymentRequestPending,
    title: "Payment Requests",
    smallTitle:
      "Review and Manage all  payment requests from the service providers in app",
  },
  {
    path: "/dashboard-payment-request-history",
    component: PaymentRequestHistory,
    title: "Payment Requests",
    smallTitle:
      "Review and Manage all  payment requests from the service providers in app",
  },
];
