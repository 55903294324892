import React, { useState } from "react";
import CommonHeader from "./CommonHeader";
import Flatpicker from "react-flatpickr";
import "flatpickr/dist/themes/confetti.css";
import moment from "moment";
import calendarIcon from "../../../assets/calendar-icon.svg";
import Button from "../../../component/dashboard/fragments/Button";
import "../../../styles/dashboard/support/SuppportHistory.css";
import TableRow from "../../../component/dashboard/tables/TableRow";
import ReuseableTable from "../../../component/dashboard/tables/ReuseableTable";
import ViewTicketModal from "./modal/ViewTicketModal";
import Modal from "react-awesome-modal";
import OpenTicketModal from "./modal/OpenTicketModal";
import SuccesOpenTicketModal from "./modal/SuccesOpenTicketModal";
import SuccessCloseTicketModal from "./modal/SuccessCloseTicketModal";
import CloseTicketModal from "./modal/CloseTicketModal";

const list = [
  {
    ticket_id: "#31562",
    name: "Kuramo Vets & Doctors",
    category: "App Bug",
    time: "30mins ago",
    status: "open",
  },
  {
    ticket_id: "#31562",
    name: "Kuramo Vets & Doctors",
    category: "App Bug",
    time: "30mins ago",
    status: "closed",
  },
  {
    ticket_id: "#31562",
    name: "Kuramo Vets & Doctors",
    category: "App Bug",
    time: "30mins ago",
    status: "open",
  },
  {
    ticket_id: "#31562",
    name: "Kuramo Vets & Doctors",
    category: "App Bug",
    time: "30mins ago",
    status: "closed",
  },
];

const SupportHistory = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [showHistoryTable, setShowHistoryTable] = useState(false);
  const [indexNo, setIndexNo] = useState("");
  const [showModal, setShowModal] = useState({
    view: false,
    close_ticket: false,
    open_ticket: false,
    close_ticket_success: false,
    open_ticket_success: false,
  });

  return (
    <>
      <div className="support-history-wrap-box">
        <CommonHeader />
        {!showHistoryTable && (
          <p className="subtitle">
            Select a Date range to fetch transaction records for
          </p>
        )}

        {/* select history box start */}
        {/* calendar box start */}
        {!showHistoryTable && (
          <div className="calender-box">
            <p className="text">from</p>
            <div className="form-group">
              <label htmlFor="from" className="label">
                <figure className="img-box">
                  <img src={calendarIcon} alt="" className="img" />
                </figure>
              </label>
              <Flatpicker
                id="from"
                name="from"
                data-enable-time
                //   cal
                className="input-calender"
                autoComplete="off"
                value={fromDate}
                onChange={(date) =>
                  setFromDate(moment(date[0]).format("YYYY/MM/DD"))
                }
                placeholder="DD / MM / YYYY"
              />
            </div>
            <p style={{ marginLeft: "2rem" }} className="text">
              to
            </p>
            <div className="form-group">
              <label htmlFor="to" className="label">
                <figure className="img-box">
                  <img src={calendarIcon} alt="" className="img" />
                </figure>
              </label>
              <Flatpicker
                data-enable-time
                id="to"
                name="to"
                //   cal
                className="input-calender"
                autoComplete="off"
                value={toDate}
                onChange={(date) =>
                  settoDate(moment(date[0]).format("YYYY/MM/DD"))
                }
                placeholder="DD / MM / YYYY"
              />
            </div>
          </div>
        )}
        {/* btn start */}
        {!showHistoryTable && (
          <Button
            btnStyle={`btn-reuse`}
            text="Fetch Records"
            bgColor={`#84BFCD`}
            onClick={() => setShowHistoryTable(true)}
          />
        )}
        {/* btn end */}

        {showHistoryTable && (
          <>
            {/* reset text box start */}
            <div className="reset-text-box">
              <p className="text">
                Displaying <span> 4 </span> results from <span>23/11/2021</span>{" "}
                to <span>01/01/2022</span>
              </p>
              <p onClick={() => setShowHistoryTable(false)} className="reset">
                Reset
              </p>
            </div>
            {/* reset text box end */}
            {/* table start */}
            <ReuseableTable
              //   loading={loading}
              one={"Ticket ID"}
              two={"Name"}
              three={`Category`}
              four={`Time`}
              threeDrop
              twoDrop
              action
              fourDrop
              five={`Status`}
              fiveDrop
              moreThanFive={list?.length >= 3}
              //   tableTwo
            >
              {list.map((chi, idx) => {
                const { name, category, ticket_id, time, status } = chi;
                return (
                  <TableRow
                    setIndexNo={() => setIndexNo(idx)}
                    rowNo={idx}
                    indexNo={indexNo}
                    key={idx}
                    action
                    one={ticket_id}
                    two={name}
                    three={category}
                    four={time}
                    statusColor={status === "open" ? "deliver" : "yellow"}
                    status={status}
                    viewText={`View Ticket`}
                    listText={status === "open" && `Close Ticket`}
                    onList={() =>
                      setShowModal((prev) => {
                        return { ...prev, close_ticket: true };
                      })
                    }
                    unListText={status !== "open" && `Open Ticket`}
                    onUnList={() =>
                      setShowModal((prev) => {
                        return { ...prev, open_ticket: true };
                      })
                    }
                    onViewDetail={() =>
                      setShowModal((prev) => {
                        return { ...prev, view: true };
                      })
                    }
                  />
                );
              })}
            </ReuseableTable>
            {/* table end */}
            {/*  view modal start */}
            <Modal effect="fadeInUp" visible={showModal.view}>
              <ViewTicketModal
                onClose={() =>
                  setShowModal((prev) => {
                    return { ...prev, view: false };
                  })
                }
              />
            </Modal>
            {/* view modal end */}
            {/*  open success ticket modal start */}
            <Modal effect="fadeInUp" visible={showModal.open_ticket_success}>
              <SuccesOpenTicketModal
                onClose={() =>
                  setShowModal((prev) => {
                    return { ...prev, open_ticket_success: false };
                  })
                }
              />
            </Modal>
            {/* open success ticket modal end */}
            {/*  open ticket modal start */}
            <Modal effect="fadeInUp" visible={showModal.open_ticket}>
              <OpenTicketModal
                onClose={() =>
                  setShowModal((prev) => {
                    return { ...prev, open_ticket: false };
                  })
                }
                onOpen={() =>
                  setShowModal((prev) => {
                    return {
                      ...prev,
                      open_ticket: false,
                      open_ticket_success: true,
                    };
                  })
                }
              />
            </Modal>
            {/* open ticket modal end */}
            {/*  close ticket success modal start */}
            <Modal effect="fadeInUp" visible={showModal.close_ticket_success}>
              <SuccessCloseTicketModal
                onClose={() =>
                  setShowModal((prev) => {
                    return { ...prev, close_ticket_success: false };
                  })
                }
              />
            </Modal>
            {/* close ticket success modal end */}
            {/* close ticket modal start */}
            <Modal effect="fadeInUp" visible={showModal.close_ticket}>
              <CloseTicketModal
                onClose={() =>
                  setShowModal((prev) => {
                    return { ...prev, close_ticket: false };
                  })
                }
                onCloseTicket={() =>
                  setShowModal((prev) => {
                    return {
                      ...prev,
                      close_ticket: false,
                      close_ticket_success: true,
                    };
                  })
                }
              />
            </Modal>
            {/*close ticket modal end */}
          </>
        )}
      </div>
    </>
  );
};

export default SupportHistory;
