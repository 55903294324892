import React from 'react'
import Button from '../../../../../component/dashboard/fragments/Button'
import CommonModal from '../CommonModal'

const SuccessRejectModal = ({onClose}) => {
  return (
    <>
    <CommonModal
      onClose={onClose}
      successRed
      bigText={`Document successfully rejected`}
    >
      <Button bgColor={`#FFA72C`} text="Close" onClick={onClose} />
    </CommonModal>
  </>
  )
}

export default SuccessRejectModal