import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import Skeleton from "react-loading-skeleton";
// import NoTableContent from "../../../../fragments/NoTableContent";
// import NoBarContent from "../../../../fragments/NoBarContent";
import { numberFormatChart } from "../../../helpers/Helper";
import NoBarContent from "./NoBarContent";
// import faker from 'faker';
// ChartJS.pointWidth(10);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// import React from 'react'

const DashboardBarChart = ({ dataOne, dataTwo, dataToShow, loading }) => {
//   const isDarkTheme = useThemeDetector();
//   const { preference } = useSelector((state) => state.preference);
  // const brandcolor = localStorage.getItem("brand_color");
  // const []

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        barPercentage: 0.3,
        categoryPercentage: 0.3,
        ticks: {
          color: "#1d1d18",
        },
        // stacked: true,
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback: (val) => `${numberFormatChart(val)}`,
          color: "#1d1d18",
        },
      },
    },
  };

  if (loading) {
    return (
    <NoBarContent />
    );
  }

  return (
    <>
      <Bar
        redraw={false}
        width={100}
        height={100}
        options={options}
        data={dataToShow}
      />
    </>
  );
};

export default DashboardBarChart;