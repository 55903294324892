import React, { useState, useEffect } from "react";
// import DashboardLayout from "../../../component/dashboard/layout";
import ReuseableTable from "../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../component/dashboard/tables/TableRow";
import { shopOrderList } from "../../../utils/ShopData";
import CommonHeader from "./CommonHeader";
import Modal from "react-awesome-modal";
import OrderDetailModal from "./modals/OrderDetailModal";
import { FaTimes } from "react-icons/fa";
import AcceptOrderModal from "./modals/AcceptOrderModal";
import SuccessAcceptModal from "./modals/SuccessAcceptModal";
import Button from "../../../component/dashboard/fragments/Button";
import RejectOrderModal from "./modals/RejectOrderModal";
import SuccessRejectModal from "./modals/SuccessRejectModal";
import ConfirmOrderModal from "./modals/ConfirmOrderModal";
import SuccessConfirmModal from "./modals/SuccessConfirmModal";
import OrderHistorySelectModal from "./modals/OrderHistorySelectModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getShopOrders,
  getShopOrdersPagination,
} from "../../../redux/shop/shopOrder";
import {
  formatNumWithCommaNairaSymbol,
  lowerCaseWrap,
  sumAllNum,
} from "../../../helpers/Helper";

const ShopOrder = () => {
  const [indexNo, setIndexNo] = useState("");
  const dispatch = useDispatch();
  const [showCotent, setShowContent] = useState("Details");
  const [singleChild, setSingleChild] = useState({});
  const { shop_orders, loading } = useSelector((state) => state.shop_order);
  const [showModal, setShowModal] = useState({
    order_detail: false,
    confirm_order: false,
    reject: false,
    accept: false,
    successAccept: false,
    successReject: false,
    successConfirm: false,
    order_history_select: false,
  });

  useEffect(() => {
    dispatch(getShopOrders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePagination = async (param) => {
    const linkSplit = param.split("api/");
    const link = `${linkSplit[1]}&paginate=${10}`;
    // console.log(param);
    // console.log(link);
    // return
    dispatch(getShopOrdersPagination(link));
  };

  const getAllSumArray = (list) => {
    if (list?.length > 0) {
      const newList = list?.map((chi) => chi?.cost);
      // console.log(newList);
      return newList;
    }
  };

  return (
    <>
      <div
      // onClick={() => {
      //   console.log(shop_orders);
      // }}
      >
        {/* <DashboardLayout
        pageTitle={`Shop`}
        pageSmallText={`Review and Manage all sales and shop listing on Petme`}
      > */}

        {/* common headr start */}
        <CommonHeader
          onClickBtn={() => {
            // console.log(shop_orders);
            setShowModal((prev) => {
              return { ...prev, order_history_select: true };
            });
          }}
        />
        {/* common header end */}
        {/* table start  */}
        <ReuseableTable
          one={shopOrderList.tableHead[0]}
          two={shopOrderList.tableHead[1]}
          three={shopOrderList.tableHead[2]}
          four={shopOrderList.tableHead[3]}
          five={shopOrderList.tableHead[4]}
          six={shopOrderList.tableHead[5]}
          fourDrop
          fiveDrop
          sixDrop
          action
          moreThanFive={shop_orders?.data?.length >= 3}
          toNo={shop_orders?.meta?.to}
          totalNo={shop_orders?.meta?.total}
          fromNo={shop_orders?.meta?.from}
          nextPage={shop_orders?.links?.next}
          prevPage={shop_orders?.links?.prev}
          onNext={() => {
            handlePagination(shop_orders?.links?.next);
          }}
          onPrev={() => {
            handlePagination(shop_orders?.links?.prev);
          }}
        >
          {shop_orders?.data?.map((child, index) => {
            const {
              quantity,
              order_reference,
              user_name,
              payment_method,
              cart_order_items,
              status,
            } = child;
            return (
              <TableRow
                setIndexNo={() => setIndexNo(index)}
                rowNo={index}
                indexNo={indexNo}
                loading={loading}
                key={index}
                action
                one={order_reference}
                two={lowerCaseWrap(user_name)}
                three={lowerCaseWrap(payment_method)}
                four={quantity}
                five={
                  cart_order_items
                    ? formatNumWithCommaNairaSymbol(
                        String(
                          sumAllNum(getAllSumArray(child?.cart_order_items))
                        )
                      )
                    : formatNumWithCommaNairaSymbol("0")
                }
                status={status !== "0" ? "deliver" : "pending"}
                // statusColor={"pending"}
                statusColor={status !== "0" ? "deliver" : "pending"}
                // pending_order={order_status?.toLowerCase() === "pending"}
                // onAccept={() =>
                //   setShowModal((prev) => {
                //     return { ...prev, accept: true };
                //   })
                // }
                // onReject={() =>
                //   setShowModal((prev) => {
                //     return { ...prev, reject: true };
                //   })
                // }
                onViewDetail={() => {
                  setSingleChild(child);
                  // console.log(child);
                  getAllSumArray(child?.cart_order_items);
                  setShowModal((prev) => {
                    return { ...prev, order_detail: true };
                  });
                }}
                // onConfirm={() =>
                //   setShowModal((prev) => {
                //     return { ...prev, confirm_order: true };
                //   })
                // }
              />
            );
          })}
        </ReuseableTable>
        {/* table end */}
        {/* </DashboardLayout> */}
      </div>

      {/* order detail modal start */}
      <Modal visible={showModal.order_detail} effect="fadeInUp">
        <OrderDetailModal
          showCotent={showCotent}
          setShowContent={setShowContent}
          detail={singleChild}
        >
          <div
            onClick={() => {
              setShowModal((prev) => {
                return { ...prev, order_detail: false };
              });
              setShowContent("Details");
            }}
            className="close-icon"
          >
            <FaTimes className="icon" />
          </div>
        </OrderDetailModal>
      </Modal>
      {/* order detail modal send */}
      {/* accept order modal start */}
      <Modal visible={showModal.accept} effect="fadeInUp">
        <AcceptOrderModal
          onAccept={() =>
            setShowModal((prev) => {
              return { ...prev, successAccept: true, accept: false };
            })
          }
        >
          <div
            onClick={() =>
              setShowModal((prev) => {
                return { ...prev, accept: false };
              })
            }
            className="close-icon"
          >
            <FaTimes className="icon" />
          </div>
        </AcceptOrderModal>
      </Modal>
      {/* accept order modal send */}
      {/* success accept order modal start */}
      <Modal visible={showModal.successAccept} effect="fadeInUp">
        <SuccessAcceptModal>
          <div
            onClick={() =>
              setShowModal((prev) => {
                return { ...prev, successAccept: false };
              })
            }
            className="close-icon"
          >
            <FaTimes className="icon" />
          </div>
          <Button
            onClick={() =>
              setShowModal((prev) => {
                return { ...prev, successAccept: false };
              })
            }
            text={`Close`}
            bgColor={`#FFA72C`}
          />
        </SuccessAcceptModal>
      </Modal>
      {/* success accept order modal send */}
      {/* reject order modal start */}
      <Modal visible={showModal.reject} effect="fadeInUp">
        <RejectOrderModal
          onReject={() =>
            setShowModal((prev) => {
              return { ...prev, successReject: true, reject: false };
            })
          }
        >
          <div
            onClick={() =>
              setShowModal((prev) => {
                return { ...prev, reject: false };
              })
            }
            className="close-icon"
          >
            <FaTimes className="icon" />
          </div>
        </RejectOrderModal>
      </Modal>
      {/* reject order modal send */}
      {/* success reject order modal start */}
      <Modal visible={showModal.successReject} effect="fadeInUp">
        <SuccessRejectModal>
          <div
            onClick={() =>
              setShowModal((prev) => {
                return { ...prev, successReject: false };
              })
            }
            className="close-icon"
          >
            <FaTimes className="icon" />
          </div>
          <Button
            onClick={() =>
              setShowModal((prev) => {
                return { ...prev, successReject: false };
              })
            }
            text={`Close`}
            bgColor={`#FFA72C`}
          />
        </SuccessRejectModal>
      </Modal>
      {/* success reject order modal send */}
      {/* confirm order modal start */}
      <Modal visible={showModal.confirm_order} effect="fadeInUp">
        <ConfirmOrderModal
          onAccept={() =>
            setShowModal((prev) => {
              return { ...prev, successConfirm: true, confirm_order: false };
            })
          }
        >
          <div
            onClick={() =>
              setShowModal((prev) => {
                return { ...prev, confirm_order: false };
              })
            }
            className="close-icon"
          >
            <FaTimes className="icon" />
          </div>
        </ConfirmOrderModal>
      </Modal>
      {/* confirm order modal send */}
      {/* success confirm order modal start */}
      <Modal visible={showModal.successConfirm} effect="fadeInUp">
        <SuccessConfirmModal>
          <div
            onClick={() =>
              setShowModal((prev) => {
                return { ...prev, successConfirm: false };
              })
            }
            className="close-icon"
          >
            <FaTimes className="icon" />
          </div>
          <Button
            onClick={() =>
              setShowModal((prev) => {
                return { ...prev, successConfirm: false };
              })
            }
            text={`Close`}
            bgColor={`#FFA72C`}
          />
        </SuccessConfirmModal>
      </Modal>
      {/* success confirm order modal send */}
      {/* order history modal start */}
      <Modal visible={showModal.order_history_select} effect="fadeInUp">
        <OrderHistorySelectModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, order_history_select: false };
            })
          }
          onClickTableRow={() =>
            setShowModal((prev) => {
              return {
                ...prev,
                order_history_select: false,
                order_detail: true,
              };
            })
          }
        ></OrderHistorySelectModal>
      </Modal>
      {/* order history modal send */}
    </>
  );
};

export default ShopOrder;
