import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import "../../../../styles/dashboard/user-admin/AddNewUserModal.css";
import Select from "react-select";
import Button from "../../../../component/dashboard/fragments/Button";
// import Button from "../../../../../component/dashboard/fragments/Button";

const EditNewUserModal = ({ onClose, onCreate }) => {
  const [selectedOption, setselectedOption] = useState(null);
  const metricsDropDown = [
    { value: 1, label: "test one" },
    { value: 2, label: "test two" },
  ];

  return (
    <div className="add-new-user-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* end of cancel */}
      <p className="title">Edit User Details</p>
      {/* form start */}
      <form action="" className="form">
        {/* two boxes start */}
        <div className="two-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Enter User`s Name
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="text"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* two boxes end */}
        {/* three boxes start */}
        <div className="three-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Email address
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="email"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* three boxes end */}
        {/* three boxes start */}
        <div className="three-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Select Role
            </label>
            <Select
              value={selectedOption}
              onChange={(e) => setselectedOption(e)}
              options={metricsDropDown}
              className="input-border-select"
            />
          </div>
          {/* form group end */}
        </div>
        {/* three boxes end */}

        {/* button start */}
        <Button
          text={`Save Changes`}
          btnStyle={`btn-reuse`}
          bgColor="#84BFCD"
          onClick={onCreate}
        />
        {/* button end */}
      </form>
      {/* form end */}
    </div>
  );
};

export default EditNewUserModal;
