import React from "react";
import "../../../styles/dashboard/Button.css";

const Button = ({ children, text, onClick, bgColor, btnStyle }) => {
  return (
    <div
      style={{ backgroundColor: bgColor ? bgColor : "" }}
      onClick={onClick}
      className={`btn-wrap ${btnStyle}`}
    >
      {children} {text}
    </div>
  );
};

export default Button;
