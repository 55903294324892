 
  /* eslint-disable no-useless-concat */

import moment from "moment";
// import { stringSimilarity } from "string-similarity";

export const  formatNumWithComma = (numb)  => {
    var regex = /[,\sNG]/g;
    var result = numb.replace(regex, "");
    var num = Math.abs(result);
    num = num.toFixed(2);
    const numSplit = num.split(".");
    var int = numSplit[0];
    // const dec = numSplit[1];
    if (int.length > 3) {
      int = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  
    return  int 
  }

  export const  formatNumWithCommaNaira = (numb)  => {
    const nairaSymbol = "\u{020A6}"
    var regex = /[,\sNG]/g;
    var result = numb.replace(regex, "");
    var num = Math.abs(result);
    num = num.toFixed(2);
    const numSplit = num.split(".");
    var int = numSplit[0];
    const dec = numSplit[1];
    if (int.length > 3) {
      int = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  
    return  `${nairaSymbol}` + int  + "." + dec
  }

  export const  formatNumWithoutComma = (numb)  => {
    var regex = /[,.\sNG]/g;
    var result = numb.replace(regex, "");
    

    return  result
  }

  export const reactSelectStyleChart = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      // backgroundColor: state.isSelected ? "#6F6F6F" : "white",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        // border: state.isFocused ? 0 : 0
      }
    }),
    option: (provided, state) => ({
      ...provided,      
      backgroundColor: state.isSelected ? "#FFA72C" : "white",
    }), 
  };

  export const reactSelectStyle = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? ".1rem solid #bbbbbb" : ".1rem solid #bbbbbb",
      // backgroundColor: state.isSelected ? "#6F6F6F" : "white",
      boxShadow: state.isFocused ? ".1rem solid #bbbbbb" : ".1rem solid #bbbbbb",
      "&:hover": {
        // border: state.isFocused ? 0 : 0
      }
    }),
    option: (provided, state) => ({
      ...provided,      
      backgroundColor: state.isSelected ? "#FFA72C" : "white",
    }), 
  };


  export const addLabelValueFunc = (list) => {
    // console.log(list);
    const newList = list.map((chi) => {
      return { ...chi, label: chi?.name, value: chi?.id };
    });
    // console.log(newList);
    return newList;
  };


export const reactSelectStyleTwo = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "0.1rem solid #6F6F6F" : "0.1rem solid #6F6F6F",
    // backgroundColor: state.isSelected ? "#6F6F6F" : "white",
    boxShadow: state.isFocused ? "0.1rem solid #6F6F6F" : 0,
    "&:hover": {
      // border: state.isFocused ? 0 : 0
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgba(204, 204, 204, .3)" : "white",
    color: state.isSelected ? "#020202" : "#020202",
  }),
};

export const formatNumWithoutCommaNaira = (numb) => {
  // const nairaSymbol = "\u{020A6}";

  var regex = /[,\sN#%₦G]/g;
  var result = String(numb).replace(regex, "");
  return result;
};

export const formatNumWithCommaNairaTwo = (numb) => {
  // const nairaSymbol = "\u{020A6}";

  var regex = /[,\sNG]/g;
  var result = String(numb).replace(regex, "");
  var num = Math.abs(result);
  num = num.toFixed(2);
  const numSplit = num.split(".");
  var int = numSplit[0];
  const dec = numSplit[1];
  if (int.length > 3) {
    int = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (numb) {
    return int + "." + dec;
  }

  return "0" + "." + "00";
};

export const formatNumWithCommaNairaSymbol = (numb) => {
  const nairaSymbol = "\u{020A6}";

  var regex = /[,\sNG]/g;
  var result = String(numb).replace(regex, "");
  var num = Math.abs(result);
  num = num.toFixed(2);
  const numSplit = num.split(".");
  var int = numSplit[0];
  const dec = numSplit[1];
  if (int.length > 3) {
    int = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (numb) {
    return nairaSymbol + "" + int + "." + dec;
  }

  return nairaSymbol + "" + "0" + "." + "00";
};

export const getTotalPage = (perpage, totalNum) => {
  const val = Math.ceil(Number(totalNum) / Number(perpage));
  // console.log(val);
  return val;
};

export function formatDate(date) {
  var actualDate = String(moment(date).format("MMMM, DD, YYYY  — HH:mm a"));
  const splitDate = actualDate.split(",");
  var newDate =
    splitDate[0].slice(0, 3) + "." + splitDate[1] + "," + splitDate[2];
  return newDate;
}

// export function numberFormatChart(num, digits) {
//   const lookup = [
//     { value: 1, symbol: "" },
//     { value: 1e3, symbol: "k" },
//     { value: 1e6, symbol: "M" },
//     { value: 1e9, symbol: "B" },
//     { value: 1e12, symbol: "T" },
//     { value: 1e15, symbol: "Q" },
//     { value: 1e18, symbol: "QT" },
//     { value: 1e21, symbol: "QQ" },
//   ];
//   const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
//   var item = lookup
//     .slice()
//     .reverse()
//     .find(function (item) {
//       return num >= item.value;
//     });
//   if (item && item.symbol === "M") {
//     // const formattedNum = (num / item.value).toFixed(digits);
//     // const integerPart = formattedNum.split(".")[0];
//     // const decimalPart = formattedNum.split(".")[1];
//     // return `${integerPart}.${decimalPart}${item.symbol}`;
//   }
//   return item
//     ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
//     : "0";
// }

export const formatMetaData = (obj) => {
  if (obj) {
    const newObj = JSON?.parse(obj);
    // console.log(newObj);
    return newObj;
  }
};

export const getPageNum = (link) => {
  if (link) {
    const num = link?.split("&current_page=")[1]?.split("&")[0];
    return num;
  }
};

export const sumAllNum = (list) => {
  if (list) {
    const sumVal = list?.reduce((a, b) => Number(a) + Number(b), 0);
    return sumVal;
  }
};

export const removeCountryCode = (num) => {
  if (num) {
    const val =
      num.slice(0, 3) === "234"
        ? num.replace("234", "0")
        : num.slice(0, 1) === "0"
        ? num.replace("0", "")
        : num;
    const valspace = val?.replace(" ", "");
    return removeLeadingZeroFromString(valspace);
  }
};

export const capitalizeFirstLetter = (str) => {
  if (str) {
    let capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalizedStr;
  }
};

export const formatStatus = (param) => {
  if (param) {
    const val =
      param === "0"
        ? "pending"
        : param === "1"
        ? "processing"
        : param === "2"
        ? "success"
        : param === "3"
        ? "failed"
        : "--";
    return val;
  }
};

// export function findBestMatch(str, strArr) {
//   const lowerCaseArr = strArr.map((element) => element.toLowerCase()); //creating lower case array
//   const match = stringSimilarity.findBestMatch(
//     str.toLowerCase(),
//     lowerCaseArr
//   ).bestMatch; //trying to find bestMatch
//   //  console.log('rating >>', match.rating);
//   if (match.rating > 0 && match.rating >= 0.5) {
//     const foundIndex = lowerCaseArr.findIndex((x) => x === match.target); //finding the index of found best case
//     return strArr[foundIndex]; //returning initial value from array
//   }
//   return null;
// }

export const reverseArrayFunc = (list) => {
  if(list?.length > 0){
    const reversedArray = list?.reverse();
    return reversedArray
  }
}

export const removeSpace = (str) => {
  const val = str.replace(/\s+/g, "");
  // console.log(val);
  return val;
};

// export const sumAllNum = (list) => {
//   if (list) {
//     const sumVal = list?.reduce((a, b) => a + b, 0);
//     return sumVal;
//   }
// };

// export const trimLongString = (str, num) => {
//   if (str && num) {
//     const val =
//       String(str).length > Number(num)
//         ? `${String(str).slice(0, Number(num))}...`
//         : str;
//     return val;
//   }
// };

// export const sumAllNum = (list) => {
//   if (list) {
//     const sumVal = list?.reduce((a, b) => a + b, 0);
//     return sumVal;
//   }
// };

export const lowerCaseWrap = (text) => {
  if (text) {
    // Capitalize the first letter of the first word
    const firstWord = text.split(" ")[0];
    const capitalizedFirstWord =
      firstWord.charAt(0).toUpperCase() + firstWord.slice(1);

    // Reconstruct the string with the capitalized first word
    return capitalizedFirstWord + text.slice(firstWord.length);
  }
};

export const getPosTerminalRequest = (list) => {
  if (list?.length > 0) {
    const status = list[0]?.status;
    // console.log(status);
    return status;
  }
};

export const formatTitleTemplate = (text) => {
  var regex = /[,\s_.csvNG]/g;
  var result = String(text).replace(regex, " ");
  return result;
};

export const formatNUmPan = (str) => {
  if (str) {
    const val = `${str?.slice(0, 6)}******${str?.slice(
      str?.length - 4,
      str?.length
    )}`;
    return val;
  }
};

export const trimLongString = (str, num) => {
  if (str && num) {
    const val =
      String(str).length > Number(num || 15)
        ? `${String(str).slice(0, Number(num || 15))}...`
        : str;
    return val;
  }
};

export const formatTypeFunction = (str) => {
  let val;
  if (str) {
    if (!str?.includes("_")) {
      return str;
    }
    const splitStr = str.split("_");
    // console.log(splitStr);
    if (splitStr.length === 1 && str?.includes("_")) {
      const newText = `${splitStr[0] || ""}`;
      val = lowerCaseWrap(newText);
    }
    if (splitStr.length === 2 && str?.includes("_")) {
      const newText = `${splitStr[0] || ""} ${splitStr[1] || ""}`;
      val = lowerCaseWrap(newText);
    }
    if (splitStr.length === 3 && str?.includes("_")) {
      const newText = `${splitStr[0] || ""} ${splitStr[1] || ""} ${
        splitStr[2] || ""
      }`;
      val = lowerCaseWrap(newText);
    }
    // console.log(val);
    return val;
  }
};

export const getStartAndStopDates = (param) => {
  if (param) {
    // get the current date
    var currentDate = moment();

    // subtract 7 days from the current date moment(currentDate).subtract(daysToSubtract, 'days')
    var actualDate = moment(currentDate).subtract(param, "days");

    const fromDate = actualDate.format("YYYY-MM-DD");

    const toDate = currentDate.format("YYYY-MM-DD");

    const obj = {
      start_date: fromDate,
      end_date: toDate,
    };

    return obj;
  }
};

export const getAllAmount = (list) => {
  if (list.length > 0) {
    const amountList = list.map((chi) =>
      Number(formatNumWithoutCommaNaira(chi?.amount))
    );
    const sumList = sumAllNum(amountList);
    // console.log(sumList);
    return sumList;
  }
};

export const returnCbnTransferFee = (feesObject, amount) => {
  let transferCharge = 0;
  let cbnFeeStructure = feesObject.cbn;
  for (let l in cbnFeeStructure) {
    let splittedIndex = l.split("_");
    let lowerNumberForComparism = splittedIndex[0];
    if (parseFloat(splittedIndex.length) === parseFloat(2)) {
      if (parseFloat(splittedIndex[0]) > parseFloat(0)) {
        lowerNumberForComparism = parseFloat(splittedIndex[0]) - parseFloat(1);
      }
      if (
        parseFloat(amount) > parseFloat(lowerNumberForComparism) &&
        parseFloat(amount) <= parseFloat(splittedIndex[1])
      ) {
        transferCharge = parseFloat(cbnFeeStructure[String(l)]);
        break;
      }
    } else {
      if (parseFloat(amount) >= parseFloat(splittedIndex[0])) {
        transferCharge = parseFloat(cbnFeeStructure[String(splittedIndex[0])]);
        break;
      }
    }
  }
  return transferCharge;
};

export function clearCache() {
  if ("caches" in window) {
    // Clear all caches
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }
}

export const formatListWithImage = (list) => {
  const newList = list.map((chi) => {
    return {
      ...chi,
      imageSrc: `https://businessapi.getraventest.com/static/media/banks/${chi?.value}.png `,
    };
  });
  // console.log(newList);
  return newList;
};

export function removeLeadingZeroFromString(str) {
  if (typeof str !== "string") {
    return str; // Return as it is if not a string
  }

  if (str.charAt(0) === "0") {
    return str.substr(1); // Remove the first character (leading zero) and return the rest of the string
  }

  return str; // Return the string as it is if the first character is not '0'
}

export function getCurrencySymbol(param) {
  const nairaSymbol = "\u{020A6}";
  const symbol =
    param === "NGN"
      ? nairaSymbol
      : param === "USD"
      ? "$"
      : param === "EUR"
      ? "€"
      : param === "GBP"
      ? "£"
      : nairaSymbol;
  return symbol;
}

export function getPrimaryAccount(list) {
  if (list?.length > 0) {
    const newList = list?.filter((chi) => chi?.primary === 1);
    // console.log(newList);
    return newList[0];
  }
}

export function formAllAccountList(list) {
  // console.log(list);
  if (list?.length > 0) {
    const firstChi = getPrimaryAccount(list);
    const newList = list?.filter((chi) => chi?.primary !== 1);
    const returnList = [firstChi, ...newList];
    return returnList;
  }
}


export function canStringify(obj) {
  try {
    JSON.stringify(obj);
    return true;
  } catch (error) {
    return false;
  }
}


// export const lowerCaseWrap = (text) => {
//   if (text) {
//     // Capitalize the first letter of the first word
//     const firstWord = text.split(" ")[0];
//     const capitalizedFirstWord =
//       firstWord.charAt(0).toUpperCase() + firstWord.slice(1);

//     // Reconstruct the string with the capitalized first word
//     return capitalizedFirstWord + text.slice(firstWord.length);
//   }
// };

export function numberFormatChart(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "Q" },
    { value: 1e18, symbol: "QT" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}