import React from "react";
import "../../../../styles/dashboard/shop/Modal.css";
// import sucImg from "../../../../assets/success-order-icon.svg";
// import rejImg from "../../../../assets/reject-order-icon.svg";
// import acpImg from "../../../../assets/accept-order-icon.svg";
import CommonModal from "./CommonModal";
import Button from "../../../../component/dashboard/fragments/Button";

const AcceptOrderModal = ({ children, onAccept }) => {
  return (
    <>
      <CommonModal
        accept
        bigText={`Are you sure you want to accept this
   Order?`}
      >
        {children}
        <Button
          onClick={onAccept}
          text={`Accept Order`}
          bgColor={`#00C013`}
        />
      </CommonModal>
    </>
  );
};

export default AcceptOrderModal;
