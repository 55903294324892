import React, { useState } from "react";
import "../../../../styles/dashboard/support/SupportChat.css";
import moment from "moment";
import InputEmoji from "react-input-emoji";
import cameraIcon from "../../../../assets/chat-camera-icon.svg";
import sendIcon from "../../../../assets/chat-send-icon.svg";

const InputWrap = () => {
  const [text, setText] = useState("");
  const [details, setDetails] = useState({
    text: "",
    file: "",
  });

  function handleOnEnter(text) {
    // console.log("enter", text);
    const date = new Date();
    const time = moment(date).format("hh:mm A");
    const obj = {
      msg: details.text,
      time: time,
    };
    // actionCtx.addToMessageListFunc(obj)
    // scrollBottom()
    setTimeout(() => {
      setText("");
      // console.log(details.text);
    }, 500);
  }

  return (
    <div className="support-input-wrap">
      {/* input emoji wrap start */}
      <div className="input-wrap">
        <InputEmoji
          value={text}
          onChange={(e) => {
            setText(e);
            setDetails((prev) => {
              return { ...prev, text: e };
            });
          }}
          cleanOnEnter
          onEnter={handleOnEnter}
          placeholder="Type a message"
          borderRadius={5}
          theme="auto"
          // style={{backgroundColor: "red !important"}}
        />
      </div>
      {/* input emoji wrap end */}
      {/* camera box start */}
      <figure className="img-box">
        <img src={cameraIcon} alt="" className="img" />
      </figure>
      {/* camera box end */}
       {/* send box start */}
       <figure className="img-box">
        <img src={sendIcon} alt="" className="img" />
      </figure>
      {/* send box end */}
    </div>
  );
};

export default InputWrap;
