import CreatePetDetail from "../../../pages/dashboard/pets/CreatePetDetail";
import DashboardPets from "../../../pages/dashboard/pets/DashboardPets";
import EditPetDetail from "../../../pages/dashboard/pets/EditPetDetail";
import PetParent from "../../../pages/dashboard/pets/PetParent";
import ViewPetDetail from "../../../pages/dashboard/pets/ViewPetDetail";

export const pet_route_group = [
  {
    path: "/dashboard-pets",
    component: DashboardPets,
    title: "Pets",
    smallTitle: "Review and Manage all Pets & Pet Parents on Petme",
  },
  {
    path: "/dashboard-pets-parent",
    component: PetParent,
    title: "Pets",
    smallTitle: "Review and Manage all Pets & Pet Parents on Petme",
  },
  {
    path: "/dashboard-pets-view-pet-detail",
    component: ViewPetDetail,
    title: "Pets",
    smallTitle: "Review and Manage all Pets & Pet Parents on Petme",
  },
  {
    path: "/dashboard-pets-edit-pet-detail",
    component: EditPetDetail,
    title: "Pets",
    smallTitle: "Review and Manage all Pets & Pet Parents on Petme",
  },
  {
    path: "/dashboard-pets-create-pet-detail",
    component: CreatePetDetail,
    title: "Pets",
    smallTitle: "Review and Manage all Pets & Pet Parents on Petme",
  },
];
