import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const SuccessDeletePetModal = ({ onClose }) => {
  return (
    <>
      <CommonModal onClose={onClose} success bigText={`Pet Details  Successfully Deleted`}>
        <Button bgColor={`#FFA72C`} text="Close" onClick={onClose} />
      </CommonModal>
    </>
  );
};

export default SuccessDeletePetModal;
