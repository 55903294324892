import React from "react";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import "../../../../styles/dashboard/pet/modal/CreateAppointmentModal.css";
import Select from "react-select";
import Flatpicker from "react-flatpickr";
import "flatpickr/dist/themes/confetti.css";
import moment from "moment";
import calendarIcon from "../../../../assets/calendar-icon.svg";
import Button from "../../../../component/dashboard/fragments/Button";

const CreateAppointmentModal = ({ onClose, onCreateAppointment }) => {
  const [fromDate, setFromDate] = useState("");
  const [activeBox, setActiveBox] = useState("details");
  const [selectedOption, setselectedOption] = useState(null);

  const metricsDropDown = [
    { value: 1, label: "test one" },
    { value: 2, label: "test two" },
  ];
  return (
    <div className="create-appointment-modal">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* detail note box start */}
      <div className="detail-note-box">
        <p
          onClick={() => setActiveBox("details")}
          className={`text ${activeBox === "details" && "text-active"}`}
        >
          <span>Details</span>
        </p>
        <p
          onClick={() => setActiveBox("note")}
          className={`text ${activeBox === "note" && "text-active"}`}
        >
          <span>Note</span>
        </p>
      </div>
      {/* detail note box end */}

      {/* formbox start */}
      <form action="" className="form">
        {activeBox === "details" && (
          <>
            <div className="group-one">
              {/* form group start */}
              <div className="form-group">
                <label htmlFor="Metrics" className="label">
                  Select Client
                </label>
                <Select
                  value={selectedOption}
                  onChange={(e) => setselectedOption(e)}
                  options={metricsDropDown}
                  // className="input-border"
                />
              </div>
              {/* form group end */}
              {/* form group start */}
              <div className="form-group">
                <label htmlFor="Metrics" className="label">
                  Select Appointment type
                </label>
                <Select
                  value={selectedOption}
                  onChange={(e) => setselectedOption(e)}
                  options={metricsDropDown}
                  // className="input-border"
                />
              </div>
              {/* form group end */}
            </div>
            <div className="group-two">
              {/* form group start */}
              <div className="form-group">
                <label htmlFor="Metrics" className="label">
                  Select Vet Doctor
                </label>
                <Select
                  value={selectedOption}
                  onChange={(e) => setselectedOption(e)}
                  options={metricsDropDown}
                  // className="input-border"
                />
              </div>
              {/* form group end */}
              {/* form group start */}
              <div className="form-group">
                <label htmlFor="Metrics" className="label">
                  Select Appointment Time
                </label>
                <div className="group-two">
                  <label htmlFor="from" className="label">
                    <figure className="img-box">
                      <img src={calendarIcon} alt="" className="img" />
                    </figure>
                  </label>
                  <Flatpicker
                    id="from"
                    name="from"
                    data-enable-time
                    //   cal
                    className="input-calender"
                    autoComplete="off"
                    value={fromDate}
                    onChange={(date) =>
                      setFromDate(moment(date[0]).format("YYYY/MM/DD"))
                    }
                    placeholder="DD / MM / YYYY"
                  />
                </div>
              </div>
              {/* form group end */}
            </div>
          </>
        )}

        {activeBox === "note" && (
          <>
            <div className="form-group form-group-text-area">
              <textarea
                style={{ resize: "none" }}
                className="input"
                placeholder="Give a detail note"
                name=""
                id=""
                cols="30"
                rows="20"
              ></textarea>
            </div>
          </>
        )}

        {/* submit button start */}
        <Button
          text={`Create Appointment`}
          btnStyle="btn-reuse"
          bgColor={`#84BFCD`}
          onClick={onCreateAppointment}
        />
        {/* submit button end */}
      </form>
      {/* formbox end */}
    </div>
  );
};

export default CreateAppointmentModal;
