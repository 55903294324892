import React, { useEffect, useState } from "react";
import ReuseableTable from "../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../component/dashboard/tables/TableRow";
import Modal from "react-awesome-modal";
import CommonHeader from "./CommonHeader";
import ResetPasswordModal from "./modal/ResetPasswordModal";
import SuccessResetPassword from "./modal/SuccessResetPassword";
import DeactivateModal from "./modal/DeactivateModal";
import SuccessDeactivateModal from "./modal/SuccessDeactivateModal";
import ActivateModal from "./modal/ActivateModal";
import SuccesActivateModal from "./modal/SuccesActivateModal";
import CommonProfileModal from "./modal/profile/CommonProfileModal";
import AddNewServiceProviderModal from "./modal/AddNewServiceProvider";
import SuccessAddServiceProvider from "./modal/SuccessAddServiceProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  // getServiceProvider,
  getServiceProviders,
  getServiceProvidersPagination,
} from "../../../redux/service-provider";
import { formatNumWithCommaNairaSymbol } from "../../../helpers/Helper";

// const list = [
//   {
//     name: "Kuramo Vets & Doctors",
//     level: "Clinic",
//     email: "+(234)-8156666666",
//     status: "Active",
//   },
//   {
//     name: "Kuramo Vets & Doctors",
//     level: "Clinic",
//     email: "+(234)-8156666666",
//     status: "Active",
//   },
//   {
//     name: "Kuramo Vets & Doctors",
//     level: "Clinic",
//     email: "+(234)-8156666666",
//     status: "Deactivated",
//   },
//   {
//     name: "Kuramo Vets & Doctors",
//     level: "Clinic",
//     email: "+(234)-8156666666",
//     status: "Active",
//   },
//   {
//     name: "Kuramo Vets & Doctors",
//     level: "Clinic",
//     email: "+(234)-8156666666",
//     status: "Deactivated",
//   },
//   {
//     name: "Kuramo Vets & Doctors",
//     level: "Clinic",
//     email: "+(234)-8156666666",
//     status: "Active",
//   },
// ];

const ServiceProvider = () => {
  const dispatch = useDispatch();
  const { service_providers, loading } = useSelector(
    (state) => state.service_provider
  );
  const [singleChild, setSingleChild] = useState({});
  // const [refreshTable, setRefreshTable] = useState(false);
  const [indexNo, setIndexNo] = useState("");
  const [showModal, setShowModal] = useState({
    reset_password: false,
    reset_password_success: false,
    deactivate: false,
    deactivate_success: false,
    activate: false,
    activate_success: false,
    view_profile: false,
    add_new_provider: false,
    add_new_provider_success: false,
  });

  useEffect(() => {
    dispatch(getServiceProviders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePagination = async (param) => {
    const linkSplit = param.split("api/");
    const link = linkSplit[1];
    dispatch(getServiceProvidersPagination(link));
  };

  const getOptionDrop = (per, total) => {
    // let endingValue = 10;
    const rangeValue = Math.ceil(Number(total) / Number(per));
    let array = [];

    for (let i = 1; i <= rangeValue; i++) {
      array.push(i);
    }

    const labelOption = array.map((chi) => {
      return { label: chi, value: chi };
    });
    // console.log(labelOption);
    return labelOption;
  };

  return (
    <>
      <div>
        <CommonHeader
          onBtnClick={() =>
            setShowModal((prev) => {
              return { ...prev, add_new_provider: true };
            })
          }
        />
        {/* table start */}
        <ReuseableTable
          //   loading={loading}

          sN
          one={"Name"}
          two={"Phone no"}
          three={`Email`}
          four={`Category`}
          five={"Wallet Balance"}
          six={"Status"}
          oneDrop
          twoDrop
          action
          moreThanFive={service_providers?.data?.length >= 3}
          toNo={service_providers?.meta?.to}
          totalNo={service_providers?.meta?.total}
          fromNo={service_providers?.meta?.from}
          nextPage={service_providers?.links?.next}
          prevPage={service_providers?.links?.prev}
          onNext={() => {
            handlePagination(service_providers?.links?.next);
          }}
          onPrev={() => {
            handlePagination(service_providers?.links?.prev);
          }}
          // selectValue={selectValue}
          onPageSort={(e) => {
            // setSelectValue(e);
            // handlePagination(e);
          }}
          sortOption={getOptionDrop(
            service_providers?.meta?.per_page,
            service_providers?.meta?.total
          )}
        >
          {service_providers?.data?.map((chi, idx) => {
            const { name, email, status } = chi;
            return (
              <TableRow
                loading={loading}
                sN={idx + 1}
                setIndexNo={() => setIndexNo(idx)}
                rowNo={idx}
                indexNo={indexNo}
                key={idx}
                five={
                  chi?.wallet_balance === 0
                    ? formatNumWithCommaNairaSymbol("0")
                    : formatNumWithCommaNairaSymbol(chi?.wallet_balance)
                }
                action
                one={name || ""}
                four={chi?.category || ""}
                two={chi?.mobile || <>&nbsp;</>}
                three={email}
                viewText={`View Profile`}
                onViewDetail={() => {
                  setShowModal((prev) => {
                    return { ...prev, view_profile: true };
                  });
                  setSingleChild(chi);
                  // console.log(chi);
                }}
                addText={status.toLowerCase() === "active" && "Reset Password"}
                onAdd={() =>
                  setShowModal((prev) => {
                    return { ...prev, reset_password: true };
                  })
                }
                deleteText={
                  status.toLowerCase() === "verified" && "Deactivate User"
                }
                onDelete={() => {
                  setShowModal((prev) => {
                    return { ...prev, deactivate: true };
                  });
                  setSingleChild(chi);
                }}
                listText={
                  status.toLowerCase() !== "verified" && "Reactivate User"
                }
                onList={() => {
                  setShowModal((prev) => {
                    return { ...prev, activate: true };
                  });
                  setSingleChild(chi);
                }}
                status={status === "verified" ? "verified" : "Deactivated"}
                statusColor={
                  status.toLowerCase() === "verified" ? "deliver" : "red"
                }
              />
            );
          })}
        </ReuseableTable>
        {/* table end */}
      </div>
      {/* reset password start */}
      <Modal effect="fadeInUp" visible={showModal.reset_password}>
        <ResetPasswordModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, reset_password: false };
            })
          }
          onReset={() =>
            setShowModal((prev) => {
              return {
                ...prev,
                reset_password: false,
                reset_password_success: true,
              };
            })
          }
        />
      </Modal>
      {/* reset password end */}
      {/* reset password sucess start */}
      <Modal effect="fadeInUp" visible={showModal.reset_password_success}>
        <SuccessResetPassword
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, reset_password_success: false };
            })
          }
        />
      </Modal>
      {/* reset password sucess end */}
      {/* deactivate  start */}
      <Modal effect="fadeInUp" visible={showModal.deactivate}>
        <DeactivateModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, deactivate: false };
            })
          }
          onDeactivate={() =>
            setShowModal((prev) => {
              return { ...prev, deactivate: false, deactivate_success: true };
            })
          }
          detail={singleChild}
        />
      </Modal>
      {/* deactivate  end */}
      {/* deactivate  success start */}
      <Modal effect="fadeInUp" visible={showModal.deactivate_success}>
        <SuccessDeactivateModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, deactivate_success: false };
            })
          }
        />
      </Modal>
      {/* deactivate success end */}
      {/* activate  start */}
      <Modal effect="fadeInUp" visible={showModal.activate}>
        <ActivateModal
          detail={singleChild}
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, activate: false };
            })
          }
          onReactivate={() =>
            setShowModal((prev) => {
              return { ...prev, activate: false, activate_success: true };
            })
          }
        />
      </Modal>
      {/* activate  end */}
      {/* activate success start */}
      <Modal effect="fadeInUp" visible={showModal.activate_success}>
        <SuccesActivateModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, activate_success: false };
            })
          }
        />
      </Modal>
      {/* activate success end */}
      {/* common profile modal start */}
      <Modal effect="fadeInUp" visible={showModal.view_profile}>
        <CommonProfileModal
          detail={singleChild}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, view_profile: false };
            });
            setSingleChild({});
          }}
        />
      </Modal>
      {/* common profile modal end */}
      {/* add new provider start */}
      <Modal visible={showModal.add_new_provider} effect="fadeInUp">
        <AddNewServiceProviderModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, add_new_provider: false };
            })
          }
          onCreate={() =>
            setShowModal((prev) => {
              return {
                ...prev,
                add_new_provider: false,
                add_new_provider_success: true,
              };
            })
          }
        />
      </Modal>
      {/* add new provider end */}
      {/* add new provider start */}
      <Modal visible={showModal.add_new_provider_success} effect="fadeInUp">
        <SuccessAddServiceProvider
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, add_new_provider_success: false };
            })
          }
        />
      </Modal>
      {/* add new provider end */}
    </>
  );
};

export default ServiceProvider;
