import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../utils/axios";
// import { encrypt, decrypt } from 'utils/encrypt';
import setAuthToken from "../utils/auth";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const loginUser = createAsyncThunk(
  "admin/loginUser",
  async (formData, thunkAPI) => {
    try {
      const payload = {
        email: formData?.email,
        password: formData?.password,
      };
      // console.log(payload);
      // return
      const { data } = await axios.post("/admin/login", payload);
      console.log('Login', data);
      if (!data.success) {
        // console.log(data.message);
        toast.error(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }

      await setAuthToken(data.token);
      await thunkAPI.dispatch(login(data));
      return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "admin/logout",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      const { data } = await axios.post("/admin/logout");
      console.log("Login", data);
      if (!data.success) {
        // console.log(data.message);
        toast.error(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }

      if (data.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }

      // await setAuthToken(data.token);
      // await thunkAPI.dispatch(login(data));
      return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const user = createSlice({
  name: "user",
  initialState: {
    user: JSON.parse(localStorage.getItem("user")) || { clearance: "support" },
    balances: [],
    isAuth: false,
    loading: false,
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setUser: (state, action) => {
      state.isAuth = true;
      state.user = action.payload;
      state.loading = false;
    },
    login: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      state.user = action.payload.user;
      state.isAuth = true;
      state.loading = false;
    },
    // logout: state => {
    //   delete axios.defaults.headers.common['Authorization'];
    //   localStorage.removeItem('token');
    //   state.user = null;
    //   state.isAuth = false;
    //   state.loading = false;
    //   state.balances = [];
    //   state = null;
    // }
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.loading = true;
    },
    [loginUser.fulfilled]: (state) => {
      state.loading = false;
    },
    [loginUser.rejected]: (state) => {
      localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [logoutUser.pending]: (state) => {
      state.loading = true;
    },
    [logoutUser.fulfilled]: (state) => {
      state.loading = false;
    },
    [logoutUser.rejected]: (state) => {
      localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, setUser } = user.actions;

export default user.reducer;
