import React from 'react'
import { FaTimes } from 'react-icons/fa'
import "../../../../../styles/dashboard/pet/modal/EditParentDetailModal.css"
import defaultImg from "../../../../../assets/parent-pet-profile-img.png";
import Select from "react-select";
import Button from '../../../../../component/dashboard/fragments/Button';

const EditParentDetailModal = ({onClose, onUpdate}) => {
  return (
    <div className='edit-parent-detail-modal-wrap'>
        <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* end of cancel */}
      {/* title start */}
      <p className="title">Edit Pet Parent Details</p>
      {/* title end */}
       {/*picture box start */}
       <div className="picture-box">
        <div
          style={{ backgroundImage: `url(${defaultImg})` }}
          className="img-show-wrap"
        ></div>
        {/* box start */}
        <div className="box">
          <p className="light">Created by </p>
          <p className="bold">Philo Isioma </p>
        </div>
        {/* box end */}
      </div>
      {/*picture box end */}

      {/* form start */}
      <form action="" className="form">
         {/* form group start */}
         <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Phone Number
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="text"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
           {/* form group start */}
         <div className="form-group">
            <label htmlFor="Metrics" className="label">
            Email address
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="text"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
          {/* butn submit start */}
          <Button onClick={onUpdate} text={`Save Changes`} bgColor="#84BFCD" btnStyle={`btn-reuse`} />
          {/* butn submit end */}
      </form>
      {/* form endd */}
    </div>
  )
}

export default EditParentDetailModal