import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const SuccessRescheduleModal = ({ onClose }) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
        success
        smallText={`The Client will be notified of the new time
          and date`}
        bigText={`Appointment Successfully Rescheduled`}
      >
        <Button bgColor={`#FFA72C`} text="Close" onClick={onClose} />
      </CommonModal>
    </>
  );
};

export default SuccessRescheduleModal;
