import React, { useEffect, useState } from "react";
import "../../../styles/dashboard/user-admin/UserAdmin.css";
import searchIcon from "../../../assets/search-icon.svg";
import bukIcon from "../../../assets/user-admin-btn-icon.svg";
import Button from "../../../component/dashboard/fragments/Button";
import ReuseableTable from "../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../component/dashboard/tables/TableRow";
import Modal from "react-awesome-modal";
import ResetPasswordModal from "./modal/ResetPasswordModal";
import SuccessResetPassword from "./modal/SuccessResetPassword";
import DeactivateModal from "./modal/DeactivateModal";
import SuccessDeactivateModal from "./modal/SuccessDeactivateModal";
import SuccesActivateModal from "./modal/SuccesActivateModal";
import ActivateModal from "./modal/ActivateModal";
import AddNewUserModal from "./modal/AddNewuserModal";
import AddUserSuccessModal from "./modal/AddUserSuccessModal";
// import ViewNewUserModal from "./modal/ViewUserModal";
import EditUserSucessModal from "./modal/EditUserSucessModal";
import EditNewUserModal from "./modal/EditNewUserModal";
import ViewUserModal from "./modal/ViewUserModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getPetParents,
  getPetParentsPagination,
} from "../../../redux/pets/petTypes";

const list = [
  {
    name: "Kokumo Blaqbonez",
    role: "Admin",
    last_login: "9 Hours ago",
    email: "blaqzku@gmail.com",
    status: "active",
  },
  {
    name: "Kokumo Blaqbonez",
    role: "verifier",
    last_login: "9 Hours ago",
    email: "blaqzku@gmail.com",
    status: "deactivated",
  },
  {
    name: "Kokumo Blaqbonez",
    role: "verifier",
    last_login: "9 Hours ago",
    email: "blaqzku@gmail.com",
    status: "deactivated",
  },
  {
    name: "Kokumo Blaqbonez",
    role: "Admin",
    last_login: "9 Hours ago",
    email: "blaqzku@gmail.com",
    status: "deactivated",
  },
  {
    name: "Kokumo Blaqbonez",
    role: "Admin",
    last_login: "9 Hours ago",
    email: "blaqzku@gmail.com",
    status: "deactivated",
  },
  {
    name: "Kokumo Blaqbonez",
    role: "Admin",
    last_login: "9 Hours ago",
    email: "blaqzku@gmail.com",
    status: "active",
  },
  {
    name: "Kokumo Blaqbonez",
    role: "Admin",
    last_login: "9 Hours ago",
    email: "blaqzku@gmail.com",
    status: "active",
  },
];

const UserAdmin = () => {
  const dispatch = useDispatch();
  const { petParents, loading } = useSelector((state) => state.pet_type);
  const [singleChild, setSingleChild] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const [indexNo, setIndexNo] = useState("");
  const [showModal, setShowModal] = useState({
    reset_password: false,
    reset_password_success: false,
    deactivate: false,
    deactivate_success: false,
    activate: false,
    activate_success: false,
    add_user: false,
    add_user_success: false,
    view_user: false,
    edit_user: false,
    edit_user_success: false,
  });

  useEffect(() => {
    dispatch(getPetParents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  const handlePagination = async (param) => {
    const linkSplit = param.split("api/");
    const link = linkSplit[1];
    dispatch(getPetParentsPagination(link));
  };

  return (
    <>
      <div className="user-admin-wrap-box">
        {/* common header start */}
        <div className="shop-common-header-wrap">
          {/* button search box start */}
          <div className="btn-search-box">
            <div className="form-group">
              <label htmlFor="search" className="label">
                <figure className="img-box">
                  <img src={searchIcon} alt="" className="img" />
                </figure>
              </label>
              <input
                type="text"
                id="search"
                className="input"
                placeholder="Search"
                //   onKeyUp={(e) => onSearch(e.target.value)}
              />
            </div>
            {/* <Button
              onClick={() =>
                setShowModal((prev) => {
                  return { ...prev, add_user: true };
                })
              }
              text={"Add New User"}
            >
              {" "}
              <figure className="img-box">
                <img src={bukIcon} alt="" className="img" />
              </figure>
            </Button> */}
          </div>
          {/* button search box end */}
        </div>
        {/* common header end */}
        {/* table start */}
        <ReuseableTable
          //   loading={loading}
          sN
          one={"Name"}
          two={"Role"}
          three={`Last Login`}
          four={`Email`}
          //   five="23"
          five={"Status"}
          oneDrop
          twoDrop
          action
          moreThanFive={petParents?.data?.length >= 3}
          toNo={petParents?.meta?.to}
        totalNo={petParents?.meta?.total}
        fromNo={petParents?.meta?.from}
        nextPage={petParents?.links?.next}
        prevPage={petParents?.links?.prev}
        onNext={() => {
          handlePagination(petParents?.links?.next);
        }}
        onPrev={() => {
          handlePagination(petParents?.links?.prev);
        }}
        // selectValue={selectValue}
        onPageSort={(e) => {
          // setSelectValue(e);
          // handlePagination(e);
        }}
        // sortOption={getOptionDrop(
        //   petParents?.meta?.per_page,
        //   petParents?.meta?.total
        // )}
          //   tableTwo
        >
          {petParents?.data?.map((chi, idx) => {
            const { name, role, last_login, email, status } = chi;
            return (
              <TableRow
                loading={loading}
                sN={idx + 1}
                setIndexNo={() => setIndexNo(idx)}
                rowNo={idx}
                indexNo={indexNo}
                key={idx}
                action
                one={name}
                two={role || <>&nbsp;</>}
                three={last_login || <>&nbsp;</>}
                four={email}
                status={status}
                statusColor={
                  status?.toLowerCase() === "verified" ? "deliver" : "red"
                }
                viewText="View Details"
                onViewDetail={() => {
                  setShowModal((prev) => {
                    return { ...prev, view_user: true };
                  });
                  setSingleChild(chi);
                }}
                // editText="Edit User Details"
                onEdit={() =>
                  setShowModal((prev) => {
                    return { ...prev, edit_user: true };
                  })
                }
                createText={
                  status?.toLowerCase() === "active" && `Reset Password`
                }
                onAdd={() =>
                  setShowModal((prev) => {
                    return { ...prev, reset_password: true };
                  })
                }
                deleteText={
                  status?.toLowerCase() === "active" && "Deactivate User"
                }
                onDelete={() =>
                  setShowModal((prev) => {
                    return { ...prev, deactivate: true };
                  })
                }
                listText={
                  status?.toLowerCase() === "deactivated" && "Reactivate User"
                }
                onList={() =>
                  setShowModal((prev) => {
                    return { ...prev, activate: true };
                  })
                }
              />
            );
          })}
        </ReuseableTable>
        {/* table end */}
      </div>
      {/* reset password start */}
      <Modal effect="fadeInUp" visible={showModal.reset_password}>
        <ResetPasswordModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, reset_password: false };
            })
          }
          onReset={() =>
            setShowModal((prev) => {
              return {
                ...prev,
                reset_password: false,
                reset_password_success: true,
              };
            })
          }
        />
      </Modal>
      {/* reset password end */}
      {/* reset password sucess start */}
      <Modal effect="fadeInUp" visible={showModal.reset_password_success}>
        <SuccessResetPassword
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, reset_password_success: false };
            })
          }
        />
      </Modal>
      {/* reset password sucess end */}
      {/* deactivate  start */}
      <Modal effect="fadeInUp" visible={showModal.deactivate}>
        <DeactivateModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, deactivate: false };
            })
          }
          onDeactivate={() =>
            setShowModal((prev) => {
              return { ...prev, deactivate: false, deactivate_success: true };
            })
          }
        />
      </Modal>
      {/* deactivate  end */}
      {/* deactivate  success start */}
      <Modal effect="fadeInUp" visible={showModal.deactivate_success}>
        <SuccessDeactivateModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, deactivate_success: false };
            })
          }
        />
      </Modal>
      {/* deactivate success end */}
      {/* activate  start */}
      <Modal effect="fadeInUp" visible={showModal.activate}>
        <ActivateModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, activate: false };
            })
          }
          onReactivate={() =>
            setShowModal((prev) => {
              return { ...prev, activate: false, activate_success: true };
            })
          }
        />
      </Modal>
      {/* activate  end */}
      {/* activate success start */}
      <Modal effect="fadeInUp" visible={showModal.activate_success}>
        <SuccesActivateModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, activate_success: false };
            })
          }
        />
      </Modal>
      {/* activate success end */}
      {/* add user modal start */}
      <Modal effect="fadeInUp" visible={showModal.add_user}>
        <AddNewUserModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, add_user: false };
            })
          }
          onCreate={() =>
            setShowModal((prev) => {
              return { ...prev, add_user: false, add_user_success: true };
            })
          }
        />
      </Modal>
      {/* add user modal end */}
      {/* view user modal start */}
      <Modal effect="fadeInUp" visible={showModal.view_user}>
        <ViewUserModal
          detail={singleChild}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, view_user: false };
            });
            setSingleChild({});
          }}
        />
      </Modal>
      {/* view user modal end */}
      {/* add user success modal start */}
      <Modal effect="fadeInUp" visible={showModal.add_user_success}>
        <AddUserSuccessModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, add_user_success: false };
            })
          }
        />
      </Modal>
      {/* add user success modal end */}
      {/* edit user  modal start */}
      <Modal effect="fadeInUp" visible={showModal.edit_user}>
        <EditNewUserModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, edit_user: false };
            })
          }
          onCreate={() =>
            setShowModal((prev) => {
              return { ...prev, edit_user: false, edit_user_success: true };
            })
          }
        />
      </Modal>
      {/* edit user  modal end */}
      {/* edit user success modal start */}
      <Modal effect="fadeInUp" visible={showModal.edit_user_success}>
        <EditUserSucessModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, edit_user_success: false };
            })
          }
        />
      </Modal>
      {/* edit user success modal end */}
    </>
  );
};

export default UserAdmin;
