import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const CreateSuccessModal = ({ onClose }) => {
  return (
    <>
      <CommonModal onClose={onClose} accept bigText={`Article has successfully been posted`}>
        <Button bgColor={`#00C013`} text="Close" onClick={onClose} />
      </CommonModal>
    </>
  );
};

export default CreateSuccessModal;
