import React from "react";
import { FaTimes } from "react-icons/fa";
import "../../../../styles/dashboard/pet/modal/DewormVaccinationDietModal.css";
import noContentImg from "../../../../assets/deworm-content-img.png";
import Button from "../../../../component/dashboard/fragments/Button";

const DietDetailModal = ({ onClose, detail }) => {
  const list = ["", ""];
  return (
    <div className="deworm-vaccination-diet-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* end oc cancel box */}
      {/* modal title start */}
      <p className="modal-title">Manage Deit</p>
      {/* modal title end */}
      {/* no content box start */}
      {detail?.diet_details?.length  < 1 && (
        <div className="no-content-box">
          <div className="wrap">
            <figure className="img-box">
              <img src={noContentImg} alt="" className="img" />
            </figure>
            <p className="text">This pet currently has no Diet schdule.</p>
          </div>
        </div>
      )}
      {/* no content box end */}

      {/* content box start */}
      {detail?.diet_details?.length > 0 && <div className="content-box">
        <div className="small-title">Click on any of the Schedules to edit</div>
        {/* row wrappwr start */}
        <div className="row-wrap">
          {/* row start */}
          {detail?.diet_details?.length > 0 &&
            detail?.diet_details?.map((chi, idx) => {
              const {date, food_name, time} = chi;
              return (
                <div key={idx} className="row">
                  <p className="num">{idx + 1}</p>
                  <p className="name">{food_name}</p>
                  <p className="day">{date}</p>
                  <p className="time">{time}</p>
                </div>
              );
            })}
          {/* row end */}
        </div>
        {/* row wrappwr end */}
      </div>}
      {/* content box  end */}

      {/* close button start */}
      <Button
        onClick={onClose}
        text={`Close`}
        bgColor="#84BFCD"
        btnStyle={`btn-reuse`}
      />
      {/* close btn end */}
    </div>
  );
};

export default DietDetailModal;
