import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const SuccessSettleTransactionModal = ({ onClose }) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
        success
        bigText={`Transaction ID #12345892 has been successfully Settled`}
      >
        <Button bgColor={"#FFA72C"} text="Close" onClick={onClose} />
      </CommonModal>
    </>
  );
};

export default SuccessSettleTransactionModal;
