import React from 'react'
import Button from '../../../../component/dashboard/fragments/Button'
import CommonModal from './CommonModal'

const SuccessCloseTicketModal = ({onClose}) => {
  return (
    <>
    <CommonModal
      onClose={onClose}
      success
      bigText={`Ticket #31562 has been successfully
      Closed `}
    >
      <Button bgColor={`#FFA72C`} text="Close" onClick={onClose} />
    </CommonModal>
  </>
  )
}

export default SuccessCloseTicketModal