import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import "../../../../styles/dashboard/shop/Modal.css";
import cameraIcon from "../../../../assets/camera-upload-label.svg";
import Select from "react-select";
import Button from "../../../../component/dashboard/fragments/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getShopCategories,
  getShopMetrics,
} from "../../../../redux/shop/ShopSettings";
import {
  formatNumWithComma,
  formatNumWithoutComma,
  reactSelectStyle,
} from "../../../../helpers/Helper";
import { createShopItem } from "../../../../redux/shop/ShopItems";
import useFileUpload from "react-use-file-upload";
import { toast } from "react-toastify";
// import ImageSpinner from "../../../../component/dashboard/fragments/ImageSpinner";
import { Dna } from "react-loader-spinner";

const AddShopItemModal = ({ onClose, onSubmit, loading }) => {
  const dispatch = useDispatch();
  const { categoriesDropDown, metricsDropDown } = useSelector(
    (state) => state.shop_settings
  );
  const {
    // files,
    setFiles,
    // removeFile,
  } = useFileUpload();
  const [selectedOption, setselectedOption] = useState(null);
  const [selectedOptionTwo, setselectedOptionTwo] = useState(null);
  const [details, setDetails] = useState({
    main_img: "",
    img_one: "",
    img_two: "",
    img_three: "",
    name: "",
    avaliable_stock: "",
    description: "",
    shop_category_id: "",
    shop_metric_id: "",
    cost: "",
    status: "Pending",
    owner: "",
    quantity: "",
  });
  const [imgToLoad, setImgToLoad] = useState("one");

  const handleSubmitFinish = () => {
    toast.success(`Shop item ${details?.name} added successfully`, {
      theme: "colored",
    });
    setDetails({
      main_img: "",
      img_one: "",
      img_two: "",
      img_three: "",
      name: "",
      avaliable_stock: "",
      description: "",
      shop_category_id: "",
      shop_metric_id: "",
      cost: "",
      status: "Pending",
      owner: "",
      quantity: "",
    });
    onSubmit();
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  useEffect(() => {
    dispatch(getShopMetrics());
    dispatch(getShopCategories());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpload = (img) => {
    // console.log(img);
    if (imgToLoad === "one") {
      // onImageEdit(img);
      setDetails((prev) => {
        return { ...prev, img_one: img, main_img: img };
      });
    }
    if (imgToLoad === "two") {
      setDetails((prev) => {
        return { ...prev, img_two: img, main_img: img };
      });
    }
    if (imgToLoad === "three") {
      setDetails((prev) => {
        return { ...prev, img_three: img, main_img: img };
      });
    }
  };

  //   const getUrlExtension = (url) => {
  //     return url
  //       .split(/[#?]/)[0]
  //       .split(".")
  //       .pop()
  //       .trim();
  //   }

  // const onImageEdit = async (imgUrl) => {
  //     var imgExt = getUrlExtension(imgUrl);

  //     const response = await fetch(imgUrl);
  //     const blob = await response.blob();
  //     const file = new File([blob], "profileImage." + imgExt, {
  //       type: blob.type,
  //     });
  //     setImgToLoads(file);
  // }

  const [imageToSubmit, setImageToSubmit] = useState({
    one: "",
    two: "",
    three: "",
  });
  // const [imageToSubmitOne, setImageToSubmitOne] = useState("")

  // const handleImagesToSave = (param) => {
  //   if(imageToSubmit?.length < 3){
  //     const newList = [param, ...imageToSubmit]
  //     setImageToSubmit(newList)
  //     console.log(newList);
  //   }
  //   if(imageToSubmit?.length >= 3){
  //     const newList = [param, ...imageToSubmit]
  //     const removeList = newList?.slice(0, -1);
  //     console.log(removeList);
  //     setImageToSubmit(removeList)
  //   }
  // }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Image = e.target.result;
        setImageToSubmit((prev) => {
          return { ...prev, [imgToLoad]: base64Image };
        });

        // console.log([...Object.values(imageToSubmit)]);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    const obj = {
      status: details?.status,
      images: [...Object.values(imageToSubmit)],
      shop_metric_id: selectedOption?.value,
      shop_category_id: selectedOptionTwo?.value,
      quantity: details.quantity ? Number(details?.quantity) : "",
      name: details?.name,
      description: details?.description,
      cost: details?.cost,
      avaliable_stock: details?.avaliable_stock,
    };
    // console.log(obj);
    // console.log(imageToSubmit);
    // return;
    const formData = new FormData();
    formData.append("status", obj.status === "Published" ? 1 : 0);
    formData.append("images[]", obj.images);
    formData.append("shop_metric_id", obj.shop_metric_id);
    formData.append("shop_category_id", obj.shop_category_id);
    formData.append("name", obj.name);
    formData.append("description", obj.description);
    formData.append("cost", obj.cost);
    formData.append("quantity", obj.quantity);
    formData.append("discount_percent", 2);
    //  console.log(formData);
    //  return;
    // let data;
    // for (const [key, value] of formData) {
    //    console.log(`${key}: ${value}\n`)
    //    data = value
    //    console.log(data);
    // }
    // for (const value of formData.values()) {
    //   console.log(value);
    // }
    //  return;
    const dataRes = await dispatch(createShopItem(formData));
    if (dataRes.payload.success) {
      handleSubmitFinish();
    }
  };

  // if (loading) {
  //   return <ImageSpinner />;
  // }

  return (
    <div className="view-shop-item-modal">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>

      {/* left box start */}
      <div style={{ justifyContent: "center" }} className="left-box">
        <div className="upload-wrap">
          <p className="title">Item Picture</p>
          <input
            type="file"
            name="upload"
            id="upload-add"
            accept="image/png, image/gif, image/jpeg"
            multiple
            className="input-upload"
            onChange={(e) => {
              setFiles(e, "a");
              handleImageChange(e);
              handleUpload(e.target.files[0]);
            }}
          />
          <label htmlFor="upload-add" className="label">
            {" "}
            <figure className="img-box">
              <img
                src={
                  details?.main_img
                    ? URL.createObjectURL(details?.main_img)
                    : "" || cameraIcon
                }
                alt=""
                className="img"
              />
            </figure>
          </label>
          <div className="switch-upload-wrap">
            <figure
              onClick={() => {
                setImgToLoad("one");
                setDetails((prev) => {
                  return { ...prev, main_img: details.img_one };
                });
              }}
              className={`img-box ${imgToLoad === "one" && "img-box-active"}`}
            >
              <img
                src={
                  details?.img_one
                    ? URL.createObjectURL(details?.img_one)
                    : "" || cameraIcon
                }
                alt=""
                className="img"
              />
            </figure>
            <figure
              onClick={() => {
                setImgToLoad("two");
                setDetails((prev) => {
                  return { ...prev, main_img: details.img_two };
                });
              }}
              className={`img-box ${imgToLoad === "two" && "img-box-active"}`}
            >
              <img
                src={
                  details?.img_two
                    ? URL.createObjectURL(details?.img_two)
                    : "" || cameraIcon
                }
                alt=""
                className="img"
              />
            </figure>
            <figure
              onClick={() => {
                setImgToLoad("three");
                setDetails((prev) => {
                  return { ...prev, main_img: details?.img_three };
                });
              }}
              className={`img-box ${imgToLoad === "three" && "img-box-active"}`}
            >
              <img
                src={
                  details?.img_three
                    ? URL.createObjectURL(details?.img_three)
                    : "" || cameraIcon
                }
                alt=""
                className="img"
              />
            </figure>
          </div>
        </div>
      </div>
      {/* left box end */}
      {/* right box start */}
      <div className="right-box right-box-edit-item">
        <div className="form-group">
          <label htmlFor="" className="label">
            Item Name
          </label>
          <input
            name="name"
            onChange={handleChange}
            placeholder=""
            type="text"
            className="input input-border"
          />
        </div>
        <div className="form-group">
          <label htmlFor="" className="label">
            Available Stock
          </label>
          <input
            name="avaliable_stock"
            onChange={handleChange}
            placeholder=""
            type="number"
            className="input input-border"
          />
        </div>
        {/* two groups start */}
        <div className="two-group-input">
          <div className="form-group">
            <label htmlFor="quantity" className="label">
              Size
            </label>
            <input
              type="number"
              className="input-border"
              name="quantity"
              id="quantity"
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Metrics
            </label>
            <Select
              styles={reactSelectStyle}
              value={selectedOption}
              onChange={(e) => setselectedOption(e)}
              options={metricsDropDown}
              //   className="input-border"
            />
          </div>
        </div>
        {/* two group end */}
        {/* two groups start */}
        <div className="two-group-input">
          <div className="form-group-two">
            <label htmlFor="Category" className="label">
              Category
            </label>
            <Select
              styles={reactSelectStyle}
              value={selectedOptionTwo}
              onChange={(e) => setselectedOptionTwo(e)}
              options={categoriesDropDown}
            />
          </div>
          <div className=" form-group-two">
            <p className="title-item">List Item</p>
            <div className="wrap">
              <div className="check-box">
                <input
                  type="radio"
                  name="list-item"
                  value={`Published`}
                  id="yes-check-add"
                  className="checkbox-list"
                  checked={details.status === "Published"}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, status: e.target.value };
                    });
                  }}
                />
                <label
                  style={{ marginRight: "1.5rem" }}
                  htmlFor="yes-check-add"
                  className="label"
                >
                  <span></span> Yes
                </label>
              </div>
              <div className="check-box">
                <input
                  type="radio"
                  name="list-item"
                  id="no-check-add"
                  className="checkbox-list"
                  value={`Pending`}
                  checked={details.status === "Pending"}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, status: e.target.value };
                    });
                  }}
                />
                <label htmlFor="no-check-add" className="label">
                  <span></span> No
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* two group end */}
        <div className="form-group form-group-unit-cost">
          <p className="title-text">Unit Cost</p>
          <div className="wrap">
            <label htmlFor="" className="label">
              NGN
            </label>
            <input
              value={formatNumWithComma(details.cost)}
              onChange={(e) =>
                setDetails((prev) => {
                  return {
                    ...prev,
                    cost: formatNumWithoutComma(e.target.value),
                  };
                })
              }
              type="text"
              className=""
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="" className="label">
            Item Description
          </label>
          <textarea
            name="description"
            id=""
            cols="30"
            rows="10"
            className="input input-border-describtion"
            onChange={handleChange}
          ></textarea>
        </div>
        {!loading ? (
          <Button
            onClick={() => {
              details?.name &&
                details?.description &&
                details?.quantity &&
                details?.cost &&
                details?.main_img &&
                handleSubmit();
            }}
            text={`Add Item`}
            bgColor={
              details?.name &&
              details?.description &&
              details?.quantity &&
              details?.cost &&
              details?.main_img
                ? `#FFA72C`
                : `#bbbbbb`
            }
          />
        ) : (
          <div className="dna-wrapper-box" style={{ alignSelf: "center" }}>
            <Dna
              visible={true}
              height="80"
              width="100"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
      </div>
      {/* right box end */}
    </div>
  );
};

export default AddShopItemModal;
