import React from 'react'
import Button from '../../../../component/dashboard/fragments/Button'
import CommonModal from './CommonModal'

const UpdatePetDetailSuccessModal = ({onClose,}) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
        success
        // smallText={`The Pet Parent has been notified via mail`}
        bigText={`Pet Details  Successfully Updated`}
      >
        <Button bgColor={`#FFA72C`} text="Close" onClick={onClose} />
      </CommonModal>
    </>
  )
}

export default UpdatePetDetailSuccessModal