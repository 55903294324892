import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const ResetPasswordModal = ({ onClose, onReset }) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
        resetPas
        //   smallText={`The Pet Parent has been notified via mail`}
        bigText={"Are you sure you want to reset this user`s password ?"}
      >
        <Button bgColor={`#00C013`} text="Reset" onClick={onReset} />
      </CommonModal>
    </>
  );
};

export default ResetPasswordModal;
