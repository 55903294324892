import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../component/dashboard/layout";
import CommonHeader from "./CommonHeader";
import "../../../styles/dashboard/shop/ShopSetting.css";
import { FaPlus } from "react-icons/fa";
import ItemInList from "./ItemInList";
import Modal from "react-awesome-modal";
import { useSelector, useDispatch } from "react-redux";
import {
  createShopCategory,
  createShopMetric,
  deleteShopCategory,
  deleteShopMetric,
  getShopCategories,
  getShopMetrics,
} from "../../../redux/shop/ShopSettings";
import { toast } from "react-toastify";
import DeleteShopSettingModal from "./modals/DeleteShopSettingItem";

const ShopSettings = () => {
  const dispatch = useDispatch();
  const { shop_metrics, shop_categories, loading } = useSelector(
    (state) => state.shop_settings
  );
  // const [metricsList, setMetricsList] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [showMetricsAdd, setShowMetricsAdd] = useState(false);
  const [metricsActiveInput, setMetricsActiveInput] = useState(false);
  const [productCategoryList, setproductCategoryList] = useState([]);
  const [showproductCategoryAdd, setShowproductCategoryAdd] = useState(false);
  const [productCategoryActiveInput, setproductCategoryActiveInput] =
    useState(false);
  const [showModal, setShowModal] = useState({
    metric: false,
    category: false,
    detail: {},
  });

  const handleAddToMetricsList = async (e) => {
    const obj = {
      name: e.target.value,
    };
    if (e.key === "Enter" && e.target.value) {
      if (
        !shop_metrics.some(
          (vendor) =>
            vendor["name"].toLowerCase() === e.target.value.toLowerCase()
        )
      ) {
        const data = await dispatch(createShopMetric(obj));
        if (data?.payload?.success) {
          setReloadData(!reloadData);
          setMetricsActiveInput(false);
        }
      } else {
        toast.info(" Shop metrics already exist");
      }
    }
  };

  const handleRemoveMetrics = async () => {
    // console.log(showModal.detail.id);
    const payload = {
      id: showModal.detail.id,
    };
    const data = await dispatch(deleteShopMetric(payload));
    if (data?.payload?.success) {
      toast.success(
        `Shop metric ${showModal.detail.name}  deleted successfully`,
        {
          theme: "colored",
        }
      );
      setShowModal((prev) => {
        return { ...prev, metric: false, detail: {} };
      });
      setReloadData(!reloadData);
    }
  };

  const handleAddToProductCategoryList = async (e) => {
    const obj = {
      name: e.target.value,
      // id: id,
    };
    if (e.key === "Enter" && e.target.value) {
      if (
        !shop_categories.some(
          (vendor) =>
            vendor["name"].toLowerCase() === e.target.value.toLowerCase()
        )
      ) {
        const data = await dispatch(createShopCategory(obj));
        if (data?.payload?.success) {
          setReloadData(!reloadData);
          setproductCategoryActiveInput(false);
        }
      } else {
        toast.info(" Shop product category already exist");
      }
    }
  };

  const handleRemoveCategory = async () => {
    // console.log(showModal.detail.id);
    const payload = {
      id: showModal.detail.id,
    };
    const data = await dispatch(deleteShopCategory(payload));
    if (data?.payload?.success) {
      toast.success(
        `Shop category ${showModal.detail.name}  deleted successfully`,
        {
          theme: "colored",
        }
      );
      setShowModal((prev) => {
        return { ...prev, category: false, detail: {} };
      });
      setReloadData(!reloadData);
    }
  };

  useEffect(() => {
    dispatch(getShopMetrics());
    dispatch(getShopCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  return (
    <>
      <div>
        {/* <DashboardLayout
        pageTitle={`Shop`}
        pageSmallText={`Review and Manage all sales and shop listing on Petme`}
      > */}
      {/* common headr start */}
      <CommonHeader />
      {/* common header end */}
      {/* content box start */}
      <div className="content-box-shop-settings">
        {/* metrics box start */}
        <div className="metrics-box">
          <p className="title">Metrics</p>
          <div
            className="detail-box"
            onClick={() => {
              metricsActiveInput && setMetricsActiveInput(false);
              setShowMetricsAdd(true);
            }}
          >
            {shop_metrics?.length < 1 &&
              !metricsActiveInput &&
              !showMetricsAdd && (
                <p className="info">
                  Enter metrics that can be applied to shop items
                </p>
              )}
            {shop_metrics?.map((chi, idx) => {
              const { name, id } = chi;
              return (
                <ItemInList
                  loading={loading}
                  onRemove={() => {
                    setShowModal((prev) => {
                      return { ...prev, metric: true, detail: chi };
                    });
                  }}
                  key={id || idx}
                  text={name}
                />
              );
            })}
            {/* add box button start */}
            <div
              className={`input-btn-box ${
                metricsActiveInput && "input-btn-box-active"
              }`}
            >
              {metricsActiveInput && (
                <input
                  onKeyPress={handleAddToMetricsList}
                  autoFocus={metricsActiveInput}
                  type="text"
                  className="metrics-input"
                />
              )}
              {!metricsActiveInput && showMetricsAdd && (
                <FaPlus
                  onClick={() => setMetricsActiveInput(true)}
                  className="icon"
                />
              )}
            </div>
            {/* add box button end */}
          </div>
          {/* detail box end */}
        </div>
        {/* metrics box end */}
        {/* product category box start */}
        <div style={{ marginTop: "4rem" }} className="metrics-box">
          <p className="title">Product Category</p>
          <div
            className="detail-box"
            onClick={() => setShowproductCategoryAdd(true)}
          >
            {shop_categories?.length < 1 &&
              !productCategoryActiveInput &&
              !showproductCategoryAdd && (
                <p className="info">Create & edit product categories</p>
              )}
            {shop_categories?.map((chi, idx) => {
              const { name, id } = chi;
              return (
                <ItemInList
                  loading={loading}
                  onRemove={() => {
                    setShowModal((prev) => {
                      return { ...prev, category: true, detail: chi };
                    });
                  }}
                  key={id || idx}
                  text={name}
                />
              );
            })}
            {/* add box button start */}
            <div
              className={`input-btn-box ${
                productCategoryActiveInput && "input-btn-box-active"
              }`}
            >
              {productCategoryActiveInput && (
                <input
                  onKeyPress={handleAddToProductCategoryList}
                  autoFocus={productCategoryActiveInput}
                  type="text"
                  className="metrics-input"
                />
              )}
              {!productCategoryActiveInput && showproductCategoryAdd && (
                <FaPlus
                  onClick={() => setproductCategoryActiveInput(true)}
                  className="icon"
                />
              )}
            </div>
            {/* add box button end */}
          </div>
          {/* detail box end */}
        </div>
        {/* product category box end */}
      </div>
      {/* content box end */}
      {/* </DashboardLayout> */}
      </div>
      {/* metric delete modal start */}
      <Modal visible={showModal.metric} effect="fadeInUp">
        <DeleteShopSettingModal
          onDelete={handleRemoveMetrics}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, metric: false, detail: {} };
            });
          }}
          type="metric"
          name={showModal?.detail?.name}
        />
      </Modal>
      {/* metric delete modal end */}
      {/* category delete modal start */}
      <Modal visible={showModal.category} effect="fadeInUp">
        <DeleteShopSettingModal
          onDelete={handleRemoveCategory}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, category: false, detail: {} };
            });
          }}
          type="category"
          name={showModal?.detail?.name}
        />
      </Modal>
      {/* category delete modal end */}
    </>
  );
};

export default ShopSettings;
