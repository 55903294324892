import React from "react";
import { useState } from "react";
import ReuseableTable from "../../../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../../../component/dashboard/tables/TableRow";
import Flatpicker from "react-flatpickr";
import "flatpickr/dist/themes/confetti.css";
import moment from "moment";
import calendarIcon from "../../../../../assets/calendar-icon.svg";
import Button from "../../../../../component/dashboard/fragments/Button";

const AppointmentDetail = ({detail}) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [showHistoryTable, setShowHistoryTable] = useState(false);
  const list = [
    { pet: "Enzo - Cat ", type: "Medical", date: "Jul 28 - 10:00am" },
    { pet: "Enzo - Cat ", type: "Medical", date: "Jul 28 - 10:00am" },
    {
      pet: "Enzo - Cat ",
      type: "Medical",
      date: "Jul 28 - 10:00am",
    },
  ];

  const [switchBox, setSwitchBox] = useState("new-appointment");
  return (
    <div className="appointment-box">
      {/* switch box start */}
      <div className="switch-box">
        <p
          className={`switch ${
            switchBox === "new-appointment" && "switch-active"
          }`}
          onClick={() => setSwitchBox("new-appointment")}
        >
          <span>New Appointments (3)</span>
        </p>
        <p
          onClick={() => setSwitchBox("history")}
          className={`switch ${switchBox === "history" && "switch-active"}`}
        >
          <span>Appointment History</span>
        </p>
      </div>
      {/* switch box end */}
      {/* appointment box start */}
      <>
        {switchBox === "new-appointment" && (
          <ReuseableTable
            //    loading={loading}
            sN
            one={`Pet `}
            two={`Type`}
            three={`Date`}
            //   oneDrop
            twoDrop
            threeDrop
          >
            {detail?.Appointments?.map((chi, idx) => {
              const { pet, type, date } = chi;
              return (
                <TableRow
                  key={idx}
                  sN={idx + 1}
                  one={pet}
                  two={type}
                  three={date}
                />
              );
            })}
          </ReuseableTable>
        )}
      </>
      {/* appoinment box end */}
      {/* history start */}
      {switchBox === "history" && (
        <div className="history-box">
          {!showHistoryTable && (
            <p className="subtitle">
              Select a Date range to fetch appointment records for
            </p>
          )}

          {/* select history box start */}
          {/* calendar box start */}
          {!showHistoryTable && (
            <div className="calender-box">
              <p className="text">from</p>
              <div className="form-group">
                <label htmlFor="from" className="label">
                  <figure className="img-box">
                    <img src={calendarIcon} alt="" className="img" />
                  </figure>
                </label>
                <Flatpicker
                  id="from"
                  name="from"
                  data-enable-time
                  //   cal
                  className="input-calender"
                  autoComplete="off"
                  value={fromDate}
                  onChange={(date) =>
                    setFromDate(moment(date[0]).format("YYYY/MM/DD"))
                  }
                  placeholder="DD / MM / YYYY"
                />
              </div>
              <p style={{ marginLeft: "2rem" }} className="text">
                to
              </p>
              <div className="form-group">
                <label htmlFor="to" className="label">
                  <figure className="img-box">
                    <img src={calendarIcon} alt="" className="img" />
                  </figure>
                </label>
                <Flatpicker
                  data-enable-time
                  id="to"
                  name="to"
                  //   cal
                  className="input-calender"
                  autoComplete="off"
                  value={toDate}
                  onChange={(date) =>
                    settoDate(moment(date[0]).format("YYYY/MM/DD"))
                  }
                  placeholder="DD / MM / YYYY"
                />
              </div>
            </div>
          )}
           {/* btn start */}
           {!showHistoryTable && (
                <Button
                  btnStyle={`btn-reuse`}
                  text="Fetch Records"
                  bgColor={`#84BFCD`}
                  onClick={() => setShowHistoryTable(true)}
                />
              )}
              {/* btn end */}
          {/* calendar box end */}
          {/* table history start */}
          {showHistoryTable && (
            <>
              {/* reset text box start */}
              <div className="reset-text-box">
                <p className="text">
                  Displaying <span> 4 </span> results from{" "}
                  <span>23/11/2021</span> to <span>01/01/2022</span>
                </p>
                <p onClick={() => setShowHistoryTable(false)} className="reset">
                  Reset
                </p>
              </div>
              {/* reset text box end */}
              <ReuseableTable
                //    loading={loading}
                sN
                one={`Pet `}
                two={`Type`}
                three={`Date`}
                //   oneDrop
                twoDrop
                threeDrop
              >
                {detail?.Appointments?.map((chi, idx) => {
                  const { pet, type, date } = chi;
                  return (
                    <TableRow
                      key={idx}
                      sN={idx + 1}
                      one={pet}
                      two={type}
                      three={date}
                    />
                  );
                })}
              </ReuseableTable>

             
            </>
          )}
          {/* table history end */}
          {/* select historu box end */}
        </div>
      )}
      {/* history end */}
    </div>
  );
};

export default AppointmentDetail;
