import moment from "moment";
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import Button from "../../../../component/dashboard/fragments/Button";
import "../../../../styles/dashboard/appointment/ViewAppointmentModal.css";

const headerList = ["Details", "Note"];

const ViewAppointmentModal = ({ onClose, detail }) => {
  const [activeBox, setActiveBox] = useState("Details");

  return (
    <div className="view-appointment-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* content box start */}
      <div className="content-box">
        <p className="content-title">Appointment Details</p>
        {/* selct box start */}
        <div className="select-content-box">
          {headerList.map((chi, idx) => {
            return (
              <p
                key={idx}
                onClick={() => setActiveBox(chi)}
                className={`item ${activeBox === chi && "item-active"}`}
              >
                <span>{chi}</span>
              </p>
            );
          })}
        </div>
        {/* selct box end */}
        {/* detail content start */}
        {activeBox === "Details" && (
          <div className="detail-box">
            {/* top box start */}
            <div className="top-box">
              <div className="four-group">
                {/* box start */}
                <div className="box">
                  <p className="light">Pet Name</p>
                  <p style={{ textTransform: "capitalize" }} className="bold">
                    {detail?.pets?.length > 0
                      ? detail?.pets[0]?.pet_name || "---"
                      : "---"}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="light">Pet Type</p>
                  <p className="bold">
                    {detail?.pets?.length > 0
                      ? detail?.pets[0]?.pet_type || "---"
                      : "---"}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="light">Breed</p>
                  <p className="bold">{"---"}</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="light">Sex</p>
                  <p className="bold">{"---"}</p>
                </div>
                {/* box end */}
              </div>
              <div className="three-group">
                {/* box start */}
                <div className="box">
                  <p className="light">Parent Name</p>
                  <p className="bold">{detail?.pet_owner || "---"}</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="light">Phone Number</p>
                  <p className="bold">{"---"}</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="light">Email</p>
                  <p className="bold">{detail?.pet_owner_email || "---"}</p>
                </div>
                {/* box end */}
              </div>

              {/* box start */}
              <div className="box">
                <p className="light">Address</p>
                <p className="bold">{detail?.pet_owner_address || "---"}</p>
              </div>
              {/* box end */}
            </div>
            {/* top box end */}
            {/* bottom box start */}
            <div className="bottom-box">
              <div className="three-group">
                {/* box start */}
                <div className="box">
                  <p className="light">Appointment type</p>
                  <p className="bold">{"---"}</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="light">Appointment Time</p>
                  <p className="bold">
                    {moment(detail?.appointment_time)?.format(
                      "YYYY - MM - DD hh:mm a"
                    ) || "---"}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="light">Location</p>
                  <p className="bold">{"---"}</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="light">Assigned Vet Name</p>
                  <p className="bold">
                    {detail?.service_provider_name || "---"}
                  </p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="light">Assigned Vet Number</p>
                  <p className="bold"> {detail?.service_provider_mobile || "---"}</p>
                </div>
                {/* box end */}
                <Button text={`Free`} btnStyle="btn-reuse" />
              </div>
            </div>
            {/* bottom box end */}
          </div>
        )}

        {/* detail content end */}
        {/* note start */}
        {activeBox === "Note" && (
          <div className="note-detail-box">
            {/* form group start */}
            <div className="form-group">
              <div className="input-wrap">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  disabled
                  rows="20"
                  value={detail?.note}
                  className="input"
                  style={{ resize: "none" }}
                ></textarea>
              </div>
            </div>
            {/* form group end */}
          </div>
        )}
        {/* note end */}
      </div>
      {/* content box end */}
    </div>
  );
};

export default ViewAppointmentModal;
