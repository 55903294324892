import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import "../../../../styles/dashboard/shop/Modal.css";
import cameraIcon from "../../../../assets/camera-upload-label.svg";
import Select from "react-select";
import Button from "../../../../component/dashboard/fragments/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateShopItem } from "../../../../redux/shop/ShopItems";
import {
  formatNumWithComma,
  formatNumWithoutComma,
  reactSelectStyle,
  reactSelectStyleChart,
} from "../../../../helpers/Helper";
import useFileUpload from "react-use-file-upload";
import {
  getShopCategories,
  getShopMetrics,
} from "../../../../redux/shop/ShopSettings";
import { toast } from "react-toastify";
import ImageSpinner from "../../../../component/dashboard/fragments/ImageSpinner";

const EditShopItemModal = ({
  onClose,
  onSubmit,
  shop_item,
  onEditSingleItem,
  loading,
}) => {
  const dispatch = useDispatch();
  const { categoriesDropDown, metricsDropDown } = useSelector(
    (state) => state.shop_settings
  );

  const {
    files,
    setFiles,
    // removeFile,
  } = useFileUpload();
  const [selectedOption, setselectedOption] = useState(null);
  const [selectedOptionTwo, setselectedOptionTwo] = useState(null);
  //   const [rating, setRating] = useState(0); //
  const [details, setDetails] = useState({
    main_img: "",
    img_one: "",
    img_two: "",
    img_three: "",
    name: "",
    avaliable_stock: "",
    description: "",
    shop_category_id: "",
    shop_metric_id: "",
    cost: "",
    status: "",
    owner: "",
    quantity: "",
  });
  const [imgToLoad, setImgToLoad] = useState("one");

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    onEditSingleItem(name, value);
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleUpload = (img) => {
    if (imgToLoad === "one") {
      console.log(img);
      setDetails((prev) => {
        return { ...prev, img_one: img, main_img: img };
      });
    }
    if (imgToLoad === "two") {
      setDetails((prev) => {
        return { ...prev, img_two: img, main_img: img };
      });
    }
    if (imgToLoad === "three") {
      setDetails((prev) => {
        return { ...prev, img_three: img, main_img: img };
      });
    }
  };

  const handleSubmitFinish = () => {
    toast.success(`Shop item ${details?.name} updated successfully`, {
      theme: "colored",
    });
    setDetails({
      main_img: "",
      img_one: "",
      img_two: "",
      img_three: "",
      name: "",
      avaliable_stock: "",
      description: "",
      shop_category_id: "",
      shop_metric_id: "",
      cost: "",
      status: "Pending",
      owner: "",
      quantity: "",
    });
    onSubmit();
  };

  const handleUpdate = async () => {
    const obj = {
      status: details?.status || shop_item?.status,
      images: details?.img_one || shop_item?.images,
      shop_metric_id: selectedOption?.value || shop_item?.shop_metric_id,
      shop_category_id: selectedOptionTwo?.value || shop_item?.shop_category_id,
      quantity: details.quantity
        ? Number(details?.quantity)
        : "" || shop_item?.quantity,
      name: details?.name || shop_item?.name,
      description: details?.description || shop_item?.description,
      cost: details?.cost
        ? formatNumWithoutComma(details?.cost)
        : "" || shop_item?.cost,
      avaliable_stock: details?.avaliable_stock || shop_item?.avaliable_stock,
    };
    // console.log(obj);
    const formData = new FormData();
    formData.append("status", obj.status);
    formData.append("images", obj.images);
    formData.append("shop_metric_id", obj.shop_metric_id);
    formData.append("shop_category_id", obj.shop_category_id);
    formData.append("name", obj.name);
    formData.append("description", obj.description);
    formData.append("cost", obj.cost);
    formData.append("quantity", obj.quantity);

    const dataRes = await dispatch(updateShopItem(formData));
    if (dataRes.payload.success) {
      handleSubmitFinish();
    }
  };

  useEffect(() => {
    dispatch(getShopMetrics());
    dispatch(getShopCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  if (loading) {
    return <ImageSpinner />;
  }

  return (
    <div className="view-shop-item-modal">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>

      {/* left box start */}
      <div style={{ justifyContent: "center" }} className="left-box">
        <div className="upload-wrap">
          <p className="title">Item Picture</p>
          <input
            type="file"
            name="upload"
            id="upload-edit"
            accept="image/png, image/gif, image/jpeg"
            multiple
            className="input-upload"
            onChange={(e) => {
              setFiles(e, "a");
              handleUpload(URL.createObjectURL(e.target.files[0]));
              onEditSingleItem("image", URL.createObjectURL(e.target.files[0]))
              
            }}
          />
          <label htmlFor="upload-edit" className="label">
            {" "}
            <figure className="img-box">
              <img
                src={
                  details?.main_img ? details?.main_img :
                  shop_item?.images
                    ? `${shop_item?.images[0]?.image}`
                    : "" || cameraIcon
                }
                alt=""
                className="img"
              />
            </figure>
          </label>
          <div className="switch-upload-wrap">
            <figure
              onClick={() => {
                setImgToLoad("one");
                setDetails((prev) => {
                  return { ...prev, main_img: shop_item?.images[0]?.image };
                });
                
              }}
              className={`img-box ${imgToLoad === "one" && "img-box-active"}`}
            >
              <img
                src={
                  shop_item?.images
                    ? `${shop_item?.images[0]?.image}`
                    : "" || cameraIcon
                }
                alt=""
                className="img"
              />
            </figure>
            <figure
              onClick={() => {
                setImgToLoad("two");
                setDetails((prev) => {
                  return { ...prev, main_img: shop_item?.images[1]?.image };
                });
              }}
              className={`img-box ${imgToLoad === "two" && "img-box-active"}`}
            >
              <img
                src={
                  shop_item?.images?.length > 1
                    ? `${shop_item?.images[1]?.image}`
                    : cameraIcon || cameraIcon
                }
                alt=""
                className="img"
              />
            </figure>
            <figure
              onClick={() => {
                setImgToLoad("three");
                setDetails((prev) => {
                  return { ...prev, main_img: shop_item?.images[2]?.image };
                });
              }}
              className={`img-box ${imgToLoad === "three" && "img-box-active"}`}
            >
              <img
                src={
                  shop_item?.images?.length > 1
                    ? `${shop_item?.images[2]?.image}`
                    : cameraIcon || cameraIcon
                }
                alt=""
                className="img"
              />
            </figure>
          </div>
        </div>
      </div>
      {/* left box end */}
      {/* right box start */}
      <div className="right-box right-box-edit-item">
        <div className="form-group">
          <label htmlFor="" className="label">
            Item Name
          </label>
          <input
            name="name"
            onChange={handleChange}
            placeholder={``}
            type="text"
            className="input input-border"
            value={shop_item?.name}
          />
        </div>
        <div className="form-group">
          <label htmlFor="" className="label">
            Available Stock
          </label>
          <input
            name="avaliable_stock"
            onChange={handleChange}
            placeholder=""
            type="number"
            className="input input-border"
            value={shop_item?.avaliable_stock}
          />
        </div>
        {/* two groups start */}
        <div className="two-group-input">
          <div className="form-group">
            <label htmlFor="quantity" className="label">
              Quantity
            </label>
            <input
              type="number"
              className="input-border"
              name="quantity"
              id="quantity"
              onChange={handleChange}
              value={shop_item?.quantity}
            />
          </div>
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Metrics
            </label>
            <Select
              name="shop_metric_id"
              value={shop_item?.metric}
              styles={reactSelectStyle}
              onChange={(e) => {
                onEditSingleItem("metric", e);
                setselectedOption(e);
              }}
              options={metricsDropDown}
              // value='kkk'
            />
          </div>
        </div>
        {/* two group end */}
        {/* two groups start */}
        <div className="two-group-input">
          <div className="form-group-two">
            <label htmlFor="Category" className="label">
              Category
            </label>
            <Select
              value={shop_item?.category}
              styles={reactSelectStyle}
              onChange={(e) => {
                onEditSingleItem("category", e);
                setselectedOptionTwo(e);
              }}
              options={categoriesDropDown}
            />
          </div>
          <div className=" form-group-two">
            <p className="title-item">List Item</p>
            <div className="wrap">
              <div className="check-box">
                <input
                  type="radio"
                  name="list-item-edit"
                  id="yes-check"
                  className="checkbox-list"
                  value={`Published`}
                  checked={
                    shop_item?.status === "published" ||
                    shop_item?.status !== "pending"
                  }
                  onChange={(e) => {
                    onEditSingleItem("status", e.target.value);
                    setDetails((prev) => {
                      return { ...prev, status: e.target.value };
                    });
                  }}
                />
                <label
                  style={{ marginRight: "1.5rem" }}
                  htmlFor="yes-check"
                  className="label"
                >
                  <span></span> Yes
                </label>
              </div>
              <div className="check-box">
                <input
                  type="radio"
                  name="list-item-edit"
                  value={`Pending`}
                  id="no-check"
                  className="checkbox-list"
                  checked={shop_item?.status === "pending"}
                  onChange={(e) => {
                    onEditSingleItem("status", e.target.value);
                    setDetails((prev) => {
                      return { ...prev, status: e.target.value };
                    });
                  }}
                />
                <label htmlFor="no-check" className="label">
                  <span></span> No
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* two group end */}
        <div className="form-group form-group-unit-cost">
          <p className="title-text">Unit Cost</p>
          <div className="wrap">
            <label htmlFor="" className="label">
              NGN
            </label>
            <input
              name="cost"
              value={shop_item?.cost && formatNumWithComma(shop_item?.cost)}
              onChange={(e) => {
                handleChange(e);
                onEditSingleItem("cost", formatNumWithoutComma(e.target.value));
              }}
              type="text"
              className=""
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="" className="label">
            Item Description
          </label>
          <textarea
            name="description"
            id=""
            cols="30"
            rows="10"
            className="input input-border-describtion"
            onChange={handleChange}
            value={shop_item?.description}
          ></textarea>
        </div>
        <Button onClick={handleUpdate} text={`Add Item`} bgColor={`#FFA72C`} />
      </div>
      {/* right box end */}
    </div>
  );
};

export default EditShopItemModal;
