import React from "react";
import { FaTimes } from "react-icons/fa";
import "../../../../styles/dashboard/support/ViewTicketModal.css";
import InputWrap from "../chat/InputWrap";

const ViewTicketModal = ({ onClose }) => {
  return (
    <div className="view-ticket-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* close end */}
      <p className="title">Support Ticket #31562 </p>
      {/* bottom box start */}
      <div className="bottom-box">
        {/* left box start */}
        <div className="left-box">
          {/* box start */}
          <div className="box">
            <p className="light">Name</p>
            <p className="bold">Kuramo Vets & Doctors</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Phone Number</p>
            <p className="bold">Kuramo Vets & Doctors</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Email</p>
            <p className="bold">Chuks@kukuhealth.com</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Date Requested</p>
            <p className="bold">July 21 2022 - 10:00am</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Ticket Category</p>
            <p className="bold">App Bug</p>
          </div>
          {/* box end */}
          {/* ticket type box start */}
          <div className={`ticket-type-box ticket-type-box-green ticket-type-box-yellow`}>
            <p className="ticket">Open Ticket</p>
          </div>
          {/* ticket type box end */}
        </div>
        {/* left box end */}
        {/* right box start */}
        <div className="right-box">
            <div className="top-content-box">
            <div className="content"></div>
            </div>
            {/* end of top content */}
            <div className="bottom-content-box">
              <InputWrap />
            </div>
            {/* end of botom content */}
        </div>
        {/* right box end */}
      </div>
      {/* bottom box end */}
    </div>
  );
};

export default ViewTicketModal;
