import React, { useState } from "react";
import ReuseableTable from "../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../component/dashboard/tables/TableRow";
import CommonHeader from "./CommonHeader";
import Modal from "react-awesome-modal";
import ViewTicketModal from "./modal/ViewTicketModal";
import SuccessCloseTicketModal from "./modal/SuccessCloseTicketModal";
import CloseTicketModal from "./modal/CloseTicketModal";

const list = [
  {
    ticket_id: "#31562",
    name: "Kuramo Vets & Doctors",
    category: "App Bug",
    time: "30mins ago",
  },
  {
    ticket_id: "#31562",
    name: "Kuramo Vets & Doctors",
    category: "App Bug",
    time: "30mins ago",
  },
  {
    ticket_id: "#31562",
    name: "Kuramo Vets & Doctors",
    category: "App Bug",
    time: "30mins ago",
  },
  {
    ticket_id: "#31562",
    name: "Kuramo Vets & Doctors",
    category: "App Bug",
    time: "30mins ago",
  },
];

const SupportIPending = () => {
  const [indexNo, setIndexNo] = useState("");
  const [showModal, setShowModal] = useState({
    view: false,
    close_ticket: false,
    close_ticket_success: false,
  });

  return (
    <>
      <div>
        <CommonHeader />
        {/* table start */}
        <ReuseableTable
          //   loading={loading}
          one={"Ticket ID"}
          two={"Name"}
          three={`Category`}
          four={`Time`}
          threeDrop
          twoDrop
          action
          fourDrop
          moreThanFive={list?.length >= 3}
          //   tableTwo
        >
          {list.map((chi, idx) => {
            const { name, category, ticket_id, time } = chi;
            return (
              <TableRow
                setIndexNo={() => setIndexNo(idx)}
                rowNo={idx}
                indexNo={indexNo}
                key={idx}
                action
                one={ticket_id}
                two={name}
                three={category}
                four={time}
                viewText={`View Ticket`}
                listText={`Close Ticket`}
                onList={() =>
                  setShowModal((prev) => {
                    return { ...prev, close_ticket: true };
                  })
                }
                onViewDetail={() =>
                  setShowModal((prev) => {
                    return { ...prev, view: true };
                  })
                }
              />
            );
          })}
        </ReuseableTable>
        {/* table end */}
      </div>
      {/*  view modal start */}
      <Modal effect="fadeInUp" visible={showModal.view}>
        <ViewTicketModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, view: false };
            })
          }
        />
      </Modal>
      {/* view modal end */}
      {/*  close ticket success modal start */}
      <Modal effect="fadeInUp" visible={showModal.close_ticket_success}>
        <SuccessCloseTicketModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, close_ticket_success: false };
            })
          }
        />
      </Modal>
      {/* close ticket success modal end */}
      {/* close ticket modal start */}
      <Modal effect="fadeInUp" visible={showModal.close_ticket}>
        <CloseTicketModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, close_ticket: false };
            })
          }
          onCloseTicket={() =>
            setShowModal((prev) => {
              return {
                ...prev,
                close_ticket: false,
                close_ticket_success: true,
              };
            })
          }
        />
      </Modal>
      {/*close ticket modal end */}
    </>
  );
};

export default SupportIPending;
