import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import ItemInList from "../blog/ItemInList";
import CommonHeader from "./CommonHeader";
import Modal from "react-awesome-modal"
import AddFaqModal from "./modal/AddFaqModal";

const SupportSetting = () => {
  // const [showModal]
  const [metricsList, setMetricsList] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [showMetricsAdd, setShowMetricsAdd] = useState(false);
  const [metricsActiveInput, setMetricsActiveInput] = useState(false);
  const [productCategoryList, setproductCategoryList] = useState([]);
  const [showproductCategoryAdd, setShowproductCategoryAdd] = useState(false);
  const [productCategoryActiveInput, setproductCategoryActiveInput] =
    useState(false);
  const [showModal, setShowModal] = useState({
    metric: false,
    category: false,
    detail: {},
    add_faq: false,
    edit_faq: false,
  });

  const handleAddToMetricsList = async (e) => {
    const obj = {
      name: e.target.value,
    };
    // if (e.key === "Enter" && e.target.value) {
    //   if (
    //     !shop_metrics.some(
    //       (vendor) =>
    //         vendor["name"].toLowerCase() === e.target.value.toLowerCase()
    //     )
    //   ) {
    //     const data = await dispatch(createShopMetric(obj));
    //     if (data?.payload?.success) {
    //       setReloadData(!reloadData);
    //       setMetricsActiveInput(false);
    //     }
    //   } else {
    //     toast.info(" Shop metrics already exist");
    //   }
    // }
  };

  const handleRemoveMetrics = async () => {
    // console.log(showModal.detail.id);
    const payload = {
      id: showModal.detail.id,
    };
    // const data = await dispatch(deleteShopMetric(payload));
    // if (data?.payload?.success) {
    //   toast.success(
    //     `Shop metric ${showModal.detail.name}  deleted successfully`,
    //     {
    //       theme: "colored",
    //     }
    //   );
    //   setShowModal((prev) => {
    //     return { ...prev, metric: false, detail: {} };
    //   });
    //   setReloadData(!reloadData);
    // }
  };

  const handleAddToProductCategoryList = async (e) => {
    const obj = {
      name: e.target.value,
      // id: id,
    };
    // if (e.key === "Enter" && e.target.value) {
    //   if (
    //     !shop_categories.some(
    //       (vendor) =>
    //         vendor["name"].toLowerCase() === e.target.value.toLowerCase()
    //     )
    //   ) {
    //     const data = await dispatch(createShopCategory(obj));
    //     if (data?.payload?.success) {
    //       setReloadData(!reloadData);
    //       setproductCategoryActiveInput(false);
    //     }
    //   } else {
    //     toast.info(" Shop product category already exist");
    //   }
    // }
  };

  const handleRemoveCategory = async () => {
    // console.log(showModal.detail.id);
    const payload = {
      id: showModal.detail.id,
    };
    // const data = await dispatch(deleteShopCategory(payload));
    // if (data?.payload?.success) {
    //   toast.success(
    //     `Shop category ${showModal.detail.name}  deleted successfully`,
    //     {
    //       theme: "colored",
    //     }
    //   );
    //   setShowModal((prev) => {
    //     return { ...prev, category: false, detail: {} };
    //   });
    //   setReloadData(!reloadData);
    // }
  };


  return (
    <>
      <div className="support-setting-wrap-box">
        <CommonHeader />
         {/* metrics box start */}
         <div style={{marginTop: "4rem"}} className="metrics-box">
          <p className="title">Support Categories</p>
          <div
            className="detail-box"
            onClick={() => {
              metricsActiveInput && setMetricsActiveInput(false);
              setShowMetricsAdd(true);
            }}
          >
            {metricsList?.length < 1 &&
              !metricsActiveInput &&
              !showMetricsAdd && (
                <p className="info">
                 Create & edit support categories
                </p>
              )}
            {metricsList?.map((chi, idx) => {
              const { name, id } = chi;
              return (
                <ItemInList
                //   loading={loading}
                  onRemove={() => {
                    setShowModal((prev) => {
                      return { ...prev, metric: true, detail: chi };
                    });
                  }}
                  key={id || idx}
                  text={name}
                />
              );
            })}
            {/* add box button start */}
            <div
              className={`input-btn-box ${
                metricsActiveInput && "input-btn-box-active"
              }`}
            >
              {metricsActiveInput && (
                <input
                  onKeyPress={handleAddToMetricsList}
                  autoFocus={metricsActiveInput}
                  type="text"
                  className="metrics-input"
                />
              )}
              {!metricsActiveInput && showMetricsAdd && (
                <FaPlus
                  onClick={() => setMetricsActiveInput(true)}
                  className="icon"
                />
              )}
            </div>
            {/* add box button end */}
          </div>
          {/* detail box end */}
        </div>
        {/* metrics box end */}
        {/* product category box start */}
        <div style={{ marginTop: "4rem" }} className="metrics-box">
          <p className="title">FAQs</p>
          <div
            className="detail-box"
            onClick={() => setShowproductCategoryAdd(true)}
          >
            {metricsList?.length < 1 &&
              !productCategoryActiveInput &&
              !showproductCategoryAdd && (
                <p className="info">Create & edit FAQs</p>   
              )}
            {metricsList?.map((chi, idx) => {
              const { name, id } = chi;
              return (
                <ItemInList
                //   loading={loading}
                  onRemove={() => {
                    setShowModal((prev) => {
                      return { ...prev, category: true, detail: chi };
                    });
                  }}
                  key={id || idx}
                  text={name}
                />
              );
            })}
            {/* add box button start */}
            <div
              className={`input-btn-box ${
                productCategoryActiveInput && "input-btn-box-active"
              }`}
            >
              {productCategoryActiveInput && (
                <input
                  onKeyPress={handleAddToProductCategoryList}
                  autoFocus={productCategoryActiveInput}
                  type="text"
                  className="metrics-input"
                />
              )}
              {!productCategoryActiveInput && showproductCategoryAdd && (
                <FaPlus
                  onClick={() => setShowModal((prev) => {
                    return {...prev, add_faq: true}
                  })}
                  className="icon"
                />
              )}
            </div>
            {/* add box button end */}
          </div>
          {/* detail box end */}
        </div>
        {/* product category box end */}
      </div>
      {/* modal faq start */}
      <Modal visible={showModal.add_faq}>
        <AddFaqModal onClose={() => setShowModal((prev) => {
              return {...prev, add_faq: false}
            })} />
      </Modal>
      {/* modal faq end */}
    </>
  );
};

export default SupportSetting;
