import React from "react";
import "../../../../styles/dashboard/shop/Modal.css";
import sucImg from "../../../../assets/success-order-icon.svg";
import rejImg from "../../../../assets/delete-appoinment-icon.svg";
import acpImg from "../../../../assets/success-blog-img.svg";
import { FaTimes } from "react-icons/fa";

const CommonModal = ({
  bigText,
  smallText,
  children,
  accept,
  deletee,
  success,
  textarea,
  onClose,
}) => {
  return (
    <div className="accept-reject-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      <figure className="img-box">
        {accept && <img src={acpImg} alt="" className="img" />}
        {deletee && <img src={rejImg} alt="" className="img" />}
        {success && <img src={sucImg} alt="" className="img" />}
      </figure>

      {bigText && <p className="big-text">{bigText}</p>}
      {textarea && (
        <textarea
          style={{ resize: "none" }}
          className="input"
          placeholder="Give a reason for cancelling the appointment"
          name=""
          id=""
          cols="30"
          rows="10"
        ></textarea>
      )}
      {smallText && <p className="small-text">{smallText}</p>}

      {children}
    </div>
  );
};

export default CommonModal;
