import React from "react";
import "../../../../styles/dashboard/shop/Modal.css";
import sucImg from "../../../../assets/list-success.svg";
import { FaTimes } from "react-icons/fa";
import Button from "../../../../component/dashboard/fragments/Button";
import { useSelector } from "react-redux";
import ImageSpinner from "../../../../component/dashboard/fragments/ImageSpinner";

const ListItemModal = ({ children, onClose, details }) => {
  const { loading } = useSelector(
    (state) => state.shop_items
  );
  if(loading){
    return(
       <ImageSpinner />
    )
  }
  return (
    <div className="accept-reject-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      <figure className="img-box">
        <img src={sucImg} alt="" className="img" />
      </figure>

      <p className="big-text">{`Shop Item ${details?.name} has been successfully listed`}</p>

      {/* {smallText && <p className="small-text">{smallText}</p>} */}
      <Button
            onClick={onClose}
            text={`Close`}
            bgColor={`#00C013`}
          />
      {children}
    </div>
  );
};

export default ListItemModal;
