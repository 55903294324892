import React from "react";
import CommonModal from "./CommonModal";

const SuccessAcceptModal = ({ children }) => {
  return (
    <>
      <CommonModal
        success
        bigText={`Order  Successfully Accepted`}
        smallText={`The Order status has been updated to reflect
        delivery in progress.`}
      >
        {children}
      </CommonModal>
    </>
  );
};

export default SuccessAcceptModal;
