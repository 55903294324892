import React from "react";
import Button from "../../../../../component/dashboard/fragments/Button";
import CommonModal from "../CommonModal";

const EditParentDetailConfirmModal = ({ onClose, onUpdate }) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
       updateFoot
        // smallText={`The Pet Parent has been notified via mail`}
        bigText={`Are you sure you want to update this
        Pet Parent details?`}
      >
        <Button bgColor={`#FFA72C`} text="Update" onClick={onUpdate} />
      </CommonModal>
    </>
  );
};

export default EditParentDetailConfirmModal;
