import DocPendingApproval from "../../../pages/dashboard/service-provider/DocPendingApproval";
import ServiceProvider from "../../../pages/dashboard/service-provider/ServiceProvider";

export const service_provider_route_group = [
  {
    path: "/dashboard-service-provider",
    component: ServiceProvider,
    title: "Service Providers",
    smallTitle:
      "Review and Manage all service providers currently operating on the Petme app",
  },
  {
    path: "/dashboard-service-provider-doc-pending",
    component: DocPendingApproval,
    title: "Service Providers",
    smallTitle:
      "Review and Manage all service providers currently operating on the Petme app",
  },
];
