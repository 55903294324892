import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const SuccessCancelAppointmentModal = ({ onClose }) => {
  return (
    <>
      <>
        <CommonModal
          onClose={onClose}
          success
          smallText={`The Client will be notified of the reason
        via mail`}
          bigText={`Appointment Successfully Cancelled`}
        >
          <Button bgColor={`#FFA72C`} text="Close" onClick={onClose} />
        </CommonModal>
      </>
    </>
  );
};

export default SuccessCancelAppointmentModal;
