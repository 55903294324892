import AppointmentHistory from "../../../pages/dashboard/appointment/AppointmentHistory";
import NewAppointments from "../../../pages/dashboard/appointment/NewAppointments";

export const appointment_route_group = [
  {
    path: "/dashboard-appointment",
    component: NewAppointments,
    title: "Appointments",
    smallTitle: "Review all appointments made on the Petme App",
  },
  {
    path: "/dashboard-appointment-history",
    component: AppointmentHistory,
    title: "Appointments",
    smallTitle: "Review all appointments made on the Petme App",
  },
];
