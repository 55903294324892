import React, { useContext } from "react";
import ActionContext from "../../../context/ActionContext";
import CommonModal from "../../../pages/dashboard/payment-request/modal/CommonModal";
import Button from "../fragments/Button";

const SuccessPasswordChangeModal = () => {
  const actionCtx = useContext(ActionContext);
  return (
    <>
      <CommonModal
        onClose={() => actionCtx.setChangePasswordSuccess(false)}
        success
        bigText={`Password update successful`}
        smallText="Kindly login again"
      >
        <Button
          bgColor={"#FFA72C"}
          text="Close"
          onClick={() => actionCtx.setChangePasswordSuccess(false)}
        />
      </CommonModal>
    </>
  );
};

export default SuccessPasswordChangeModal;
