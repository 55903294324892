import React from "react";
import { FaAngleLeft, FaAngleRight, FaCaretDown } from "react-icons/fa";
import "../../../styles/dashboard/ReuseableTable.css";
import Select from "react-select";
import { reactSelectStyleChart } from "../../../helpers/Helper";

const ReuseableTable = ({
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,
  action,
  oneDrop,
  twoDrop,
  threeDrop,
  fourDrop,
  fiveDrop,
  sixDrop,
  sevenDrop,
  eightDrop,
  nineDrop,
  elevenDrop,
  tenDrop,
  children,
  moreThanFive,
  sN,
  tableTwo,
  dontShowPagination,
  fromNo,
  toNo,
  totalNo,
  selectValue,
  onPageSort,
  prevPage,
  nextPage,
  onNext,
  onPrev,
  sortOption,
}) => {
  return (
    <div className="reuseble-table-wrap">
      <table
        cellSpacing="0"
        cellPadding="0"
        className={`table ${tableTwo && "table-two"}`}
      >
        {/* table head start */}
        <thead>
          <tr>
            {sN && <th>#</th>}
            {one && (
              <th>
                {one} {oneDrop && <FaCaretDown className="icon" />}{" "}
              </th>
            )}
            {two && (
              <th>
                {two} {twoDrop && <FaCaretDown className="icon" />}{" "}
              </th>
            )}
            {three && (
              <th>
                {three} {threeDrop && <FaCaretDown className="icon" />}{" "}
              </th>
            )}
            {four && (
              <th>
                {four} {fourDrop && <FaCaretDown className="icon" />}{" "}
              </th>
            )}
            {five && (
              <th>
                {five} {fiveDrop && <FaCaretDown className="icon" />}{" "}
              </th>
            )}
            {six && (
              <th>
                {six} {sixDrop && <FaCaretDown className="icon" />}{" "}
              </th>
            )}
            {seven && (
              <th>
                {seven} {sevenDrop && <FaCaretDown className="icon" />}{" "}
              </th>
            )}
            {eight && (
              <th>
                {eight} {eightDrop && <FaCaretDown className="icon" />}{" "}
              </th>
            )}
            {nine && (
              <th>
                {nine} {nineDrop && <FaCaretDown className="icon" />}{" "}
              </th>
            )}
            {ten && (
              <th>
                {ten} {tenDrop && <FaCaretDown className="icon" />}{" "}
              </th>
            )}
            {eleven && (
              <th>
                {eleven} {elevenDrop && <FaCaretDown className="icon" />}{" "}
              </th>
            )}
            {action && <th className="action">&nbsp;</th>}
          </tr>
        </thead>
        {/* table head end */}
        {/* table body start */}
        <tbody className={`${moreThanFive ? "table-body" : ""}`}>
          {children}
        </tbody>
        {/* table body end */}
      </table>
      {/* pagination box start */}
      {!dontShowPagination && (
        <div className="pagination-wrap-box">
          {/* form-to box startx */}
          <div className="from-to-box">
            <span>{fromNo || "1"}</span>
            <span>-</span>
            <span>{toNo || "1"}</span>
            <span>of</span>
            <span>{totalNo || "5"}</span>
          </div>
          {/* form-to box end */}
          <p className="text">You are currently on Page</p>
          {/* select option start */}
          <Select
            styles={reactSelectStyleChart}
            value={selectValue}
            onChange={(e) => {
              onPageSort && onPageSort(e);
            }}
            className="react-select"
            options={sortOption}
          />
          {/* select option end */}
          {/* prev next box start */}
          <div className="prev-next-box">
            <div
              onClick={() => prevPage && onPrev()}
              className={`box ${prevPage && "box-active"}`}
            >
              <FaAngleLeft className="icon" />
            </div>
            <div
              onClick={() => nextPage && onNext()}
              className={`box ${nextPage && "box-active"}`}
            >
              <FaAngleRight className="icon" />
            </div>
          </div>
          {/* prev next box end */}
        </div>
      )}
      {/* pagination box end */}
    </div>
  );
};

export default ReuseableTable;
