import dogBagImg from "../assets/dog-bag-img.png"


export const shopOrderList = {
  tableHead: [
    "Order Id",
    "Customer",
    "Payment Mode",
    "Item Count",
    "Item Cost",
    "Order Status",
  ],
  tableBody: [
    {
      order_id: "P2934MD",
      customer: "Kokumo Blaqbonez",
      payment_mode: "Card",
      item_count: "4",
      item_cost: "N75,000",
      order_status: "On Delivery",
    },
    {
      order_id: "P2934MD",
      customer: "Kokumo Blaqbonez",
      payment_mode: "Card",
      item_count: "4",
      item_cost: "N75,000",
      order_status: "Pending",
    },
    {
      order_id: "P2934MD",
      customer: "Kokumo Blaqbonez",
      payment_mode: "Card",
      item_count: "4",
      item_cost: "N75,000",
      order_status: "On Delivery",
    },
    {
      order_id: "P2934MD",
      customer: "Kokumo Blaqbonez",
      payment_mode: "Card",
      item_count: "4",
      item_cost: "N75,000",
      order_status: "Pending",
    },
    {
      order_id: "P2934MD",
      customer: "Kokumo Blaqbonez",
      payment_mode: "Card",
      item_count: "4",
      item_cost: "N75,000",
      order_status: "On Delivery",
    },
    {
      order_id: "P2934MD",
      customer: "Kokumo Blaqbonez",
      payment_mode: "Card",
      item_count: "4",
      item_cost: "N75,000",
      order_status: "Pending",
    },
  ],
};


export const shopItemList = {
    tableHead: [
        "Item",
        "Stock",
        "Unit Cost",
        "Sold",
        "Item Status",
      ],
      tableBody: [
        {
          item: {img: dogBagImg, des: "Fidog Vitality Community Dog Food (3kg)"},
          stock: "3",
          unit_cost: "N75,000",
          sold: "27",
          item_status: "Listed",
        },
        {
          item: {img: dogBagImg, des: "Fidog Vitality Community Dog Food (3kg)"},
          stock: "3",
          unit_cost: "N75,000",
          sold: "27",
          item_status: "Listed",
        },
        {
          item: {img: dogBagImg, des: "Fidog Vitality Community Dog Food (3kg)"},
          stock: "3",
          unit_cost: "N75,000",
          sold: "27",
          item_status: "unListed",
        },
        {
          item: {img: dogBagImg, des: "Fidog Vitality Community Dog Food (3kg)"},
          stock: "3",
          unit_cost: "N75,000",
          sold: "27",
          item_status: "unListed",
        },
    ]
}