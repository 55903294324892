import React, { useEffect, useState } from "react";
import "../../../styles/dashboard/pet/CreatePetDetails.css";
import petDefault from "../../../assets/create-pet-img-default.png";
import Flatpicker from "react-flatpickr";
import "flatpickr/dist/themes/confetti.css";
import calendarIcon from "../../../assets/calendar-icon.svg";
import moment from "moment";
import Select from "react-select";
import Button from "../../../component/dashboard/fragments/Button";
import { useHistory } from "react-router-dom";
import CreatePetDetailModal from "./modals/CreatePetDetailModal";
import CreatePetDetailSuccessModal from "./modals/CreatePetDetailSuccess";
import Modal from "react-awesome-modal";
import { useDispatch, useSelector } from "react-redux";
import { createPetAdmin, getPetTypes } from "../../../redux/pets/petTypes";
import { reactSelectStyle } from "../../../helpers/Helper";
import { Dna } from "react-loader-spinner";

const CreatePetDetail = () => {
  const dispatch = useDispatch();
  const { pet_types, loading } = useSelector((state) => state.pet_type);
  // const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [petImg, setPetImage] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [selectedOption, setselectedOption] = useState(null);
  const [details, setDetails] = useState({
    user_id: "",
    pet_type_id: "",
    registration_number: "",
    gender: "",
    name: "",
    dob: "",
    distinguishing_marks: "",
    image: "",
  });
  const [showModal, setShowModal] = useState({
    create_notify: false,
    create_notify_success: false,
  });
  const history = useHistory();
  const metricsDropDown = [
    { value: 1, label: "test one" },
    { value: 2, label: "test two" },
  ];

  const sexOptions = [
    { label: "Male", value: "male" },
    { label: "feMale", value: "female" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSubmit = async(e) => {
    // e.preventDefault();
    const obj = {
      ...details,
      user_id: user?.id,
    };
    const formData = new FormData();
    formData.append("user_id", obj.user_id);
    formData.append("image", details?.image);
    formData.append("distinguishing_marks", details?.distinguishing_marks);
    formData.append("dob", details?.dob);
    formData.append("gender", details?.gender?.value);
    formData.append("name", details?.name);
    formData.append("pet_type_id", details?.pet_type_id?.value);
    formData.append("registration_number", details?.registration_number);

    const data = await dispatch(createPetAdmin(formData))

  };

  useEffect(() => {
    dispatch(getPetTypes());
  }, []);

  return (
    <>
      <div className="create-pet-detail-wrap">
        {/* ttitle box start */}
        <div className="title-box">
          <p className="text">Pet Details</p>
          <span></span>
        </div>
        {/* ttitle box end */}

        {/* form box start  */}
        <form onSubmit={handleSubmit} action="" className="form">
          {/* picture detail box start */}
          <div className="picture-detail-box">
            {/* picture box start */}
            <div className="picture-box">
              <div className="form-group">
                <p className="text">Pet Picture</p>
                <input
                  onChange={(e) => {
                    setPetImage(e.target.files[0]);
                    setDetails((prev) => {
                      return { ...prev, image: e.target.files[0] };
                    });
                  }}
                  type="file"
                  name=""
                  id="file-input"
                  className="file-input"
                />
                <label
                  style={{
                    backgroundImage: `url(${
                      petImg ? URL.createObjectURL(petImg) : petDefault
                    })`,
                  }}
                  htmlFor="file-input"
                  className="file-label"
                ></label>
              </div>
            </div>
            {/* picture box end */}
            {/* distinguish box start */}
            <div className="distinguish-box">
              <p className="text">Distinguishing Marks (Optional)</p>
              <div className="form-group form-group-text-area">
                <textarea
                  value={details?.distinguishing_marks}
                  style={{ resize: "none" }}
                  className="input"
                  placeholder="Give a detail note"
                  name="distinguishing_marks"
                  onChange={handleChange}
                  id=""
                  cols="40"
                  rows="7"
                ></textarea>
              </div>
            </div>
            {/* distinguish box end */}
          </div>
          {/* picture detail box end */}
          {/* three boxes start */}
          <div className="three-group-boxes">
            {/* form group start */}
            <div className="form-group">
              <label htmlFor="" className="label">
                Pet Name
              </label>
              <div className="input-wrap">
                <input
                  name="name"
                  onChange={handleChange}
                  placeholder=""
                  type="text"
                  className="input"
                />
              </div>
            </div>
            {/* fom group end */}
            {/* form group start */}
            <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Select Date of Birth
              </label>
              <div className="group-two">
                <label htmlFor="from" className="label label-calendar">
                  <figure className="img-box">
                    <img src={calendarIcon} alt="" className="img" />
                  </figure>
                </label>
                <Flatpicker
                  id="from"
                  name="from"
                  // data-enable-time
                  //   cal
                  className="input-calender"
                  autoComplete="off"
                  value={fromDate}
                  onChange={(date) => {
                    setFromDate(moment(date[0]).format("YYYY/MM/DD"));
                    setDetails((prev) => {
                      return {
                        ...prev,
                        dob: moment(date[0]).format("YYYY/MM/DD"),
                      };
                    });
                  }}
                  placeholder="DD / MM / YYYY"
                />
              </div>
            </div>
            {/* form group end */}
            {/* form group start */}
            <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Select Sex
              </label>
              <Select
                styles={reactSelectStyle}
                value={details?.gender}
                onChange={(e) => {
                  // setselectedOption(e);
                  setDetails((prev) => {
                    return {
                      ...prev,
                      gender: e,
                    };
                  });
                }}
                options={sexOptions}
                className="input-border-select"
              />
            </div>
            {/* form group end */}
          </div>
          {/* three boxes end */}
          {/* three boxes start */}
          <div className="three-group-boxes">
            {/* form group start */}
            <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Select Pet Type
              </label>
              <Select
                styles={reactSelectStyle}
                value={details?.pet_type_id}
                onChange={(e) => {
                  setDetails((prev) => {
                    return {
                      ...prev,
                      pet_type_id: e,
                    };
                  });
                }}
                options={pet_types}
                className="input-border-select"
              />
            </div>
            {/* form group end */}
            {/* form group start */}
            <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Resgistration Number
              </label>
              <div className="input-wrap">
                <input
                  value={details?.registration_number}
                  name="registration_number"
                  onChange={handleChange}
                  placeholder=""
                  type="text"
                  className="input"
                />
              </div>
            </div>
            {/* form group end */}
            {/* form group start */}
            {/* <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Select Breed
              </label>
              <Select
                isDisabled
                value={selectedOption}
                onChange={(e) => setselectedOption(e)}
                options={metricsDropDown}
                className="input-border-select"
              />
            </div> */}
            {/* form group end */}
          </div>
          {/* three boxes end */}

          {/* three boxes start */}
          {/* <div className="three-group-boxes"> */}
          {/* form group start */}
          {/* <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Assign Service Provider
              </label>
              <Select
                isDisabled
                value={selectedOption}
                onChange={(e) => setselectedOption(e)}
                options={metricsDropDown}
                className="input-border-select"
              />
            </div> */}
          {/* form group end */}
          {/* </div> */}
          {/* three boxes end */}

          {/* ttitle box start */}
          {/* <div className="title-box">
            <p className="text">Pet Parent Details</p>
            <span></span>
          </div> */}
          {/* ttitle box end */}
          {/* two boxes start */}
          {/* <div className="two-group-boxes"> */}
          {/* form group start */}
          {/* <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Pet Parent Name
              </label>
              <Select
                isDisabled
                value={selectedOption}
                onChange={(e) => setselectedOption(e)}
                options={metricsDropDown}
                className="input-border-select"
              />
            </div> */}
          {/* form group end */}
          {/* form group start */}
          {/* <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Phone Number
              </label>
              <div className="input-wrap">
                <input
                  disabled
                  name="name"
                  // onChange={handleChange}
                  placeholder=""
                  type="text"
                  className="input"
                />
              </div>
            </div> */}
          {/* form group end */}
          {/* </div> */}
          {/* two boxes end */}
          {/* three boxes start */}
          {/* <div className="three-group-boxes"> */}
          {/* form group start */}
          {/* <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Email address
              </label>
              <div className="input-wrap">
                <input
                  disabled
                  name="name"
                  // onChange={handleChange}
                  placeholder=""
                  type="email"
                  className="input"
                />
              </div>
            </div> */}
          {/* form group end */}
          {/* form group start */}
          {/* <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Select Branch
              </label>
              <Select
                isDisabled
                value={selectedOption}
                onChange={(e) => setselectedOption(e)}
                options={metricsDropDown}
                className="input-border-select"
              />
            </div> */}
          {/* form group end */}
          {/* </div> */}
          {/* three boxes end */}
          {/* two boxes start */}
          {/* <div className="two-group-boxes"> */}
          {/* form group start */}
          {/* <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Pet Parent Address
              </label>
              <div className="input-wrap">
                <input
                  disabled
                  name="name"
                  // onChange={handleChange}
                  placeholder=""
                  type="text"
                  className="input"
                />
              </div>
            </div> */}
          {/* form group end */}
          {/* form group start */}
          {/* <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Phone Number
              </label>
              <div className="input-wrap">
                <input
                  disabled
                  name="name"
                  // onChange={handleChange}
                  placeholder=""
                  type="text"
                  className="input"
                />
              </div>
            </div> */}
          {/* form group end */}
          {/* </div> */}
          {/* two boxes end */}
          {/* ttitle box start */}
          {/* <div className="title-box">
            <p className="text">Ownership History (Optional)</p>
            <span></span>
          </div> */}
          {/* ttitle box end */}
          {/* two boxes start */}
          {/* <div className="two-group-boxes"> */}
          {/* form group start */}
          {/* <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Previous Pet Parent Name
              </label>
              <Select
                isDisabled
                value={selectedOption}
                onChange={(e) => setselectedOption(e)}
                options={metricsDropDown}
                className="input-border-select"
              />
            </div> */}
          {/* form group end */}
          {/* </div> */}
          {/* two boxes end */}
          {/* three boxes start */}
          {/* <div className="three-group-boxes"> */}
          {/* form group start */}
          {/* <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Email address
              </label>
              <div className="input-wrap">
                <input
                  name="name"
                  // onChange={handleChange}
                  placeholder=""
                  type="email"
                  className="input"
                />
              </div>
            </div> */}
          {/* form group end */}
          {/* </div> */}
          {/* three boxes end */}
          {/* two boxes start */}
          {/* <div className="two-group-boxes"> */}
          {/* form group start */}
          {/* <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Previous Pet Parent Address
              </label>
              <div className="input-wrap">
                <input
                  name="name"
                  // onChange={handleChange}
                  placeholder=""
                  type="text"
                  className="input"
                />
              </div>
            </div> */}
          {/* form group end */}
          {/* </div> */}
          {/* two boxes end */}
          {/* ttitle box start */}
          {/* <div className="title-box">
            <p className="text">Breeder Details (Optional)</p>
            <span></span>
          </div> */}
          {/* ttitle box end */}
          {/* two boxes start */}
          {/* <div className="two-group-boxes"> */}
          {/* form group start */}
          {/* <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Breeder`s Name
              </label>
              <Select
                value={selectedOption}
                onChange={(e) => setselectedOption(e)}
                options={metricsDropDown}
                className="input-border-select"
              />
            </div> */}
          {/* form group end */}
          {/* form group start */}
          {/* <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Phone Number
              </label>
              <div className="input-wrap">
                <input
                  name="name"
                  // onChange={handleChange}
                  placeholder=""
                  type="text"
                  className="input"
                />
              </div>
            </div> */}
          {/* form group end */}
          {/* </div> */}
          {/* two boxes end */}
          {/* two boxes start */}
          {/* <div className="two-group-boxes"> */}
          {/* form group start */}
          {/* <div className="form-group">
              <label htmlFor="Metrics" className="label">
                Breeder`s Address
              </label>
              <div className="input-wrap">
                <input
                  name="name"
                  // onChange={handleChange}
                  placeholder=""
                  type="text"
                  className="input"
                />
              </div>
            </div> */}
          {/* form group end */}
          {/* </div> */}
          {/* two boxes end */}
          {/* btn box wrap start */}
          <div className="btn-box-wrap">
            {!loading ? (
              <Button
                onClick={() => {
                  // setShowModal((prev) => {
                  //   return { ...prev, create_notify: true };
                  // });
                  handleSubmit();
                }}
                text={`Create Pet Client`}
                bgColor={
                  details?.distinguishing_marks &&
                  details?.dob &&
                  details?.gender &&
                  details?.image &&
                  details?.name &&
                  details?.pet_type_id &&
                  details?.registration_number
                    ? "#84BFCD"
                    : "#bbbbbb"
                }
                btnStyle={`btn-reuse`}
              />
            ) : (
              <div className="dna-wrapper-box" style={{ alignSelf: "center" }}>
                <Dna
                  visible={true}
                  height="80"
                  width="100"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            <p onClick={() => history.goBack()} className="cancel-text">
              Cancel
            </p>
          </div>
          {/* btn box wrap end */}
        </form>
        {/* form box end */}
      </div>
      {/* modal uppdate notify start */}
      <Modal visible={showModal.create_notify} effect="fadeInUp">
        <CreatePetDetailModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, create_notify: false };
            })
          }
          onCreate={() =>
            setShowModal((prev) => {
              return {
                ...prev,
                create_notify: false,
                create_notify_success: true,
              };
            })
          }
        />
      </Modal>
      {/* modal uppdate notify end */}
      {/* modal uppdate success notify start */}
      <Modal visible={showModal.create_notify_success} effect="fadeInUp">
        <CreatePetDetailSuccessModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, create_notify_success: false };
            })
          }
        />
      </Modal>
      {/* modal uppdate success notify end */}
    </>
  );
};

export default CreatePetDetail;
