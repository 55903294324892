import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import setAuthToken from "../../utils/auth";
toast.configure();

export const getShopMetrics = createAsyncThunk(
  "admin/shop-get-metrics",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      //     console.log(token);
      //   return
      const { data } = await axios.get("admin/shop/metrics", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log('Login', data);
      if (!data.success) {
        // console.log(data.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      // console.log(data.metrics);
      await thunkAPI.dispatch(setShopMetrics(data.metrics));
      return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const getShopCategories = createAsyncThunk(
  "admin/shop-get-categories",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      //     console.log(token);
      //   return
      const { data } = await axios.get("admin/shop/categories", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!data.success) {
        // console.log(data.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      // console.log(data.categories);
      await thunkAPI.dispatch(setShopCategories(data.categories));
      return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

const loadOptionFunc = (data, type) => {
  if (type === "metrics") {
    const list = data?.map(({ id, name }) => {
      return { value: id, label: name };
    });
    return list;
  }
  if (type === "categories") {
    const list = data?.map(({ id, name }) => {
      return { value: id, label: name };
    });
    return list;
  }
};

export const createShopMetric = createAsyncThunk(
  "admin/create-shop-metric",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const payload = {
        name: formData.name,
      };
      //   return
      await setAuthToken(token);
      const { data } = await axios.post("admin/shop/metrics/create", {
        name: payload.name,
      });
      // console.log('Login', data);
      if (!data.success) {
        // console.log(data.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      toast.success(`Shop metric ${data.metric.name} creaated successfully`, {
        theme: "colored",
      });
      return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const createShopCategory = createAsyncThunk(
  "admin/create-shop-category",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const payload = {
        name: formData.name,
      };
      //   return
      await setAuthToken(token);
      const { data } = await axios.post("admin/shop/categories/create", {
        name: payload.name,
      });
      // console.log('Login', data);
      if (!data.success) {
        // console.log(data.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      toast.success(`Shop metric ${data.category.name} creaated successfully`, {
        theme: "colored",
      });
      return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteShopMetric = createAsyncThunk(
  "admin/delete-shop-metric",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const { id } = formData;
      const { data } = await axios.delete(`admin/shop/metrics/${id}/delete`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      //   console.log('delete', data);
      if (!data.success) {
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if(data?.success){
        // console.log(data);
        // toast.success(data.message, {
        //   theme: "colored",
        // });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteShopCategory = createAsyncThunk(
  "admin/delete-shop-category",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const { id } = formData;
      const { data } = await axios.delete(
        `admin/shop/categories/${id}/delete`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //   console.log('delete', data);
      if (!data.success) {
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if(data?.success){
        // console.log(data);
        // toast.success(data.message, {
        //   theme: "colored",
        // });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const shop_settings = createSlice({
  name: "shop_settings",
  initialState: {
    shop_metrics: [],
    shop_categories: [],
    loading: false,
    success: false,
    metricsDropDown: [],
    categoriesDropDown: [],
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setShopMetrics: (state, action) => {
      state.shop_metrics = action.payload;
      state.loading = false;
      state.success = true;
      state.metricsDropDown = loadOptionFunc(action.payload, "metrics");
    },
    setShopCategories: (state, action) => {
      state.shop_categories = action.payload;
      state.loading = false;
      state.categoriesDropDown = loadOptionFunc(action.payload, "categories");
    },
  },
  extraReducers: {
    [getShopMetrics.pending]: (state) => {
      state.loading = true;
    },
    [getShopMetrics.fulfilled]: (state) => {
      state.loading = false;
      //   state.success = true;
    },
    [getShopMetrics.rejected]: (state) => {
      //   localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [createShopMetric.pending]: (state) => {
      state.loading = true;
    },
    [createShopMetric.fulfilled]: (state) => {
      state.loading = false;
      //   state.success = true;
    },
    [createShopMetric.rejected]: (state) => {
      //   localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [createShopCategory.pending]: (state) => {
      state.loading = true;
    },
    [createShopCategory.fulfilled]: (state) => {
      state.loading = false;
      //   state.success = true;
    },
    [createShopCategory.rejected]: (state) => {
      //   localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteShopMetric.pending]: (state) => {
      state.loading = true;
    },
    [deleteShopMetric.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
    },
    [deleteShopMetric.rejected]: (state) => {
      //   localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [deleteShopCategory.pending]: (state) => {
      state.loading = true;
    },
    [deleteShopCategory.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
    },
    [deleteShopCategory.rejected]: (state) => {
      //   localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getShopCategories.pending]: (state) => {
      state.loading = true;
    },
    [getShopCategories.fulfilled]: (state) => {
      state.loading = false;
      //   state.success = true;
    },
    [getShopCategories.rejected]: (state) => {
      //   localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setShopMetrics, setShopCategories } = shop_settings.actions;

export default shop_settings.reducer;
