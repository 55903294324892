import React from "react";
// import Button from "../../../../component/dashboard/fragments/Button";
import "../../../../styles/dashboard/shop/Modal.css";
// import dogImg from "../../../../assets/dog-bag-img.png";
import {
  formatNumWithCommaNairaSymbol,
  lowerCaseWrap,
  sumAllNum,
  trimLongString,
} from "../../../../helpers/Helper";

const listShow = ["Details", "Items"];

// const itemList = [
//   {
//     img: dogImg,
//     des: "Fidog Vitality Community Dog Food (3kg)",
//     quantity: "1 Bag",
//     amount: "N25,000",
//   },
//   {
//     img: dogImg,
//     des: "Fidog Vitality Community Dog Food (3kg)",
//     quantity: "1 Bag",
//     amount: "N25,000",
//   },
//   {
//     img: dogImg,
//     des: "Fidog Vitality Community Dog Food (3kg)",
//     quantity: "1 Bag",
//     amount: "N25,000",
//   },
//   {
//     img: dogImg,
//     des: "Fidog Vitality Community Dog Food (3kg)",
//     quantity: "1 Bag",
//     amount: "N25,000",
//   },
//   {
//     img: dogImg,
//     des: "Fidog Vitality Community Dog Food (3kg)",
//     quantity: "1 Bag",
//     amount: "N25,000",
//   },
// ];

const getAllSumArray = (list) => {
  if (list?.length > 0) {
    const newList = list?.map((chi) => chi?.cost);
    // console.log(newList);
    return newList;
  }
};

const OrderDetailModal = ({ children, detail, showCotent, setShowContent }) => {
  // const [showCotent, setShowContent] = useState("Details");
  return (
    <div className="order-shop-modal-wrap">
      {children}
      {/* title start  */}
      <p className="title">Order Details</p>
      {/* title end */}

      {/* switch box start */}
      <div className="switch-box">
        {listShow.map((chi, idx) => {
          return (
            <div
              onClick={() => setShowContent(chi)}
              key={idx}
              className={`item ${showCotent === chi && "item-active"}`}
            >
              <span>
                {chi} {chi === "Items" && `- ${detail?.cart_order_items?.length}`}
              </span>
            </div>
          );
        })}
      </div>
      {/* switch box end */}

      {/* content box start */}
      {/* detail box start */}
      {showCotent === "Details" && (
        <div className="detail-wrap">
          <div className="top-box">
            {/* box start */}
            <div className="box">
              <p className="key">Parent Name</p>
              <p className="value">
                {trimLongString(lowerCaseWrap(detail?.user_name), 25) || "---"}
              </p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="key">Phone Number</p>
              <p className="value">{detail?.user_mobile || "---"}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="key">Email</p>
              <p className="value">{detail?.user_email || "---"}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="key">Delivery Address</p>
              <p className="value">{detail?.order_address || "---"}</p>
            </div>
            {/* box end */}
          </div>
          <div className="bottom-box">
            {/* box start */}
            <div className="box">
              <p className="key">Order ID</p>
              <p className="value">{detail?.order_reference || "---"}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="key">Payment Mode</p>
              <p className="value">{detail?.payment_method || "---"}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="key">Order Time</p>
              <p className="value">{detail?.created_at || "---"}</p>
            </div>
            {/* box end */}
            {/* box start */}
            <div className="box">
              <p className="key">Order Status</p>
              <p
                className={`status status-pending ${
                  detail?.status === "0" && "status-pending"
                }  ${detail?.status !== "0" && "status-deliver"} `}
              >
                <span>
                  {detail?.status === "0" ? "On  Pending" : `On Delivery`}
                </span>
              </p>
            </div>
            {/* box end */}
          </div>
        </div>
      )}
      {/* detail box end */}
      {/* item- list box start */}
      {showCotent === "Items" && (
        <div className="item-list-box">
          <p className="title">{`Total Cost - ${
            detail?.cart_order_items
              ? formatNumWithCommaNairaSymbol(
                  String(sumAllNum(getAllSumArray(detail?.cart_order_items)))
                )
              : formatNumWithCommaNairaSymbol("0")
          }`}</p>
          {/* content start  */}
          <div className="content-box">
            {detail?.cart_order_items?.map((chi, idx) => {
              const { images, cost, name, quantity } = chi;
              return (
                <div key={idx} className="box">
                  <figure className="img-box">
                    <img
                      src={images && images[0]?.image}
                      alt=""
                      className="img"
                    />
                  </figure>
                  <div className="text-box">
                    <p className="value">
                      {trimLongString(lowerCaseWrap(name), 25)}
                    </p>
                    <p className="key">{quantity}</p>
                  </div>
                  <p className="amount">
                    {formatNumWithCommaNairaSymbol(String(cost))}
                  </p>
                </div>
              );
            })}
          </div>
          {/* content end */}
        </div>
      )}
      {/* item- list box end */}
      {/* button start */}
      {/* <Button text="Confirm Delivery" bgColor={`#84BFCD`} /> */}
      {/* button end */}
      {/* content box end */}
    </div>
  );
};

export default OrderDetailModal;
