import React, { useState, useEffect } from "react";
import { createShopMetric } from "../../../redux/shop/ShopSettings";
import CommonHeader from "./CommonHeader";
import "../../../styles/dashboard/shop/ShopSetting.css";
import { FaPlus } from "react-icons/fa";
import ItemInList from "./ItemInList";
import Modal from "react-awesome-modal";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { createBlogCategory, deleteBlogCategory, getBlogCategories } from "../../../redux/blog/BlogCategories";
import DeleteBlogCategoryModal from "./modals/DeleteBlogCategory";
// import DeleteBlogModal from "./modals/DeleteBlogModal";

const BlogSettings = () => {
  const dispatch = useDispatch();
  const { blog_categories, loading } = useSelector(
    (state) => state.blog_settings
  );
  // const [metricsList, setMetricsList] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [showMetricsAdd, setShowMetricsAdd] = useState(false);
  const [metricsActiveInput, setMetricsActiveInput] = useState(false);
  const [showModal, setShowModal] = useState({
    metric: false,
    category: false,
    detail: {},
  });

  const handleAddToCategoriesList = async (e) => {
    const obj = {
      name: e.target.value,
    };
    if (e.key === "Enter" && e.target.value) {
      if (
        !blog_categories.some(
          (vendor) =>
            vendor["name"].toLowerCase() === e.target.value.toLowerCase()
        )
      ) {
        // console.log(obj);
        // return
        const data = await dispatch(createBlogCategory(obj));
        if (data?.payload?.success) {
          setReloadData(!reloadData);
          setMetricsActiveInput(false);
        }
      } else {
        toast.info(" Shop metrics already exist");
      }
    }
  };

  const handleRemoveCategory = async () => {
    // console.log(showModal.detail.id);
    const payload = {
      id: showModal.detail.id,
    };
    const data = await dispatch(deleteBlogCategory(payload));
    if (data?.payload?.success) {
      toast.success(
        `blog category ${showModal.detail.name}  deleted successfully`,
        {
          theme: "colored",
        }
      );
      setShowModal((prev) => {
        return { ...prev, category: false, detail: {} };
      });
      setReloadData(!reloadData);
    }
  };

  useEffect(() => {
    dispatch(getBlogCategories())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData])

  return (
    <>
      <div>
        {/* common headr start */}
        <CommonHeader
          onSearch={(e) => console.log(e)}
          onClickBtn={() =>
            setShowModal((prev) => {
              return { ...prev, add: true };
            })
          }
        />
        {/* common header end */}
        {/* content box start */}
        <div className="content-box-shop-settings">
          {/* metrics box start */}
          <div className="metrics-box">
            <p className="title">Article Categories</p>
            <div
              className="detail-box"
              onClick={() => {
                metricsActiveInput && setMetricsActiveInput(false);
                setShowMetricsAdd(true);
              }}
            >
              {blog_categories?.length < 1 &&
                !metricsActiveInput &&
                !showMetricsAdd && (
                  <p className="info">Create & edit article categories</p>
                )}
              {blog_categories?.map((chi, idx) => {
                const { name, id } = chi;
                return (
                  <ItemInList
                    loading={loading}
                    onRemove={() => {
                      // console.log(chi);
                      setShowModal((prev) => {
                        return { ...prev, category: true, detail: chi };
                      });
                    }}
                    key={id || idx}
                    text={name}
                  />
                );
              })}
              {/* add box button start */}
              <div
                className={`input-btn-box ${
                  metricsActiveInput && "input-btn-box-active"
                }`}
              >
                {metricsActiveInput && (
                  <input
                    onKeyPress={handleAddToCategoriesList}
                    autoFocus={metricsActiveInput}
                    type="text"
                    className="metrics-input"
                  />
                )}
                {!metricsActiveInput && showMetricsAdd && (
                  <FaPlus
                    onClick={() => setMetricsActiveInput(true)}
                    className="icon"
                  />
                )}
              </div>
              {/* add box button end */}
            </div>
            {/* detail box end */}
          </div>
          {/* metrics box end */}
        </div>
        {/* content box end */}
      </div>
      <Modal visible={showModal.category} effect={`fadeInUp`}>
     <DeleteBlogCategoryModal
     name={showModal?.detail?.name}
     onDelete={handleRemoveCategory}
     onClose={() => {
       setShowModal((prev) => {
         return { ...prev, category: false, detail: {} };
       });
     }}
     />
      </Modal>
    </>
  );
};

export default BlogSettings;
