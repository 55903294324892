import SupportHistory from "../../../pages/dashboard/support/SupportHistory";
import SupportIPending from "../../../pages/dashboard/support/SupportIPending";
import SupportSetting from "../../../pages/dashboard/support/SupportSetting";

export const support_route_group = [
  {
    path: "/dashboard-support",
    component: SupportIPending,
    title: "Support",
    smallTitle: "Review and Manage all  support requests from Petme users",
  },
  {
    path: "/dashboard-support-history",
    component: SupportHistory,
    title: "Support",
    smallTitle: "Review and Manage all  support requests from Petme users",
  },
  {
    path: "/dashboard-support-setting",
    component: SupportSetting,
    title: "Support",
    smallTitle: "Review and Manage all  support requests from Petme users",
  },
];
