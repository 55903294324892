import { configureStore } from '@reduxjs/toolkit';
import user from './user';
import shop_items  from './shop/ShopItems';
import shop_settings from './shop/ShopSettings';
import blog_settings from './blog/BlogCategories';
import blog_posts from './blog/BlogPosts';
import service_provider from "./service-provider/index"
import pet_type  from './pets/petTypes';
import appointments from "./appointment/Appointments"
import shop_order from "./shop/shopOrder"
import dashboard from './dashboard/dashboard';

const initialState = {};
const store = configureStore({
  reducer: {
    // reducer
    user,
    shop_items,
    shop_settings,
    blog_settings,
    blog_posts,
    service_provider,
    pet_type,
    appointments,
    shop_order,
    dashboard
  },
  initialState,
  devTools: true
});

export default store;
// Language: javascript
