import React, { useState, useEffect } from "react";
// import ZojatechContext from "../store/ZojatechContext"
import { Route, Redirect, useLocation } from "react-router-dom";
import DashboardLayout from "../component/dashboard/layout";
// import DashboardLayout from "../components/DashboardLayout";
// import {  useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, title, smallTitle,   ...rest }) => {
  const location = useLocation();
  // const zojatechCtx = useContext(ZojatechContext);
  const [pageTitle, setPageTitle] = useState("");
  const [psmallTitle, setSmallTitle] = useState("")

  const login = JSON.parse(localStorage.getItem("login")) || false; 
  const token = localStorage.getItem("token")

  useEffect(() => {
    setPageTitle(title);
    setSmallTitle(smallTitle);
  }, [location.pathname, title, smallTitle]);

  return (
    <Route
      {...rest}
      render={(props) =>
        login && token ? (
          <DashboardLayout pageSmallText={psmallTitle} pageTitle={pageTitle}>
            <Component {...props} smallTitl={smallTitle}  title={title} />
          </DashboardLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;