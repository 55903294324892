import React, { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import Button from "../../../component/dashboard/fragments/Button";
import "../../../styles/dashboard/home/DashboardHome.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Select from "react-select";
import {
  lowerCaseWrap,
  reactSelectStyleChart,
  sumAllNum,
} from "../../../helpers/Helper";
import userImg from "../../../assets/user-img.png";
// import HomeChart from "./HomeChart";
// import serviceImgOne from "../../../assets/service-provider-one-img.png";
// import serviceImgTwo from "../../../assets/service-provider-two-img.png";
// import serviceImgThree from "../../../assets/service-provider-three-img.png";
import { Rating } from "react-simple-star-rating";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardInfo } from "../../../redux/dashboard/dashboard";
import DashboardBarChart from "./HomeBarChart";
import { getServiceProviders } from "../../../redux/service-provider";

const paymentRequestList = [
  {
    date: "Jul 20 - 09:00am",
    clinic_name: "Kurams Vet Clinic",
    amount: "N294,000",
    bank_name: "Wema Bank",
    bank_num: "0123456789",
  },
  {
    date: "Jul 20 - 09:00am",
    clinic_name: "Kurams Vet Clinic",
    amount: "N294,000",
    bank_name: "Wema Bank",
    bank_num: "0123456789",
  },
];

const selectOptions = [
  { label: "Cost", value: "cost" },
  { label: "Count", value: "count" },
];

const DashboardHome = () => {
  const [selectedOption, setselectedOption] = useState("");
  const [focus, setFocus] = useState(false);
  const { dashboard_info, loading } = useSelector((state) => state.dashboard);
  const { service_providers } = useSelector((state) => state.service_provider);
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [rating, setRating] = useState(0); //
  const dispatch = useDispatch();

  const handleRating = (rate) => {
    setRating(rate);
    // other logic
  };

  const transactionList = [
    {
      type: "Appointment Fee",
      client: "Ahmed Mohammed Soliu (Horse)",
      amount: " N5,000",
      time: "Jul 29,2022 - 10:07 am",
      status: "Credit",
    },
    {
      type: "Appointment Fee",
      client: "Ahmed Mohammed Soliu (Horse)",
      amount: " N5,000",
      time: "Jul 29,2022 - 10:07 am",
      status: "debit",
    },
    {
      type: "Appointment Fee",
      client: "Ahmed Mohammed Soliu (Horse)",
      amount: " N5,000",
      time: "Jul 29,2022 - 10:07 am",
      status: "Credit",
    },
  ];

  useEffect(() => {
    // dispatch(getDashboardInfo());
    getDashInfoFunc();
    dispatch(getServiceProviders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dataChartValue, setDataChartValue] = useState({
    labels: ["Appointment Count"],
    datasets: [
      {
        label: "Approved",
        data: [27],
        backgroundColor: "#5c9f3b",
      },
      {
        label: "Pending",
        data: [89],
        backgroundColor: "#FCA549",
      },
      {
        label: "Cancelled",
        data: [45],
        backgroundColor: "#CC0905",
      },
      {
        label: "Completed",
        data: [45],
        backgroundColor: "#7849fe",
      },
    ],
  });

  const [dataChartValueTwo, setDataChartValueTwo] = useState({
    labels: ["Appointment Cost"],
    datasets: [
      {
        label: "Approved",
        data: [27],
        backgroundColor: "#5c9f3b",
      },
      {
        label: "Pending",
        data: [89],
        backgroundColor: "#FCA549",
      },
      {
        label: "Cancelled",
        data: [45],
        backgroundColor: "#CC0905",
      },
      {
        label: "Completed",
        data: [45],
        backgroundColor: "#7849fe",
      },
    ],
  });

  const getDashInfoFunc = async () => {
    const data = await dispatch(getDashboardInfo());
    // console.log(data);
    if (data?.payload?.status === 200) {
      // console.log(data?.payload?.data?.appointment_status);
      const info = data?.payload?.data?.appointment_status;
      const infoTwo = data?.payload?.data?.appointment_cost;
      setDataChartValue((prev) => {
        return {
          ...prev,
          datasets: [
            {
              label: "Approved",
              data: [info?.approved],
              backgroundColor: "#a7e888",
              // barThickness: 50,
              // borderWidth: 10,
            },
            {
              label: "Pending",
              data: [info?.pending],
              backgroundColor: "#FCA549",
              // barThickness: 50,
              // borderWidth: 10,
            },
            {
              label: "Cancelled",
              data: [info?.cancelled],
              backgroundColor: "rgba(204, 9, 5, 0.7)",
              // barThickness: 50,
              // borderWidth: 10,
            },
            {
              label: "Completed",
              data: [info?.completed],
              backgroundColor: "#b398ff",
              // barThickness: 50,
              // borderWidth: 10,
            },
          ],
        };
      });
      setDataChartValueTwo((prev) => {
        return {
          ...prev,
          datasets: [
            {
              label: "Approved",
              data: [infoTwo?.approved],
              backgroundColor: "#a7e888",
              // barThickness: 50,
              // borderWidth: 10,
            },
            {
              label: "Pending",
              data: [infoTwo?.pending],
              backgroundColor: "#FCA549",
              // barThickness: 50,
              // borderWidth: 10,
            },
            {
              label: "Cancelled",
              data: [infoTwo?.cancelled],
              backgroundColor: "rgba(204, 9, 5, 0.7)",
              // barThickness: 50,
              // borderWidth: 10,
            },
            {
              label: "Completed",
              data: [infoTwo?.completed],
              backgroundColor: "#b398ff",
              // barThickness: 50,
              // borderWidth: 10,
            },
          ],
        };
      });
    }
  };

  const getStarrating = (param) => {
    if (param) {
      const arrayOfRate =
        dashboard_info?.service_providers?.top_service_providers?.map((chi) =>
          Number(chi?.appointment_count)
        );
      const maxNumber = Math.max(...arrayOfRate);
      const resultVal = (Number(param) / maxNumber) * 5;
      return resultVal;
    }
  };

  return (
    <div
      className="dashboard-home-wrap"
      onClick={() => {
        // console.log(dashboard_info);
      }}
    >
      {/* left box start */}
      <div className="left-box">
        {/* card wrap box start */}
        <div className="card-wrap-box">
          {/* card start */}
          <div className="card purple">
            <div className="wrap">
              <p className="value">
                {/* $ */}
                <CountUp
                  end={dashboard_info?.pets?.total || 0}
                  start={focus ? 0 : null}
                  redraw={false}
                >
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setFocus(true);
                        }
                      }}
                      //   offset={{
                      //     top: -1
                      //   }}
                    >
                      <span ref={countUpRef} />
                      {/* K+ */}
                    </VisibilitySensor>
                  )}
                </CountUp>
                {/* K+ */}
              </p>
              {/* <p className="value">243</p> */}
              <p className="label">Pets</p>
              <button
                onClick={() => history.push("/dashboard-pets")}
                className="btn"
              >
                <span>View All</span> <FaAngleRight className="icon" />
              </button>
            </div>
          </div>
          {/* card end */}
          {/* card start */}
          <div className="card blue">
            <div className="wrap">
              <p className={"value"}>
                {/* $ */}
                <CountUp
                  end={dashboard_info?.service_providers?.total || 0}
                  start={focus ? 0 : null}
                  redraw={false}
                >
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setFocus(true);
                        }
                      }}
                    >
                      <span ref={countUpRef} />
                      {/* K+ */}
                    </VisibilitySensor>
                  )}
                </CountUp>
                {/* K+ */}
              </p>
              {/* <p className="value">213</p> */}
              <p className="label">Service Providers</p>
              <button
                onClick={() => history.push("/dashboard-service-provider")}
                className="btn"
              >
                <span>View All</span> <FaAngleRight className="icon" />
              </button>
            </div>
          </div>
          {/* card end */}
          {/* card start */}
          <div className="card green">
            <div className="wrap">
              <p className={`value`}>
                {/* $ */}
                <CountUp
                  prefix={`N`}
                  suffix={`K`}
                  end={dashboard_info?.wallet?.users_total || 0}
                  start={focus ? 0 : null}
                  redraw={false}
                >
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setFocus(true);
                        }
                      }}
                    >
                      <span ref={countUpRef} />
                      {/* K+ */}
                    </VisibilitySensor>
                  )}
                </CountUp>
                {/* K+ */}
              </p>
              {/* <p className="value">N282k</p> */}
              <p className="label">Wallet Balance</p>
              <button className="btn">
                <span>View</span> <FaAngleRight className="icon" />
              </button>
            </div>
          </div>
          {/* card end */}
        </div>
        {/* card wrap box end */}
        {/* appointment stat box start */}
        <div className="appointment-stat-box">
          <p className="appointment-title">{`Appointments ${
            "Status"
          }`}</p>
          {/* chart detail box start */}
          <div className="chart-detail-box">
            <div className="chart-wrap">
              {/* <HomeChart /> */}
              <DashboardBarChart
                loading={loading}
                dataToShow={
                  selectedOption?.label === "Count"
                    ? dataChartValue
                    : selectedOption?.label === ""
                    ? dataChartValue : !selectedOption ? dataChartValue
                    : dataChartValueTwo
                }
              />
            </div>
            <div className="detail-wrap">
              <Select
                value={selectedOption}
                onChange={(e) => setselectedOption(e)}
                options={selectOptions}
                className="select-input"
                styles={reactSelectStyleChart}
                placeholder="Filter by"
              />
              {/* box start */}
              <div className="box" style={{margin: "auto 0rem"}}>
                <p className="bold">{`${sumAllNum([
                  dashboard_info?.appointment_status?.cancelled,
                  dashboard_info?.appointment_status?.approved,
                  dashboard_info?.appointment_status?.completed,
                  dashboard_info?.appointment_status?.pending,
                ])}k`}</p>
                <p className="light">Total Appointments</p>
              </div>
              {/* box end */}
              {/* box start */}
              {/* <div className="box">
                <p className="bold">312</p>
                <p className="light">Average per day</p>
              </div> */}
              {/* box end */}
            </div>
          </div>
          {/* chart detail box end */}
        </div>
        {/* appointment stat box end */}
        {/* service transaction box start */}
        <div className="service-transaction-box">
          {/* service box start */}
          <div className="service-box">
            <p className="service-title">Top Service Providers</p>
            {/* details box start */}
            <div className="detail-box">
              {dashboard_info?.service_providers?.top_service_providers?.map(
                (chi, idx) => {
                  const {
                    // appointments,
                    // appointments_done,
                    img,
                    name,
                    // rating,
                    status,
                  } = chi;
                  return (
                    <div key={idx} className="card-box">
                      <div className="img-wrap">
                        <figure className="img-box">
                          <img src={img || userImg} alt="" className="img" />
                        </figure>
                      </div>
                      {/* name text wrap start */}
                      <div className="name-text-box">
                        <p className="name">{lowerCaseWrap(name)}</p>
                        <p className="appointment">
                          Appointments : <span>{chi?.appointment_count}</span>
                        </p>
                      </div>
                      {/* name text wrap end */}
                      {/* rate- sta box start */}
                      <div className="rate-stat-box">
                        <div
                          className={`status-box  ${
                            status === "busy" && "status-busy"
                          }`}
                        >
                          <p className={`status`}>{status || "Active"}</p>
                        </div>
                        <div className="rate-box">
                          <Rating
                            iconsCount={1}
                            className={`rating`}
                            emptyColor="#FFA72C"
                            size={17}
                            allowHalfIcon
                            fillColor="#FFA72C"
                            onClick={handleRating}
                            ratingValue={getStarrating(chi?.appointment_count)}
                            allowHover={false}
                          />
                          <span className="number">
                            {getStarrating(chi?.appointment_count)}
                          </span>
                        </div>
                      </div>
                      {/* rate- sta box end */}
                    </div>
                  );
                }
              )}
              {/* see all request box start */}
              <div
                onClick={() => history.push("/dashboard-service-provider")}
                className="see-all-request-box"
              >
                <p className="text">
                  See{" "}
                  <span>
                    {Number(service_providers?.meta?.total) -
                      dashboard_info?.service_providers?.top_service_providers
                        ?.length}
                  </span>{" "}
                  more
                </p>
                <FaAngleRight className="icon" />
              </div>
              {/* seee all request box end */}
            </div>
            {/* details box end */}
          </div>
          {/* service box end */}
          {/* transaction box start */}
          <div className="transaction-box">
            <p className="transaction-title">Recent Transactions</p>
            {/* details box start */}
            <div className="detail-box">
              {transactionList.map((chi, idx) => {
                const { amount, client, status, time, type } = chi;
                return (
                  <div key={idx} className="card-wrap">
                    <div className="text-box">
                      <p className="transaction">
                        Transaction Type: <span>{type}</span>
                      </p>
                      <p className="client">
                        Client: <span>{client}</span>
                      </p>
                      <p className="amount">
                        Amount : <span>{amount}</span>
                      </p>
                      <p className="time">
                        Transaction Time : <span>{time}</span>
                      </p>
                    </div>
                    {/* stat view box start */}
                    <div className="status-view-box">
                      <div
                        className={`status ${
                          status === "debit" && "status-debit"
                        }`}
                      >
                        <p className="stat">{status}</p>
                      </div>
                      <div className="view">
                        <p>view</p>
                      </div>
                    </div>
                    {/* stat view box end */}
                  </div>
                );
              })}
              {/* see all request box start */}
              <div
                // onClick={() => history.push("/dashboard-service-provider")}
                className="see-all-request-box"
              >
                <p className="text">See All</p>
                <FaAngleRight className="icon" />
              </div>
              {/* seee all request box end */}
            </div>
            {/* details box end */}
          </div>
          {/* transaction box end */}
        </div>
        {/* service transaction box end */}
      </div>
      {/* left box end */}
      {/* right box start */}
      <div className="right-box">
        <div className="content-box">
          <p className="content-title">Payment Requests</p>

          {/* detail wrap box start */}
          <div className="detail-wrap-box">
            {paymentRequestList.map((chi, idx) => {
              const { amount, bank_name, bank_num, clinic_name, date } = chi;
              return (
                <div className="card-box">
                  <p className="date">{date}</p>
                  <p className="clinic-name">{clinic_name}</p>
                  <p className="amount">{amount}</p>
                  <div className="underline"></div>
                  <div className="bank-details">
                    <p className="bank-name">{bank_name}</p>
                    <p className="bank-num">{bank_num}</p>
                  </div>
                  <p className="small-name">{clinic_name}</p>

                  <button className="btn">Mark as Settled</button>
                </div>
              );
            })}
          </div>
          {/* detail wrap box end */}
          {/* see all request box start */}
          <div
            onClick={() => history.push("/dashboard-payment-request")}
            className="see-all-request-box"
          >
            <p className="text">
              See <span>All Requests</span>
            </p>
            <FaAngleRight className="icon" />
          </div>
          {/* seee all request box end */}
        </div>
      </div>
      {/* right box end */}
    </div>
  );
};

export default DashboardHome;
