import React from "react";
import "../../../../styles/dashboard/shop/Modal.css";
import sucImg from "../../../../assets/success-add-img.svg";
import { FaTimes } from "react-icons/fa";
import Button from "../../../../component/dashboard/fragments/Button";

const SuccessAddItemModal = ({ children, onClose }) => {
  return (
    <div className="accept-reject-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      <figure className="img-box">
        <img src={sucImg} alt="" className="img" />
      </figure>

      <p className="big-text">Shop Item has been successfully added</p>

      {/* {smallText && <p className="small-text">{smallText}</p>} */}
      <Button onClick={onClose} text={`Close`} bgColor={`#FFA72C`} />
      {children}
    </div>
  );
};

export default SuccessAddItemModal;
