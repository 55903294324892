import DashboardHome from "../../pages/dashboard/home/DashboardHome";
import UserAdmin from "../../pages/dashboard/user-admin";
import { appointment_route_group } from "./appointment";
import { blog_route_group } from "./blog";
import { payment_request_route_group } from "./payment-request";
import { pet_route_group } from "./pet";
import { service_provider_route_group } from "./service-provider";
import { Shop_group_routes } from "./shop";
import { support_route_group } from "./support";

export const Dashboard_route_group = [
  ...Shop_group_routes,
  ...blog_route_group,
  ...pet_route_group,
  ...service_provider_route_group,
  ...payment_request_route_group,
  ...support_route_group,
  ...appointment_route_group,
  {
    path: "/dashboard-user-admin",
    component: UserAdmin,
    title: "User Admin",
    smallTitle: "Review and Manage all users with access to this Admin tool",
  },
  {
    path: "/dashboard-home",
    component: DashboardHome,
    // title: `Hello  Kendrick,`,
    smallTitle: "Welcome Back",
  },
];
