import React, { useState } from "react";
import AuthLayout from "../../component/auth/layout";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/auth/AuthForm.css";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { loginUser } from "../../redux/user.js";
import { toast } from "react-toastify";
import { Dna } from "react-loader-spinner";

const Login = () => {
  const history = useHistory();
  const [errors, setErrors] = useState({
    msgOne: "",
    showMsgOne: false,
    msgTwo: "",
    showMsgTwo: false,
  });
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);
  const [details, setDetails] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShwoPassord] = useState(false);
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (details.email && details.password) {
      const data = await dispatch(loginUser(details));
      if (data) {
        const errors = data?.payload?.errors;
        // console.log(errors);
        // console.log(data);
        if (errors) {
          setErrors((prev) => {
            return { ...prev, msgOne: errors[0], showMsgOne: true };
          });
        }
        if (errors) {
          setErrors((prev) => {
            return { ...prev, msgTwo: errors[1], showMsgTwo: true };
          });
        }
        if (data.payload?.success) {
          // console.log("yes");
          localStorage.setItem("login", true);
          localStorage.setItem("token", data.payload?.token);
          toast.success(`Welcome back ${data.payload.user.name}`);
          history.push("/dashboard-home");
        }
      }
    }
  };
  return (
    <>
      <AuthLayout
        title="login"
        bigText={`Welcome Back`}
        smallText={`Enter your email address & password to access your Dashboard`}
      >
        <div
          // onClick={() => history.push("/sign-up")}
          className="auth-form-wrap"
        >
          {/* form start */}
          <form onSubmit={handleSubmit} action="" className="form">
            {/* form group start */}
            <div className="form-group">
              {/* <label htmlFor="email" className="form-label">
                <figure className="img-box">
                  <img src={profileIcon} className="img" alt="" />
                </figure>
              </label> */}
              <input
                type="email"
                name="email"
                id="email"
                className="form-input white-black-color"
                placeholder="Enter your email address"
                onChange={(e) => {
                  handleChange(e);
                  setErrors((prev) => {
                    return { ...prev, showMsgOne: false };
                  });
                }}
                value={details.email}
              />
              <p
                className={`placeholder  white-black-bg white-black-color ${
                  details.email && "placeholder-active"
                }`}
              >
                Email Address
              </p>
            </div>
            <p className="error">
              {errors.showMsgOne && <span>{errors.msgOne}</span>}
            </p>
            {/* form group end */}
            {/* form group start */}
            <div className="form-group">
              {/* <label htmlFor="Password" className="form-label">
                <figure className="img-box">
                  <img src={lockIcon} className="img" alt="" />
                </figure>
              </label> */}
              <input
                type={showPassword ? "text" : "Password"}
                name="password"
                id="Password"
                className="form-input white-black-color"
                placeholder="Enter your password"
                onChange={(e) => {
                  handleChange(e);
                  setErrors((prev) => {
                    return { ...prev, showMsgTwo: false };
                  });
                }}
                value={details.password}
              />
              <p
                className={`placeholder  white-black-bg white-black-color ${
                  details.password && "placeholder-active"
                }`}
              >
                Password
              </p>
              <p
                onClick={() => setShwoPassord(!showPassword)}
                className="show-hide"
              >
                {!showPassword ? (
                  <FaEyeSlash className="icon" />
                ) : (
                  <FaEye className="icon" />
                )}
              </p>
            </div>
            <p className="error">
              {errors.showMsgTwo && <span>{errors.msgTwo}</span>}
            </p>
            {/* form group end */}
            {/* link to forget page start */}
            <div className="forget-rem-password-box">
              {/* rem box start */}
              <div className="rem-box">
                <input
                  type="checkbox"
                  name="check"
                  id="check"
                  className="check"
                />
                <label htmlFor="check" className="label">
                  <span></span> Keep me logged in
                </label>
              </div>
              {/* rem box end */}
              <Link className="link" to="/reset-password">
                Recover Forgotten Password
              </Link>
            </div>
            {/* link to forget page end */}
            {/* submit form start */}
            {!loading ? (
              <input
                type="submit"
                value="Login"
                className="form-group btn-reuse form-submit"
              />
            ) : (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "3rem" }}
              >
                <Dna
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            {/* submit form  end */}
          </form>
          {/* form end */}
        </div>
      </AuthLayout>
    </>
  );
};

export default Login;
