import React, { useContext, useState } from "react";
import { FaTimes } from "react-icons/fa";
import "../../../styles/dashboard/ChangePasswordModal.css";
import Select from "react-select";
import Button from "../fragments/Button";
import ActionContext from "../../../context/ActionContext";
// import Button from "../../../../component/dashboard/fragments/Button";
// import Button from "../../../../../component/dashboard/fragments/Button";

const ChangePasswordModal = ({ onClose, onCreate }) => {
  const actionCtx = useContext(ActionContext);

  return (
    <div className="change-password-modal-wrap">
      <div
        onClick={() => actionCtx.setChangePassword(false)}
        className="close-icon"
      >
        <FaTimes className="icon" />
      </div>
      {/* end of cancel */}
      <p className="title">Reset Password</p>
      {/* form start */}
      <form action="" className="form">
        {/* two boxes start */}
        <div className="two-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Enter Old Password
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="text"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* two boxes end */}
        {/* three boxes start */}
        <div className="three-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Enter New Password
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="email"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* three boxes end */}
        {/* three boxes start */}
        <div className="three-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Reconfirm Password
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="email"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* three boxes end */}

        {/* button start */}
        <Button
          text={`Update Password`}
          btnStyle={`btn-reuse`}
          bgColor="#84BFCD"
          onClick={() => {
            actionCtx.setChangePassword(false);
            actionCtx.setChangePasswordSuccess(true)
          }}
        />
        {/* button end */}
      </form>
      {/* form end */}
    </div>
  );
};

export default ChangePasswordModal;
