import React, { useContext, useState } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import ActionContext from "../../../context/ActionContext";
import ItemInList from "../../../pages/dashboard/blog/ItemInList";
import "../../../styles/dashboard/ChangePasswordModal.css";

const SystemSettingsModal = () => {
  const [metricsList, setMetricsList] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [showMetricsAdd, setShowMetricsAdd] = useState(false);
  const [metricsActiveInput, setMetricsActiveInput] = useState(false);
  const [productCategoryList, setproductCategoryList] = useState([]);
  const [showproductCategoryAdd, setShowproductCategoryAdd] = useState(false);
  const [productCategoryActiveInput, setproductCategoryActiveInput] =
    useState(false);
  const [showModal, setShowModal] = useState({
    metric: false,
    category: false,
    detail: {},
    add_faq: false,
    edit_faq: false,
  });
  const actionCtx = useContext(ActionContext);
  const [activeBox, setActiveBox] = useState("pet");

  const handleAddToMetricsList = async (e) => {
    const obj = {
      name: e.target.value,
    };
    // if (e.key === "Enter" && e.target.value) {
    //   if (
    //     !shop_metrics.some(
    //       (vendor) =>
    //         vendor["name"].toLowerCase() === e.target.value.toLowerCase()
    //     )
    //   ) {
    //     const data = await dispatch(createShopMetric(obj));
    //     if (data?.payload?.success) {
    //       setReloadData(!reloadData);
    //       setMetricsActiveInput(false);
    //     }
    //   } else {
    //     toast.info(" Shop metrics already exist");
    //   }
    // }
  };

  const handleAddToProductCategoryList = async (e) => {
    const obj = {
      name: e.target.value,
      // id: id,
    };
    // if (e.key === "Enter" && e.target.value) {
    //   if (
    //     !shop_categories.some(
    //       (vendor) =>
    //         vendor["name"].toLowerCase() === e.target.value.toLowerCase()
    //     )
    //   ) {
    //     const data = await dispatch(createShopCategory(obj));
    //     if (data?.payload?.success) {
    //       setReloadData(!reloadData);
    //       setproductCategoryActiveInput(false);
    //     }
    //   } else {
    //     toast.info(" Shop product category already exist");
    //   }
    // }
  };

  return (
    <div className="system-setting-modal-wrap">
      <div
        onClick={() => actionCtx.setSystemSetting(false)}
        className="close-icon"
      >
        <FaTimes className="icon" />
      </div>
      {/* content start */}
      <div className="content-box">
        <p className="content-title">System Settings</p>
        <p className="content-text">
          Review and edit functions and Items on the Petme App
        </p>
        {/* switch box start */}
        <div className="switch-box">
          <p
            onClick={() => setActiveBox("pet")}
            className={`text ${activeBox === "pet" && "text-active"}`}
          >
            <span>Pets</span>
          </p>
          <p
            onClick={() => setActiveBox("service")}
            className={`text ${activeBox === "service" && "text-active"}`}
          >
            <span>Pet Services</span>
          </p>
        </div>
        {/* switch box end */}
        {activeBox === "pet" && (
          <>
            {/* metrics box start */}
            <div style={{ marginTop: "4rem" }} className="metrics-box">
              <p className="title">Support Categories</p>
              <div
                className="detail-box"
                onClick={() => {
                  metricsActiveInput && setMetricsActiveInput(false);
                  setShowMetricsAdd(true);
                }}
              >
                {metricsList?.length < 1 &&
                  !metricsActiveInput &&
                  !showMetricsAdd && <p className="info">Dog Breeds</p>}
                {metricsList?.map((chi, idx) => {
                  const { name, id } = chi;
                  return (
                    <ItemInList
                      //   loading={loading}
                      onRemove={() => {
                        setShowModal((prev) => {
                          return { ...prev, metric: true, detail: chi };
                        });
                      }}
                      key={id || idx}
                      text={name}
                    />
                  );
                })}
                {/* add box button start */}
                <div
                  className={`input-btn-box ${
                    metricsActiveInput && "input-btn-box-active"
                  }`}
                >
                  {metricsActiveInput && (
                    <input
                      onKeyPress={handleAddToMetricsList}
                      autoFocus={metricsActiveInput}
                      type="text"
                      className="metrics-input"
                    />
                  )}
                  {!metricsActiveInput && showMetricsAdd && (
                    <FaPlus
                      onClick={() => setMetricsActiveInput(true)}
                      className="icon"
                    />
                  )}
                </div>
                {/* add box button end */}
              </div>
              {/* detail box end */}
            </div>
            {/* metrics box end */}
          </>
        )}
        {activeBox === "service" && (
          <>
            {/* product category box start */}
            <div style={{ marginTop: "4rem" }} className="metrics-box">
              <p className="title">Services to display on the app</p>
              <div
                className="detail-box"
                onClick={() => setShowproductCategoryAdd(true)}
              >
                {metricsList?.length < 1 &&
                  !productCategoryActiveInput &&
                  !showproductCategoryAdd && (
                    <p className="info">Create & edit FAQs</p>
                  )}
                {metricsList?.map((chi, idx) => {
                  const { name, id } = chi;
                  return (
                    <ItemInList
                      //   loading={loading}
                      onRemove={() => {
                        setShowModal((prev) => {
                          return { ...prev, category: true, detail: chi };
                        });
                      }}
                      key={id || idx}
                      text={name}
                    />
                  );
                })}
                {/* add box button start */}
                <div
                  className={`input-btn-box ${
                    productCategoryActiveInput && "input-btn-box-active"
                  }`}
                >
                  {productCategoryActiveInput && (
                    <input
                      onKeyPress={handleAddToProductCategoryList}
                      autoFocus={productCategoryActiveInput}
                      type="text"
                      className="metrics-input"
                    />
                  )}
                  {!productCategoryActiveInput && showproductCategoryAdd && (
                    <FaPlus
                      onClick={() =>
                        setShowModal((prev) => {
                          return { ...prev, add_faq: true };
                        })
                      }
                      className="icon"
                    />
                  )}
                </div>
                {/* add box button end */}
              </div>
              {/* detail box end */}
            </div>
            {/* product category box end */}
          </>
        )}
      </div>
      {/* content end */}
    </div>
  );
};

export default SystemSettingsModal;
