import React from "react";
import CommonModal from "./CommonModal";

const SuccessConfirmModal = ({ children }) => {
  return (
    <>
      <CommonModal success bigText={`Item Delivery Successfully Confirmed`}>
        {children}
      </CommonModal>
    </>
  );
};

export default SuccessConfirmModal;
