import React from 'react'
import Button from '../../../../component/dashboard/fragments/Button'
import CommonModal from './CommonModal'

const CloseTicketModal = ({onClose, onCloseTicket}) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
        closeTicket
        bigText={`Are you sure you want to close this 
        Support Ticket?`}
      >
        <Button bgColor={`#00C013`} text="Close Ticket" onClick={onCloseTicket} />
      </CommonModal>
    </>
  )
}

export default CloseTicketModal