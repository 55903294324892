import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import setAuthToken from "../../utils/auth";
toast.configure();

export const getBlogPosts = createAsyncThunk(
  "admin/blog-posts",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      const { data } = await axios.get(`admin/blog/posts?paginate=${formData?.per_page || 10}&sort=${formData?.sort || ""}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      console.log("blog-posts", data);
      if (!data.success) {
        // console.log(data.message);
        toast.error(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      // console.log(data);
      //   await setAuthToken(data.token);
      await thunkAPI.dispatch(setBlogPosts(data.posts));
      //   return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const getBlogPost = createAsyncThunk(
  "admin/blog-posts",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      const { data } = await axios.get(`admin/blog/posts/${formData}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      // console.log("blog-posts", data.post);
      if (!data.success) {
        // console.log(data.message);
        toast.error(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      // console.log(data);
      //   await setAuthToken(data.token);
      await thunkAPI.dispatch(setBlogPost(data.post));
        return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const createBlogPost = createAsyncThunk(
  "admin/create-blog-post",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      //   for (const [key, value] of formData) {
      //     console.log(`${key}: ${value}\n`)
      //  }
      //   console.log("formData");
      // return
      // var data = new FormData();
      await setAuthToken(token);
      //   return
      const { data } = await axios.post("admin/blog/posts/create", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (!data.success) {
        console.log("blog post-------fail", data);
        if(data.message){
          toast.error(data.message, {
            theme: "colored",
          });
        }
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        toast.success("Blog post successfully created", {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateBlogPost = createAsyncThunk(
  "admin/update-blog-post",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const id = localStorage.getItem("blog-post-id");
      const { data } = await axios.post(
        `admin/blog/posts/${id}/update`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (!data.success) {
        if(data.message){
          toast.error(data.message, {
            theme: "colored",
          });
        }
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
     if(data?.success){
      toast.success(data.message || "Blog updated successfully", {
        theme: "colored",
      });
      return thunkAPI.rejectWithValue(data);
     }
    } catch (err) {
      console.log(err);
    }
  }
);

export const searchShopItem = createAsyncThunk(
  "admin/create-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const { term } = formData;
      // console.log(formData);
      // return
      // var data = new FormData();
      await setAuthToken(token);
      //   return
      const { data } = await axios.post(`admin/shop/items/search`, {
        term,
      });
      console.log(data.blog_posts);
      await thunkAPI.dispatch(setBlogPosts(data.blog_posts));
      if (!data.success) {
        // console.log(data.message);
        toast.error(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      // console.log("search", data);
      //   await setAuthToken(data.token);
      //   await thunkAPI.dispatch(login(data));
      //   return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteBlogPost = createAsyncThunk(
  "admin/delete-blog-post",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { id } = formData;
      const { data } = await axios.delete(`admin/blog/posts/${id}/delete`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      //   console.log('delete', data);
      if (!data.success) {
        toast.error("No blog post found for given data", {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      if(data?.success){
        toast.success(data.message, {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const publishShopItem = createAsyncThunk(
  "admin/publish-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { id } = formData;
      const { data } = await axios.post(`admin/shop/items/${id}/publish`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      //   console.log('delete', data);
      if (!data.success) {
        toast.error("No Shop item found for given data", {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      //   console.log(data.metrics.data);
      return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const blog_posts = createSlice({
  name: "blog_posts",
  initialState: {
    blog_data: {},
    blog_posts: [],
    blog_post: {},
    isAuth: false,
    loading: false,
    shop: [],

  },
  reducers: {
    setBlogPosts: (state, action) => {
      state.isAuth = true;
      state.blog_posts = action.payload.data;
      state.blog_data = action.payload;
      state.loading = false;
      state.shop = action.payload;
    },
    setBlogPost: (state, action) => {
      state.blog_post = action.payload
    }
  },
  extraReducers: {
    [getBlogPosts.pending]: (state) => {
      state.loading = true;
    },
    [getBlogPosts.fulfilled]: (state) => {
      state.loading = false;
    },
    [getBlogPosts.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getBlogPost.pending]: (state) => {
      state.loading = true;
    },
    [getBlogPost.fulfilled]: (state) => {
      state.loading = false;
    },
    [getBlogPost.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [createBlogPost.pending]: (state) => {
      state.loading = true;
    },
    [createBlogPost.fulfilled]: (state) => {
      state.loading = false;
    },
    [createBlogPost.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateBlogPost.pending]: (state) => {
      state.loading = true;
    },
    [updateBlogPost.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateBlogPost.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteBlogPost.pending]: (state) => {
      state.loading = true;
    },
    [deleteBlogPost.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteBlogPost.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [publishShopItem.pending]: (state) => {
      state.loading = true;
    },
    [publishShopItem.fulfilled]: (state) => {
      state.loading = false;
    },
    [publishShopItem.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBlogPosts , setBlogPost} = blog_posts.actions;

export default blog_posts.reducer;
