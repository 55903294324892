import React from "react";
import { FaTimes } from "react-icons/fa";
import "../../../../styles/dashboard/pet/modal/DewormVaccinationDietModal.css";
import noContentImg from "../../../../assets/deworm-content-img.png";
import Button from "../../../../component/dashboard/fragments/Button";
import moment from "moment";

const ViewUserModal = ({ onClose, detail }) => {
  const list = ["", ""];
  return (
    <div className="deworm-vaccination-diet-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* end oc cancel box */}
      {/* modal title start */}
      <p className="modal-title">User Details</p>
      {/* modal title end */}
      {/* content box start */}
      <div className="content-box">
        <div className="top-box">
          {/* box start */}
          <div className="box">
            <p className="light">Name</p>
            <p className="bold">{detail?.name}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            {/* <p className="light">Est Administration Duration</p>
            <p className="bold">5 minutes</p> */}
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Role</p>
            <p className="bold">{detail?.role || <>&nbsp;</>}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Email</p>
            <p className="bold">{detail?.email}</p>
          </div>
          {/* box end */}
        </div>
        {/* end of top box */}
        {/* bottom box start */}
        <div style={{gridTemplateColumns: '1fr 1fr'}} className="bottom-box">
          {/* box start */}
          <div className="box">
            <p className="light">Date Created</p>
            <p className="bold">{moment(detail?.created_at).format("YYYY - MM - DD hh:mm a")}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="light">Created by</p>
            <p className="bold">{detail?.name}</p>
          </div>
          {/* box end */}
        </div>
        {/* bottom box end */}
      </div>
      {/* content box end */}

      {/* close button start */}
      <Button
        onClick={onClose}
        text={`Close`}
        bgColor="#84BFCD"
        btnStyle={`btn-reuse`}
      />
      {/* close btn end */}
    </div>
  );
};

export default ViewUserModal;
