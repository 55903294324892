import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const OpenTicketModal = ({ onClose, onOpen }) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
        openTicket
        bigText={`Are you sure you want to reopen this 
        Support Ticket?`}
      >
        <Button bgColor={`#FFA72C`} text="Reopen Ticket" onClick={onOpen} />
      </CommonModal>
    </>
  );
};

export default OpenTicketModal;
