import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import setAuthToken from "../../utils/auth";
toast.configure();

export const getServiceProviders = createAsyncThunk(
  "admin/get-service-providers",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      const { data } = await axios.get("admin/service-providers", {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
        // console.log("service-provider--------------", data.service_providers);
      if (!data.success) {
        // console.log(data.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.items);
        //   await setAuthToken(data.token);
        await thunkAPI.dispatch(setServiceProvider(data.service_providers));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const getServiceProvidersPagination = createAsyncThunk(
  "admin/get-service-providers-pagination",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      const { data } = await axios.get(formData, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      //   console.log("service-provider", data.service_providers);
      if (!data.success) {
        // console.log(data.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.items);
        //   await setAuthToken(data.token);
        await thunkAPI.dispatch(setServiceProvider(data.service_providers));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const getServiceProvider = createAsyncThunk(
  "admin/create-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      //   return
      const { data } = await axios.get(
        `admin/service-providers/${formData?.id}/show`,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (!data.success) {
        // console.log(dataRes.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const deactivateActivateProvider = createAsyncThunk(
  "admin/create-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      //   return
      const { data } = await axios.post(
        `/admin/service-providers/publish?service_provider_id=${formData?.id}`,);
      // console.log(data);
      if (!data.success) {
        // console.log(dataRes.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateShopItem = createAsyncThunk(
  "admin/create-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      //   return
      const { data } = await axios.post(
        `admin/shop/items/${formData?.id}/update`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (!data.success) {
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const addDiscountToShopItem = createAsyncThunk(
  "admin/add-discount-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      //   return
      const { data } = await axios.post(
        `admin/shop/item/${formData?.shop_item_id}/discount/add`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (!data.success) {
        // console.log(data.errors);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const searchShopItem = createAsyncThunk(
  "admin/create-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const { term } = formData;
      // console.log(formData);
      // return
      // var data = new FormData();
      await setAuthToken(token);
      //   return
      const { data } = await axios.post(`admin/shop/items/search`, {
        term,
      });
      console.log(data.shop_items);
      await thunkAPI.dispatch(setServiceProvider(data.shop_items));
      if (!data.success) {
        // console.log(data.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      // console.log("search", data);
      //   await setAuthToken(data.token);
      //   await thunkAPI.dispatch(login(data));
      //   return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteServiceProvider = createAsyncThunk(
  "admin/delete-service-provider",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { id } = formData;
      const { data } = await axios.delete(
        `admin/service-providers/${id}/delete`,
        {}
      );
      if (!data.success) {
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const publishShopItem = createAsyncThunk(
  "admin/publish-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { id } = formData;
      const { data } = await axios.post(`admin/shop/items/${id}/publish`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      //   console.log('delete', data);
      if (!data.success) {
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const service_provider = createSlice({
  name: "service-provider",
  initialState: {
    service_providers: [],
    service_provider: [],
    isAuth: false,
    loading: false,
    shop: [],
    meta: {},
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setServiceProvider: (state, action) => {
      state.isAuth = true;
      state.service_providers = action.payload;
      state.loading = false;
      state.loadingActive = false;
      state.shop = action.payload;
    },
    // login: (state, action) => {
    //   state.shop_orders = action.payload.user;
    //   state.isAuth = true;
    //   state.loading = false;
    // },
    // logout: state => {
    //   delete axios.defaults.headers.common['Authorization'];
    //   localStorage.removeItem('token');
    //   state.user = null;
    //   state.isAuth = false;
    //   state.loading = false;
    //   state.balances = [];
    //   state = null;
    // }
  },
  extraReducers: {
    [getServiceProviders.pending]: (state) => {
      state.loading = true;
    },
    [getServiceProviders.fulfilled]: (state) => {
      state.loading = false;
    },
    [getServiceProviders.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getServiceProvidersPagination.pending]: (state) => {
      state.loading = true;
    },
    [getServiceProvidersPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [getServiceProvidersPagination.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getServiceProvider.pending]: (state) => {
      state.loading = true;
    },
    [getServiceProvider.fulfilled]: (state) => {
      state.loading = false;
    },
    [getServiceProvider.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateShopItem.pending]: (state) => {
      state.loading = true;
    },
    [updateShopItem.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateShopItem.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [addDiscountToShopItem.pending]: (state) => {
      state.loading = true;
    },
    [addDiscountToShopItem.fulfilled]: (state) => {
      state.loading = false;
    },
    [addDiscountToShopItem.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteServiceProvider.pending]: (state) => {
      state.loading = true;
    },
    [deleteServiceProvider.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteServiceProvider.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [publishShopItem.pending]: (state) => {
      state.loading = true;
    },
    [publishShopItem.fulfilled]: (state) => {
      state.loading = false;
    },
    [publishShopItem.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deactivateActivateProvider.pending]: (state) => {
      state.loadingActive = true;
    },
    [deactivateActivateProvider.fulfilled]: (state) => {
      state.loadingActive = false;
    },
    [deactivateActivateProvider.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingActive = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setServiceProvider } = service_provider.actions;

export default service_provider.reducer;
