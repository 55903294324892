import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const SuccessDeleteModal = ({ onClose }) => {
  return (
    <>
      <CommonModal onClose={onClose} success bigText={`Blog Item Successfully deleted`}>
        <Button bgColor={`#FFA72C`} text="Close" onClick={onClose} />
      </CommonModal>
    </>
  );
};

export default SuccessDeleteModal;
