import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import setAuthToken from "../../utils/auth";
toast.configure();

export const getShopItems = createAsyncThunk(
  "admin/shop-items",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      const { data } = await axios.get(`admin/shop/items?paginate=${formData?.per_page || 10}&sort=${formData?.sort || ""}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      // console.log('shop-items', data.shop_items);
      if (!data.success) {
        // console.log(data.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      // console.log(data.items);
      //   await setAuthToken(data.token);
      await thunkAPI.dispatch(setShoptems(data.shop_items));
      //   return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const getShopItemsagination = createAsyncThunk(
  "admin/shop-items",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      const { data } = await axios.get(formData, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      // console.log('shop-items', data);
      if (!data.success) {
        // console.log(data.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      // console.log(data.items);
      //   await setAuthToken(data.token);
      await thunkAPI.dispatch(setShoptems(data.shop_items));
      //   return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const createShopItem = createAsyncThunk(
  "admin/create-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      //   for (const [key, value] of formData) {
      //     console.log(`${key}: ${value}\n`)
      //  }
      // console.log(formData);
      // return
      // var data = new FormData();
      await setAuthToken(token);
      //   return
      const { data } = await axios.post("admin/shop/items/create", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (!data.success) {
        // console.log(dataRes.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if(data?.success){
        // console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateShopItem = createAsyncThunk(
  "admin/create-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      //   return
      const { data } = await axios.post(
        `admin/shop/items/${formData?.id}/update`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (!data.success) {
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if(data?.success){
        // console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const addDiscountToShopItem = createAsyncThunk(
  "admin/add-discount-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");

      await setAuthToken(token);
      //   return
      const { data } = await axios.post(
        `admin/shop/item/${formData?.shop_item_id}/discount/add`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (!data.success) {
        // console.log(data.errors);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if(data?.success){
        // console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
     
    } catch (err) {
      console.log(err);
    }
  }
);

export const searchShopItem = createAsyncThunk(
  "admin/create-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const { term } = formData;
      // console.log(formData);
      // return
      // var data = new FormData();
      await setAuthToken(token);
      //   return
      const { data } = await axios.post(`admin/shop/items/search`, {
        term,
      });
      console.log(data.shop_items);
      await thunkAPI.dispatch(setShoptems(data.shop_items));
      if (!data.success) {
        // console.log(data.message);
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      // console.log("search", data);
      //   await setAuthToken(data.token);
      //   await thunkAPI.dispatch(login(data));
      //   return thunkAPI.rejectWithValue(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteShopItem = createAsyncThunk(
  "admin/delete-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { id } = formData;
      const { data } = await axios.delete(`admin/shop/items/delete?shop_item_id=${id}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      //   console.log('delete', data);
      if (!data.success) {
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // toast.success(data?.message, {
        //   theme: "colored",
        // });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const publishShopItem = createAsyncThunk(
  "admin/publish-shop-item",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { id } = formData;
      const { data } = await axios.post(`admin/shop/items/${id}/publish`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      //   console.log('delete', data);
      if (!data.success) {
        if (Array.isArray(data.errors)) {
          toast.error(data.errors[0], {
            theme: "colored",
          });
        } else {
          toast.error(data.errors, {
            theme: "colored",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }
      if(data?.success){
        // console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        //   console.log(data.metrics.data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const shop_items = createSlice({
  name: "shop_items",
  initialState: {
    shop_orders: [],
    shop_items: [],
    isAuth: false,
    loading: false,
    shop: [],
    meta: {},
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setShoptems: (state, action) => {
      state.isAuth = true;
      state.shop_items = action.payload;
      state.loading = false;
      state.shop = action.payload;
    },
    // login: (state, action) => {
    //   state.shop_orders = action.payload.user;
    //   state.isAuth = true;
    //   state.loading = false;
    // },
    // logout: state => {
    //   delete axios.defaults.headers.common['Authorization'];
    //   localStorage.removeItem('token');
    //   state.user = null;
    //   state.isAuth = false;
    //   state.loading = false;
    //   state.balances = [];
    //   state = null;
    // }
  },
  extraReducers: {
    [getShopItems.pending]: (state) => {
      state.loading = true;
    },
    [getShopItems.fulfilled]: (state) => {
      state.loading = false;
    },
    [getShopItems.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [createShopItem.pending]: (state) => {
      state.loading = true;
    },
    [createShopItem.fulfilled]: (state) => {
      state.loading = false;
    },
    [createShopItem.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateShopItem.pending]: (state) => {
      state.loading = true;
    },
    [updateShopItem.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateShopItem.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [addDiscountToShopItem.pending]: (state) => {
      state.loading = true;
    },
    [addDiscountToShopItem.fulfilled]: (state) => {
      state.loading = false;
    },
    [addDiscountToShopItem.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteShopItem.pending]: (state) => {
      state.loading = true;
    },
    [deleteShopItem.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteShopItem.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [publishShopItem.pending]: (state) => {
      state.loading = true;
    },
    [publishShopItem.fulfilled]: (state) => {
      state.loading = false;
    },
    [publishShopItem.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setShoptems } = shop_items.actions;

export default shop_items.reducer;
