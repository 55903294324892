import React from "react";
import { Dna } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../component/dashboard/fragments/Button";
import { deleteBlogPost } from "../../../../redux/blog/BlogPosts";
import CommonModal from "./CommonModal";

const DeleteBlogModal = ({ onClose, onDeleteSuccess, detail, onFinish }) => {
  const { loading } = useSelector((state) => state.blog_posts);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const id = {
      id: detail?.id
    }
    const data = await dispatch(deleteBlogPost(id));
    if(data?.payload?.success){
      onDeleteSuccess()
      onFinish()
    }
  };

  return (
    <>
      <CommonModal
        onClose={onClose}
        deletee
        bigText={`Are you sure you want to delete this Blog? (${detail?.slug})`}
      >
        {!loading ? (
          <Button bgColor={`#DE0B0B`} text="Delete" onClick={handleSubmit} />
        ) : (
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            // wrapperStyle={{color: "red", backgroundColor : "red"}}
            wrapperClass="dna-wrapper"
          />
        )}
      </CommonModal>
    </>
  );
};

export default DeleteBlogModal;
