import React, { useState, useEffect } from "react";
// import DashboardLayout from "../../../component/dashboard/layout";
import ReuseableTable from "../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../component/dashboard/tables/TableRow";
import { shopItemList } from "../../../utils/ShopData";
import Modal from "react-awesome-modal";
import CommonHeader from "./CommonHeader";
import ViewShopItemModal from "./modals/ViewShopItemModal";
import UnlistItemModal from "./modals/UnlistItemModal";
// import Button from "../../../component/dashboard/fragments/Button";
import ListItemModal from "./modals/ListItemModal";
import DeleteItemModal from "./modals/DeleteItemModal";
import EditShopItemModal from "./modals/EditItemDetailModal";
import SuccessAddItemModal from "./modals/SuccessAddItemModal";
import AddShopItemModal from "./modals/AddItemModal";
import AddDiscountModal from "./modals/AddDiscountModal";
import SuccessDiscounModal from "./modals/SuccessDiscounModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getShopItems,
  getShopItemsagination,
  publishShopItem,
  searchShopItem,
} from "../../../redux/shop/ShopItems";
import { formatNumWithCommaNaira, trimLongString } from "../../../helpers/Helper";
import useDebounce from "../../../utils/UseDebounce";
// import { getShopCategories, getShopMetrics } from "../../../redux/shop/ShopSettings";

const ShopItems = () => {
  const dispatch = useDispatch();
  const { shop_items, loading } = useSelector(
    (state) => state.shop_items
  );
  const [indexNo, setIndexNo] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [listAction, setListAction] = useState(true);
  const [reloadTable, setReloadtable] = useState(false);
  const [showModal, setShowModal] = useState({
    view: false,
    edit: false,
    add: false,
    unlist: false,
    list: false,
    delete: false,
    confirmAdd: false,
    addDiscount: false,
    successDiscount: false,
  });
  const [singleItem, setSingleItem] = useState({});

  useEffect(() => {
    // dispatch(getShopMetrics());
    // dispatch(getShopCategories());
    dispatch(getShopItems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadTable]);

  const handleFinishAction = () => {
    setSingleItem({});
    setShowModal((prev) => {
      return {
        ...prev,
        view: false,
        edit: false,
        add: false,
        unlist: false,
        list: false,
        delete: false,
        confirmAdd: false,
        addDiscount: false,
        successDiscount: false,
      };
    });
    setReloadtable(!reloadTable);
  };

  const handleListItem = async (child, param) => {
    setSingleItem(child);
    // console.log(child);
    setListAction(param);
    const payload = { id: child?.id };
    dispatch(publishShopItem(payload));
    if (param === "list") {
      setShowModal((prev) => {
        return { ...prev, list: true };
      });
    }
    if (param === "unlist") {
      setShowModal((prev) => {
        return { ...prev, unlist: true };
      });
    }
  };

  const handleEditSingleitem = (item, param) => {
    if (item === "metric") {
      setSingleItem((prev) => {
        return { ...prev, metric: param };
      });
    }
    if (item === "category") {
      setSingleItem((prev) => {
        return { ...prev, category: param };
      });
    }
    if(item === "image"){
      console.log(param);
    }

    const data = { ...singleItem, [item]: param };
    setSingleItem(data);
  };

  // const handleSearch = (term) => {
  //   // console.log(term);
  //   const payload = {
  //     term: term,
  //   };
  //   dispatch(searchShopItem(payload));
  // };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
      const payload = {
        term: search,
      };
      dispatch(searchShopItem(payload));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handlePagination = async (param) => {
    const linkSplit = param.split("api/");
    const link = `${linkSplit[1]}&paginate=${10}`;
    // console.log(param);
    // console.log(link);
    // return
    dispatch(getShopItemsagination(link));
  };


  return (
    <>
      <div onClick={() => {
        // console.log(shop_items);
      }} className="">
        {/* <DashboardLayout
        pageTitle={`Shop`}
        pageSmallText={`Review and Manage all sales and shop listing on Petme`}
      > */}
        {/* common headr start */}
        <CommonHeader
          onSearch={(e) => setSearch(e)}
          onClickBtn={() =>
            setShowModal((prev) => {
              return { ...prev, add: true };
            })
          }
        />
        {/* common header end */}

        {/* table start  */}
        <ReuseableTable
          loading={loading}
          one={shopItemList.tableHead[0]}
          two={shopItemList.tableHead[1]}
          three={shopItemList.tableHead[2]}
          four={shopItemList.tableHead[3]}
          five={shopItemList.tableHead[4]}
          twoDrop
          threeDrop
          fourDrop
          fiveDrop
          action
          moreThanFive={shop_items?.data?.length >= 3}
          toNo={shop_items?.meta?.to}
          totalNo={shop_items?.meta?.total}
          fromNo={shop_items?.meta?.from}
          nextPage={shop_items?.links?.next}
          prevPage={shop_items?.links?.prev}
          onNext={() => {
            handlePagination(shop_items?.links?.next);
          }}
          onPrev={() => {
            handlePagination(shop_items?.links?.prev);
          }}
        >
          {shop_items?.data?.map((child, index) => {
            const {
              name,
              quantity,
              cost,
              status,
              id,
              metric,
              shop_item_name,
              images,
              // description
            } = child;
            const img_text = {
              img: images ? `${images[0]?.image}` : "",
              des: `${trimLongString(name, 15) || shop_item_name} ${metric ? metric?.name : ""}`,
            };
            return (
              <TableRow
                loading={loading}
                setIndexNo={() => setIndexNo(id)}
                rowNo={id}
                indexNo={indexNo}
                key={id || index}
                action
                img_text={img_text}
                one={quantity || "---"}
                two={formatNumWithCommaNaira(cost)}
                three={"---"}
                // action
                status={status || "---"}
                statusColor={
                  status?.toLowerCase() === "listed"
                    ? "deliver"
                    : status?.toLowerCase() === "published"
                    ? "deliver"
                    : "pending"
                }
                // pending_order={order_status.toLowerCase() === "pending"}
                viewText={`View Item Details`}
                onViewDetail={() =>
                  setShowModal((prev) => {
                    // console.log(child);
                    setSingleItem(child);
                    return { ...prev, view: true };
                  })
                }
                onUnList={() => handleListItem(child, "unlist")}
                onList={() => handleListItem(child, "list")}
                // editText={`Edit Item Details`}
                onEdit={() => {
                  const newChild = {
                    ...child,
                    metric: {
                      ...child?.metric,
                      label: child?.metric?.name,
                      value: child?.metric?.id,
                    },
                    category: {
                      ...child?.category,
                      label: child?.category?.name,
                      value: child?.category?.id,
                    },
                  };
                  console.log(newChild);
                  setSingleItem(newChild);
                  setShowModal((prev) => {
                    return { ...prev, edit: true };
                  });
                }}
                addText={`Add Discount`}
                onAdd={() => {
                  setShowModal((prev) => {
                    return { ...prev, addDiscount: true };
                  });
                  setSingleItem(child);
                  console.log(child);
                }}
                unListText={
                  status?.toLowerCase() === "listed"
                    ? "Unlist Item"
                    : status?.toLowerCase() === "published" && "Unlist Item"
                }
                listText={status?.toLowerCase() === "pending" && "list Item"}
                deleteText={`Delete Item`}
                onDelete={() => {
                  setSingleItem(child);
                  setShowModal((prev) => {
                    return { ...prev, delete: true };
                  });
                }}
              />
            );
          })}
        </ReuseableTable>
        {/* table end */}
        {/* </DashboardLayout> */}
      </div>
      {/* view modal start */}
      <Modal visible={showModal.view} effect="fadeInUp">
        <ViewShopItemModal
          detail={singleItem}
          onClose={() =>
            setShowModal((prev) => {
              setSingleItem({});
              return { ...prev, view: false };
            })
          }
        ></ViewShopItemModal>
      </Modal>
      {/* view modal end */}
      {/* unlist modal start */}
      <Modal visible={showModal.unlist} effect="fadeInUp">
        <UnlistItemModal
          // loading={true}
          details={singleItem}
          onClose={handleFinishAction}
        ></UnlistItemModal>
      </Modal>
      {/* unlist modal end */}
      {/* list modal start */}
      <Modal visible={showModal.list} effect="fadeInUp">
        <ListItemModal
          // loading={true}
          details={singleItem}
          onClose={handleFinishAction}
        ></ListItemModal>
      </Modal>
      {/* list modal end */}
      {/* delete item modal start */}
      <Modal visible={showModal.delete} effect="fadeInUp">
        <DeleteItemModal
          onDeleteFinish={handleFinishAction}
          details={singleItem}
          onClose={() => {
            setSingleItem({});
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
          }}
        ></DeleteItemModal>
      </Modal>
      {/* delete item modal end */}
      {/* delete item modal start */}
      <Modal visible={showModal.edit} effect="fadeInUp">
        <EditShopItemModal
          onEditSingleItem={handleEditSingleitem}
          shop_item={singleItem}
          loading={loading}
          onSubmit={() => {
            // setSingleItem({});
            setShowModal((prev) => {
              return { ...prev, confirmAdd: true, edit: false };
            });
          }}
          onClose={() => {
            // setSingleItem({});
            setShowModal((prev) => {
              return { ...prev, edit: false };
            });
          }}
        ></EditShopItemModal>
      </Modal>
      {/* delete item modal end */}
      {/* delete item modal start */}
      <Modal visible={showModal.add} effect="fadeInUp">
        <AddShopItemModal
          loading={loading}
          onSubmit={() =>
            setShowModal((prev) => {
              return { ...prev, confirmAdd: true, add: false };
            })
          }
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, add: false };
            })
          }
        ></AddShopItemModal>
      </Modal>
      {/* delete item modal end */}
      {/* add item modal start */}
      <Modal visible={showModal.confirmAdd} effect="fadeInUp">
        <SuccessAddItemModal onClose={handleFinishAction}></SuccessAddItemModal>
      </Modal>
      {/* add item modal end */}
      {/* add discount modal start */}
      <Modal visible={showModal.addDiscount} effect="fadeInUp">
        <AddDiscountModal
          detail={singleItem}
          onApply={() => {
            setShowModal((prev) => {
              return { ...prev, addDiscount: false, successDiscount: true };
            });
          }}
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, addDiscount: false };
            })
          }
        ></AddDiscountModal>
      </Modal>
      {/* add discount modal end */}
      {/* add discount modal start */}
      <Modal visible={showModal.successDiscount} effect="fadeInUp">
        <SuccessDiscounModal
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, successDiscount: false };
            });
            setReloadtable(!reloadTable);
          }}
        ></SuccessDiscounModal>
      </Modal>
      {/* add discount modal end */}
    </>
  );
};

export default ShopItems;
