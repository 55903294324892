import React from 'react'
import Button from '../../../../component/dashboard/fragments/Button'
import CommonModal from './CommonModal'

const ActivateModal = ({onClose, onReactivate}) => {
  return (
    <>
      <CommonModal
        onClose={onClose}
        activate
        //   smallText={`The Pet Parent has been notified via mail`}
        bigText={"Are you sure you want to reactivate this user ?"}
      >
        <Button bgColor={`#00C013`} text="Reactivate" onClick={onReactivate} />
      </CommonModal>
    </>
  )
}

export default ActivateModal