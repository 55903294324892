import React from "react";
import "../../../../styles/dashboard/shop/Modal.css";
import sucImg from "../../../../assets/delete-info.svg";
import { FaTimes } from "react-icons/fa";
import Button from "../../../../component/dashboard/fragments/Button";
import { useSelector } from "react-redux";
import { Dna } from "react-loader-spinner";

const DeleteShopSettingModal = ({
  children,
  onClose,
  name,
  type,
  onDelete,
}) => {
  const { loading } = useSelector((state) => state.shop_settings);
  return (
    <div className="accept-reject-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      <figure className="img-box">
        <img src={sucImg} alt="" className="img" />
      </figure>

      <p className="big-text">
        {`Are you sure you want to delete this Shop ${name} ${type} ?`}
      </p>

      {/* {smallText && <p className="small-text">{smallText}</p>} */}
      {!loading ? (
        <Button
          onClick={onDelete}
          text={ `Delete Shop ${type}`}
          bgColor={`#DE0B0B`}
        />
      ) : (
        <div
          className="dna-wrapper-box"
          style={{ alignSelf: "center", marginTop: "3rem" }}
        >
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            // wrapperStyle={{color: "red", backgroundColor : "red"}}
            wrapperClass="dna-wrapper"
          />
        </div>
      )}
      {children}
    </div>
  );
};

export default DeleteShopSettingModal;
