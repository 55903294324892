import React from "react";
import "../../../../styles/dashboard/shop/Modal.css";
import sucImg from "../../../../assets/delete-info.svg";
import { FaTimes } from "react-icons/fa";
import Button from "../../../../component/dashboard/fragments/Button";
import { useDispatch, useSelector } from "react-redux";
import { deleteShopItem } from "../../../../redux/shop/ShopItems";
import { toast } from "react-toastify";
import ImageSpinner from "../../../../component/dashboard/fragments/ImageSpinner";

const DeleteItemModal = ({ children, onClose, details , onDeleteFinish }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.shop_items);

  const handleDelete = async (id) => {
    const payload = {
      id: id,
    }
    // console.log(payload);
    // return
     const data = await dispatch(deleteShopItem(payload))
     if(data?.payload?.success){
      // console.log(data?.payload);
      toast.success(
        `Shop item ${details?.name}  deleted successfully`,
        {
          theme: "colored",
        }
      );
      onDeleteFinish()
     }
  }
  if(loading){
    return(
      <ImageSpinner />
    )
  }
  return (
    <div className="accept-reject-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      <figure className="img-box">
        <img src={sucImg} alt="" className="img" />
      </figure>

      <p className="big-text">
       {` Are you sure you want to delete this ${details?.name} from Shop Items?`}
      </p>

      {/* {smallText && <p className="small-text">{smallText}</p>} */}
      <Button onClick={() => handleDelete(details?.id) } text={`Delete Shop Item`} bgColor={`#DE0B0B`} />
      {children}
    </div>
  );
};

export default DeleteItemModal;
