import { logDOM } from "@testing-library/react";
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Dna } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../component/dashboard/fragments/Button";
import { formatNumWithComma } from "../../../../helpers/Helper";
import { addDiscountToShopItem } from "../../../../redux/shop/ShopItems";
import "../../../../styles/dashboard/shop/Modal.css";

const AddDiscountModal = ({ onClose, onApply, detail }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.shop_items);
  const [details, setDetails] = useState({
    shop_item_id: "",
    percent: "",
    period: "",
  });
  const [discountShow, setDiscountShow] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async () => {
    const obj = {
      percent: Number(details?.percent),
      shop_item_id: Number(detail?.id),
    };
    const data = await dispatch(addDiscountToShopItem(obj));
    if (data?.payload?.success) {
      setDetails({
        shop_item_id: "",
        percent: "",
        period: "",
      });
      onApply();
    }
  };

  const convertPercent = (param) => {
    const val = Math.floor((detail?.cost * param) / 100);
    setDiscountShow(formatNumWithComma(String(val)));
  };

  return (
    <div className="add-discount-modal-wrap">
      <div
        onClick={() => {
          onClose();
          setDetails({
            shop_item_id: "",
            percent: "",
            period: "",
          });
        }}
        className="close-icon"
      >
        <FaTimes className="icon" />
      </div>
      <p className="title">Add Discount</p>
      <p className="text">
        Enter Discount % and validity period to apply discount rule
      </p>
      {/* two groups start */}
      <div className="two-group two-group-extra">
        <div style={{ gridColumn: "1 / -1" }} className="form-group">
          <label htmlFor="">Discount</label>
          <div className="wrap">
            <input
              onChange={(e) => {
                handleChange(e);
                convertPercent(e.target.value);
              }}
              name="percent"
              value={details?.percent}
              type="text"
            />
            <p className="unit">%</p>
          </div>
        </div>
        {/* <div className="form-group">
          <label htmlFor="">Validity Period</label>
          <div className="wrap">
            <input onChange={handleChange} name="period" value={details?.period} type="text" />
            <p className="unit">Days</p>
          </div>
        </div> */}
      </div>
      {/* two groups end */}
      {/* two group start */}
      <div className="two-group two-group-bottom">
        {/* box start */}
        <div className="box">
          <p className="key">Current Price</p>
          <p className="value">{`N${formatNumWithComma(
            String(detail?.cost)
          )}`}</p>
        </div>
        {/* box end */}
        {/* box start */}
        <div className="box">
          <p className="key">Discount Price</p>
          <p className="value">{discountShow && `N${discountShow}`}</p>
        </div>
        {/* box end */}
      </div>
      {/* two gorup end */}
      {/* apply btn start */}
      {!loading ? (
        <Button
          onClick={() => {
            details?.percent && handleSubmit();
          }}
          text="Apply Discount"
          bgColor={details?.percent ? `#FFA72C` : `#bbbbbb`}
        />
      ) : (
        <div className="dna-wrapper-box" style={{ alignSelf: "center" }}>
          <Dna
            visible={true}
            height="80"
            width="100"
            ariaLabel="dna-loading"
            // wrapperStyle={{color: "red", backgroundColor : "red"}}
            wrapperClass="dna-wrapper"
          />
        </div>
      )}
      {/* apply btn end */}
    </div>
  );
};

export default AddDiscountModal;
