import React, { useEffect, useState } from "react";
import ReuseableTable from "../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../component/dashboard/tables/TableRow";
import CommonHeader from "./CommonHeader";
import Modal from "react-awesome-modal";
import DeletePetdetailModal from "./modals/DeletePetModal";
import SuccessDeletePetModal from "./modals/SuccessDeletePetModal";
import CreateAppointmentModal from "./modals/CreateAppointmentModal";
import SuccessCreateAppointmentModal from "./modals/SuccessCreateAppointmentModal";
import { useHistory } from "react-router-dom";
import DewormDetailModal from "./modals/DewormDetailModal";
import VaccinationDetailModal from "./modals/VaccinationDetailModal";
import DietDetailModal from "./modals/DietDetailModal";
import { useDispatch, useSelector } from "react-redux";
import {
  // getDewormDetails,
  getPets,
  getPetsPagination,
  getPetTypes,
} from "../../../redux/pets/petTypes";

const DashboardPets = () => {
  const dispatch = useDispatch();
  const { pets, loading } = useSelector((state) => state.pet_type);
  const [indexNo, setIndexNo] = useState("");
  const history = useHistory();
  const [showModal, setShowModal] = useState({
    view: false,
    success_delete: false,
    delete: false,
    create_appointment: false,
    create_appointment_success: false,
    deworm: false,
    diet: false,
    vaccine: false,
  });
  const [singleChild, setSingleChild] = useState({});

  useEffect(() => {
    //  console.log("yes");
    dispatch(getPetTypes());
    dispatch(getPets());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePagination = async (param) => {
    const linkSplit = param.split("api/");
    const link = linkSplit[1];
    dispatch(getPetsPagination(link));
  };

  return (
    <>
      <div className="dashboard-pet-wrap">
        {/* common headr start */}
        <CommonHeader
          onSearch={(e) => console.log(e)}
          // onClickBtn={() => history.push("/dashboard-pets-create-pet-detail")}
        />
        {/* common header end */}
        {/* table start */}
        <ReuseableTable
          sN
          one={`Pet`}
          two={`Pet Parent`}
          three={`Phone No`}
          four={"Type"}
          five={`Sex`}
          six={`Reg No`}
          fiveDrop
          twoDrop
          threeDrop
          fourDrop
          action
          moreThanFive={pets?.data?.length >= 3}
          toNo={pets?.to}
          totalNo={pets?.total}
          fromNo={pets?.from}
          nextPage={pets?.next_page_url}
          prevPage={pets?.prev_page_url}
          onNext={() => handlePagination(pets?.next_page_url)}
          onPrev={() => handlePagination(pets?.prev_page_url)}
        >
          {pets?.data?.map((chi, idx) => {
            const { name, user, type, gender, registration_number } = chi;
            return (
              <TableRow
                loading={loading}
                setIndexNo={() => setIndexNo(idx)}
                rowNo={idx}
                indexNo={indexNo}
                key={idx}
                sN={idx + 1}
                one={name}
                two={user?.name}
                three={user?.mobile}
                four={type?.name}
                five={gender}
                six={registration_number}
                action
                viewText={`View Pet Details`}
                onViewDetail={() => {
                  localStorage.setItem("pet-id", chi?.id);
                  history.push("/dashboard-pets-view-pet-detail");
                }}
                // editText={`Edit Pet Details`}
                onEdit={() => history.push("/dashboard-pets-edit-pet-detail")}
                // deleteText={"Delete Pet Details"}
                // createText="Create Appointment"
                onAdd={() =>
                  setShowModal((prev) => {
                    return { ...prev, create_appointment: true };
                  })
                }
                dewormtext={`Deworming Details`}
                onDeworm={() => {
                  setShowModal((prev) => {
                    return { ...prev, deworm: true };
                  });
                  setSingleChild(chi);
                  // console.log(chi);
                }}
                onvaccinate={() => {
                  setShowModal((prev) => {
                    return { ...prev, vaccine: true };
                  });
                  setSingleChild(chi)
                  // console.log(chi);
                }}
                onDiet={() => {
                  setShowModal((prev) => {
                    return { ...prev, diet: true };
                  });
                  setSingleChild(chi);
                }}
                vaccinateText={`Vaccination Details`}
                dietText="Diet Details"
                onDelete={() =>
                  setShowModal((prev) => {
                    return { ...prev, delete: true };
                  })
                }
                // addText={`Create Appointment`}
              />
            );
          })}
        </ReuseableTable>
        {/* table end */}
      </div>
      {/* delet pet detail modal start */}
      <Modal visible={showModal.delete}>
        <DeletePetdetailModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, delete: false };
            })
          }
          onDeleteSuccess={() =>
            setShowModal((prev) => {
              return { ...prev, delete: false, success_delete: true };
            })
          }
        />
      </Modal>
      {/* delet pet detail modal end */}
      {/* delet pet detail modal start */}
      <Modal visible={showModal.success_delete}>
        <SuccessDeletePetModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, success_delete: false };
            })
          }
        />
      </Modal>
      {/* delet pet detail modal end */}
      {/* create appointment pet detail modal start */}
      <Modal visible={showModal.create_appointment}>
        <CreateAppointmentModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, create_appointment: false };
            })
          }
          onCreateAppointment={() =>
            setShowModal((prev) => {
              return {
                ...prev,
                create_appointment: false,
                create_appointment_success: true,
              };
            })
          }
        />
      </Modal>
      {/* create appointment pet detail modal end */}
      {/* create appointment pet detail modal start */}
      <Modal visible={showModal.create_appointment_success}>
        <SuccessCreateAppointmentModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, create_appointment_success: false };
            })
          }
        />
      </Modal>
      {/* create appointment pet detail modal end */}
      {/* deworm detail pet detail modal start */}
      <Modal visible={showModal.deworm}>
        <DewormDetailModal
          detail={singleChild}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, deworm: false };
            });
            setSingleChild({});
          }}
        />
      </Modal>
      {/* deworm detail pet detail modal end */}
      {/* vaccination detail pet detail modal start */}
      <Modal visible={showModal.vaccine}>
        <VaccinationDetailModal
          detail={singleChild}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, vaccine: false };
            });
            setSingleChild({});
          }}
        />
      </Modal>
      {/* vaccination detail pet detail modal end */}
      {/*diet detail pet detail modal start */}
      <Modal visible={showModal.diet}>
        <DietDetailModal
          detail={singleChild}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, diet: false };
            });
            setSingleChild({});
          }}
        />
      </Modal>
      {/*diet detail pet detail modal end */}
    </>
  );
};

export default DashboardPets;
