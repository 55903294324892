import React from "react";
import { FaTimes } from "react-icons/fa";
import Button from "../../../../component/dashboard/fragments/Button";
import "../../../../styles/dashboard/support/ViewTicketModal.css";

const AddFaqModal = ({ onClose, onAdd }) => {
  return (
    <div className="add-new-faq-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* end of cancel */}
      <p className="title">Add a FAQ</p>
      {/* form start */}
      <form action="" className="form">
        {/* two boxes start */}
        <div className="two-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
              Question
            </label>
            <div className="input-wrap">
              <input
                name="name"
                // onChange={handleChange}
                placeholder=""
                type="text"
                className="input"
              />
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* two boxes end */}
        {/* two boxes start */}
        <div className="two-group-boxes">
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Metrics" className="label">
            Answer
            </label>
            <div className="input-wrap">
              <textarea
                name=""
                id=""
                cols="30"
                rows="15"
                className="input"
              ></textarea>
            </div>
          </div>
          {/* form group end */}
        </div>
        {/* two boxes end */}
         {/* button start */}
         <Button
          text={`Add FAQ`}  
          btnStyle={`btn-reuse`}
          bgColor="#84BFCD"
          onClick={onAdd}
        />
        {/* button end */}
      </form>
      {/* form end */}
    </div>
  );
};

export default AddFaqModal;
