import React from "react";
import "../../styles/auth/SuccessModal.css";
import succImg from "../../assets/email-verify-img.png";

const SuccessModal = ({ textOne, textTwo, textThree, children }) => {
  return (
    <div className="success-modal-wrap">
      <figure className="img-box">
        <img src={succImg} alt="" className="img" />
      </figure>
      {textOne && <p className="text-one">{textOne}</p>}
      {textTwo && <p className="text-two">{textTwo}</p>}
      {textThree && <p style={{ marginTop: "-1rem"}} className="text-two">{textThree}</p>}
      {children}
    </div>
  );
};

export default SuccessModal;
