import React from "react";
import loadr from "../../../assets/loading-gif.gif"

const ImageSpinner = () => {
  return (
    <>
      <figure style={{ width: "20rem", height: "20rem", borderRadius: "2rem" }}>
        <img
          style={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
            display: "block",
          }}
          src={loadr}
          alt=""
        />
      </figure>
    </>
  );
};

export default ImageSpinner;
