import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import Flatpicker from "react-flatpickr";
import "flatpickr/dist/themes/confetti.css";
import moment from "moment";
import calendarIcon from "../../../../assets/calendar-icon.svg";
import Button from "../../../../component/dashboard/fragments/Button";
import { shopOrderList } from "../../../../utils/ShopData";
import TableRow from "../../../../component/dashboard/tables/TableRow";
import ReuseableTable from "../../../../component/dashboard/tables/ReuseableTable";

const OrderHistorySelectModal = ({ onClose, onClickTableRow }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [orderList, setOrderList] = useState([]);

  return (
    <div className="order-history-modal-wrap">
      <div onClick={onClose} className="close-icon">
        <FaTimes className="icon" />
      </div>
      {/* end of close wrap */}
      <p className="title">Order History</p>
      {orderList.length < 1 && (
        <>
          <p className="text">Select a Date range to fetch orders for</p>
          {/* calendar box start */}
          <div className="calender-box">
            <p className="text">from</p>
            <div className="form-group">
              <label htmlFor="from" className="label">
                <figure className="img-box">
                  <img src={calendarIcon} alt="" className="img" />
                </figure>
              </label>
              <Flatpicker
                id="from"
                name="from"
                data-enable-time
                //   cal
                className="input-calender"
                autoComplete="off"
                value={fromDate}
                onChange={(date) =>
                  setFromDate(moment(date[0]).format("YYYY/MM/DD"))
                }
                placeholder="DD / MM / YYYY"
              />
            </div>
            <p style={{ marginLeft: "2rem" }} className="text">
              to
            </p>
            <div className="form-group">
              <label htmlFor="to" className="label">
                <figure className="img-box">
                  <img src={calendarIcon} alt="" className="img" />
                </figure>
              </label>
              <Flatpicker
                data-enable-time
                id="to"
                name="to"
                //   cal
                className="input-calender"
                autoComplete="off"
                value={toDate}
                onChange={(date) =>
                  settoDate(moment(date[0]).format("YYYY/MM/DD"))
                }
                placeholder="DD / MM / YYYY"
              />
            </div>
          </div>
          {/* calendar box end */}

          {/* butn start */}
          <Button
            onClick={() => setOrderList(shopOrderList.tableBody)}
            text={`fetch`}
            bgColor={`#FFA72C`}
          />
          {/* butn end */}
        </>
      )}

      {/* order table box start  */}

      {orderList.length >= 1 && (
        <>
          <div className="title-box">
            <p className="text-one">
              Displaying <span>243</span> results from <span>23/11/2021</span>{" "}
              to <span>01/01/2022</span>{" "}
              <span
                style={{ color: "#FFA72C", cursor: "pointer" }}
                onClick={() => setOrderList([])}
                className="yellow"
              >
                Reset
              </span>
            </p>
            <p className="export">Export List</p>
          </div>
          {/* table start */}
          {/* table start  */}
          <div className="table-box">
            <ReuseableTable
              one={shopOrderList.tableHead[0]}
              two={shopOrderList.tableHead[1]}
              three={shopOrderList.tableHead[2]}
              four={shopOrderList.tableHead[3]}
              five={shopOrderList.tableHead[4]}
              fourDrop
              fiveDrop
              sixDrop
            >
              {shopOrderList.tableBody.map((child, index) => {
                const {
                  item_count,
                  item_cost,
                  order_id,
                  customer,
                  payment_mode,
                } = child;
                return (
                  <TableRow
                    onClickTableRow={onClickTableRow}
                    key={index}
                    one={order_id}
                    two={customer}
                    three={payment_mode}
                    four={item_count}
                    five={item_cost}
                  />
                );
              })}
            </ReuseableTable>
          </div>
          {/* table end */}
          {/* table end */}
        </>
      )}
      {/* order table box end */}
    </div>
  );
};

export default OrderHistorySelectModal;
