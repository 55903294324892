import React from "react";
import Skeleton from "react-loading-skeleton";

const NoBarContent = () => {
  return (
    <>
      <div
        style={{
          gap: "3rem",
          width: "100%",
          minHeight: "30rem",
          display: "flex",
          borderLeft: ".1rem solid rgba(0,0,0, .1)",
          borderBottom: ".1rem solid rgba(0,0,0, .1)",
          paddingLeft: "2rem",
          marginBottom: "-5rem",
          // justifyContent: "space-between",
        }}
        className=""
      >
        <div
          style={{
            display: "flex",
            gap: "2rem",
            // marginBottom: "-3rem",
            transform: "rotate(180deg)",
            justifyContent: "center"
          }}
          className=""
        >
          <Skeleton width={90} height={`30%`} />
          <Skeleton width={90} height={`50%`} />
          <Skeleton width={90} height={`70%`} />
          <Skeleton width={90} height={`20%`} />
        </div>
      </div>
    </>
  );
};

export default NoBarContent;
