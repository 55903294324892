import React, { useState } from "react";
import ReuseableTable from "../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../component/dashboard/tables/TableRow";
import CommonHeader from "./CommonHeader";
import AddNewServiceProviderModal from "./modal/AddNewServiceProvider";
import Modal from "react-awesome-modal";
import SuccessAddServiceProvider from "./modal/SuccessAddServiceProvider";

const list = [
  {
    name: "Kuramo Vets & Doctors",
    level: "Clinic",
    phone_no: "+(234)-8156666666",
    email: "blaqzku@gmail.com",
  },
  {
    name: "Kuramo Vets & Doctors",
    level: "Clinic",
    phone_no: "+(234)-8156666666",
    email: "blaqzku@gmail.com",
  },
  {
    name: "Kuramo Vets & Doctors",
    level: "Clinic",
    phone_no: "+(234)-8156666666",
    email: "blaqzku@gmail.com",
  },
  {
    name: "Kuramo Vets & Doctors",
    level: "Clinic",
    phone_no: "+(234)-8156666666",
    email: "blaqzku@gmail.com",
  },
];

const DocPendingApproval = () => {
  const [indexNo, setIndexNo] = useState("");
  const [showModal, setShowModal] = useState({
    add_new_provider: false,
    add_new_provider_success: false,
  });
  return (
    <>
      <div>
        <CommonHeader
          onBtnClick={() =>
            setShowModal((prev) => {
              return { ...prev, add_new_provider: true };
            })
          }
        />
        {/* table start */}
        <ReuseableTable
          //   loading={loading}
          sN
          one={"Name"}
          two={"Level"}
          three={`Phone No`}
          four={"Email"}
          oneDrop
          twoDrop
          moreThanFive={list?.length >= 3}
          //   tableTwo
        >
          {list.map((chi, idx) => {
            const { name, level, email, phone_no } = chi;
            return (
              <TableRow
                sN={idx + 1}
                setIndexNo={() => setIndexNo(idx)}
                rowNo={idx}
                indexNo={indexNo}
                key={idx}
                one={name}
                two={level}
                four={email}
                three={phone_no}
              />
            );
          })}
        </ReuseableTable>
        {/* table endd */}
      </div>
      {/* add new provider start */}
      <Modal visible={showModal.add_new_provider} effect="fadeInUp">
        <AddNewServiceProviderModal
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, add_new_provider: false };
            })
          }
          onCreate={() =>
            setShowModal((prev) => {
              return { ...prev, add_new_provider: false, add_new_provider_success: true };
            })
          }
        />
      </Modal>
      {/* add new provider end */}
      {/* add new provider start */}
      <Modal visible={showModal.add_new_provider_success} effect="fadeInUp">
        <SuccessAddServiceProvider
          onClose={() =>
            setShowModal((prev) => {
              return { ...prev, add_new_provider_success: false };
            })
          }
        />
      </Modal>
      {/* add new provider end */}
    </>
  );
};

export default DocPendingApproval;
