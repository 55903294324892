import axios from 'axios';

const API = process.env.REACT_APP_BASE_URL || 'https://sweetapi.petme.tech/api/';

/** base url to make request to the BE end point */

const instance = axios.create({
  baseURL: API
});

export default instance;
