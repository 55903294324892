import React, { useState } from "react";
import ReuseableTable from "../../../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../../../component/dashboard/tables/TableRow";
import Flatpicker from "react-flatpickr";
import "flatpickr/dist/themes/confetti.css";
import moment from "moment";
import calendarIcon from "../../../../../assets/calendar-icon.svg";
import Button from "../../../../../component/dashboard/fragments/Button";

const TransactionHistory = ({ setShowbox }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [showHistoryTable, setShowHistoryTable] = useState(false);

  const list = [
    {
      type: "Appointment Fee",
      date: "Jul 28 - 10:00am",
      txn_amount: "N12,000",
      status: "Settled",
      type_stat: "credit",
    },
    {
      type: "Appointment Fee",
      date: "Jul 28 - 10:00am",
      txn_amount: "N12,000",
      status: "Unsettled",
      type_stat: "debit",
    },
    {
      type: "Appointment Fee",
      date: "Jul 28 - 10:00am",
      txn_amount: "N12,000",
      status: "Settled",
      type_stat: "credit",
    },
  ];

  return (
    <div style={{marginTop: "3rem"}} className="transaction-history-box">
      {/* text-value box sytart */}
      {/* <div className="text-value-box">
        <p className="text">Total Spend</p>
        <p className="value">N 123,456</p>
      </div> */}
      {/* text-value box end */}

      {!showHistoryTable && (
        <p className="subtitle">
          Select a Date range to fetch transaction records for
        </p>
      )}

      {/* select history box start */}
      {/* calendar box start */}
      {!showHistoryTable && (
        <div className="calender-box">
          <p className="text">from</p>
          <div className="form-group">
            <label htmlFor="from" className="label">
              <figure className="img-box">
                <img src={calendarIcon} alt="" className="img" />
              </figure>
            </label>
            <Flatpicker
              id="from"
              name="from"
              data-enable-time
              //   cal
              className="input-calender"
              autoComplete="off"
              value={fromDate}
              onChange={(date) =>
                setFromDate(moment(date[0]).format("YYYY/MM/DD"))
              }
              placeholder="DD / MM / YYYY"
            />
          </div>
          <p style={{ marginLeft: "2rem" }} className="text">
            to
          </p>
          <div className="form-group">
            <label htmlFor="to" className="label">
              <figure className="img-box">
                <img src={calendarIcon} alt="" className="img" />
              </figure>
            </label>
            <Flatpicker
              data-enable-time
              id="to"
              name="to"
              //   cal
              className="input-calender"
              autoComplete="off"
              value={toDate}
              onChange={(date) =>
                settoDate(moment(date[0]).format("YYYY/MM/DD"))
              }
              placeholder="DD / MM / YYYY"
            />
          </div>
        </div>
      )}
      {/* btn start */}
      {!showHistoryTable && (
        <Button
          btnStyle={`btn-reuse`}
          text="Fetch Records"
          bgColor={`#84BFCD`}
          onClick={() => setShowHistoryTable(true)}
        />
      )}
      {/* btn end */}
      {/* table history start */}
      {showHistoryTable && (
        <>
          {/* reset text box start */}
          <div className="reset-text-box">
            <p className="text">
              Displaying <span> 4 </span> results from <span>23/11/2021</span>{" "}
              to <span>01/01/2022</span>
            </p>
            <p onClick={() => setShowHistoryTable(false)} className="reset">
              Reset
            </p>
          </div>
          {/* reset text box end */}
          <ReuseableTable
            //    loading={loading}
            sN
            one={`Transaction type `}
            two={`Txn Amount`}
            three={`Date`}
            four={`Status`}
            twoDrop
            threeDrop
            fourDrop
            // action
          >
            {list.map((chi, idx) => {
              const { txn_amount, type, date, status, type_stat } = chi;
              return (
                <TableRow
                  key={idx}
                  sN={idx + 1}
                  one={type}
                  greenRed={txn_amount}
                  greenRedColor={type_stat === "credit" ? "#00C013" : "#DE0B0B"}
                  three={date}
                  status={status}
                  statusColor={
                    status.toLowerCase() === "settled"
                      ? "deliver"
                      : status.toLowerCase() === "complete"
                      ? "deliver"
                      : "red"
                  }
                />
              );
            })}
          </ReuseableTable>
        </>
      )}
      {/* table history end */}
    </div>
  );
};

export default TransactionHistory;
