import React from "react";
import ReuseableTable from "../../../../../component/dashboard/tables/ReuseableTable";
import TableRow from "../../../../../component/dashboard/tables/TableRow";

const PetClient = ({ setShowbox }) => {
  const list = [
    {
      pet: "Enzo",
      pet_parent: "Kokumo Blaqbonez",
      type: "Cat",
      sex: "Female",
      reg_no: "0123456",
    },
    {
      pet: "Enzo",
      pet_parent: "Kokumo Blaqbonez",
      type: "Cat",
      sex: "Female",
      reg_no: "0123456",
    },
    {
      pet: "Enzo",
      pet_parent: "Kokumo Blaqbonez",
      type: "Cat",
      sex: "Female",
      reg_no: "0123456",
    },
    {
      pet: "Enzo",
      pet_parent: "Kokumo Blaqbonez",
      type: "Cat",
      sex: "Female",
      reg_no: "0123456",
    },
  ];
  return (
    <div className="pet-wrap-box">
      <ReuseableTable
        //    loading={loading}
        sN
        one={`Pet `}
        two="Pet Parent"
        three={`Type`}
        four={`Sex`}
        five={"Reg No"}
        twoDrop
        threeDrop
        fourDrop
      >
        {list.map((chi, idx) => {
          const { pet, type, sex, pet_parent, reg_no } = chi;
          return (
            <TableRow
              key={idx}
              sN={idx + 1}
              one={pet}
              two={pet_parent}
              three={type}
              four={sex}
              five={reg_no}
            />
          );
        })}
      </ReuseableTable>
    </div>
  );
};

export default PetClient;
