import React from "react";
import Button from "../../../../component/dashboard/fragments/Button";
import CommonModal from "./CommonModal";

const ConfirmOrderModal = ({ children, onAccept }) => {
  return (
    <>
      <CommonModal
        accept
        bigText={`Are you sure you want to confirm item
        Delivery?`}
      >
        {children}
        <Button
          onClick={onAccept}
          text={`Confirm Delivery`}
          bgColor={`#00C013`}
        />
      </CommonModal>
    </>
  );
};

export default ConfirmOrderModal;
