import React from "react";
import { FaTimes } from "react-icons/fa";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ItemInList = ({ text, onRemove , loading }) => {

  if(loading){
    return(
      <Skeleton
      height={30}
      width={70}
      // className="name"
    ></Skeleton>
    )
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: ".8rem 1.5rem",
        backgroundColor: " #F6F8F9",
        borderRadius: ".9rem",
        justifySelf: "flex-start"
      }}
    >
      <p style={{ marginRight: ".6rem", textTransform: "capitalize" }}>{text}</p>
      <FaTimes
      onClick={onRemove}
        style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer", color: "#B0BABF" }}
      />
    </div>
  );
};

export default ItemInList;
